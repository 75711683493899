import React, { useState, useEffect, useContext, Fragment } from 'react';
import i18next from 'i18next';
// import InfoEnvironment from '../../utils/info.environment';
// import isMockUser from '../../utils/isMockUser';
import isTravelBenefitsActive from '../../utils/isTravelBenefitsActive';

import AsideProfile from '../../components/Profile/AsideProfile';
// import HeaderProfile from "../../components/Profile/HeaderProfile";
import NewHeaderProfile from './components/NewHeaderProfile';
import TotalRewards from '../../components/Profile/TotalRewards';
import TravelBenefits from '../../components/Profile/TravelBenefits';
//import VentaEspecial from '../../components/Profile/ventaespecial';
// import VentaEspecial from '../../components/Profile/ventaespecial2020';
import BenefitsDiscounts from '../../components/Profile/BenefitsDiscounts';
import {
	// PrivateContext,
	// PublicContext,
	ServicioContext
} from '../../globalState';

import statement from '../../assets/images/profile/tab-icon01.svg';
import money from '../../assets/images/profile/tab-icon02.svg';
import money2 from '../../assets/images/profile/tab-icon03.svg';
import { ReactComponent as FlexiSVG } from '../Flexibility/assets/isotipo.svg';
// import benefits from '../../assets/images/profile/tab-icon03.svg';
import { ReactComponent as Caballero } from '../../assets/identidad/caballero.svg';
import { AnimatePresence, motion } from 'framer-motion';
import './Styles/venta2020father.css';
/*
██████╗ ███████╗██████╗ ██╗   ██╗██╗  ██╗    ██╗      ██████╗  █████╗ ██████╗ 
██╔══██╗██╔════╝██╔══██╗██║   ██║╚██╗██╔╝    ██║     ██╔═══██╗██╔══██╗██╔══██╗
██████╔╝█████╗  ██║  ██║██║   ██║ ╚███╔╝     ██║     ██║   ██║███████║██║  ██║
██╔══██╗██╔══╝  ██║  ██║██║   ██║ ██╔██╗     ██║     ██║   ██║██╔══██║██║  ██║
██║  ██║███████╗██████╔╝╚██████╔╝██╔╝ ██╗    ███████╗╚██████╔╝██║  ██║██████╔╝
╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝ ╚═╝  ╚═╝    ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ 
*/
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPrivateUser } from '../../redux/actions/actions-private';
import Flexibility from '../Flexibility/Flexibility';

const sha1 = require('sha1');

function ProfileRewards({
	activeTab,
	privateUserData,
	publicUserData,
	privateData,
	privateLoadedAt,
	getPrivateUser,
	publicData
}) {
	const { serviceData } = useContext(ServicioContext);
	const [tabActive, setTabActive] = useState(
		activeTab === null || activeTab === undefined ? 'rewards' : activeTab
	);
	const [reduxLoading, setReduxLoading] = useState(true);
	const [flexActive, setFlexActive] = useState(false);
	const [publicDataSelected, setPublicDataSelected] = useState(null);
	const [privateDataSelected, setPrivateDataSelected] = useState(null);
	const [isLocalHost, setIsLocalHost] = useState(false);
	const [specialSale, setSpecialSale] = useState(false);
	const [boothActiveItems, setBoothActiveItems] = useState(false);

	const randomNumber = (min, max) => {
		return Math.floor(Math.random() * (max - min + 1) + min);
	};

	/* useEffect(() => {
		console.log('TAB ACTIVE', tabActive);
		console.log('NEW PROFILE', window.location.hostname);
		async function validateFlex() {
			const isItActive = await isFlexibilityActive();
			console.log('FLEX SHOULD SHOW', isItActive);
			setFlexActive(isItActive);
		}
		validateFlex();
		async function validateSpecialSale() {
			const specialActive = await fetch(
				`https://cadmin.miaeromexico.com/menu-items-validator`
			)
				.then((res) => res.json())
				.catch(console.error);
			const userSpecial = await fetch(
				`https://cadmin.miaeromexico.com/ve-usuarios?email_eq=${localStorage.getItem(
					'email'
				)}`
			)
				.then((res) => res.json())
				.catch(console.error);
			// alert(
			// 	userSpecial.length > 0 ? specialActive.venta_especial : false
			// );
			const hasSpecial =
				userSpecial.length > 0 ? specialActive.venta_especial : false;
			// const hasSpecial = userSpecial.length > 0 ? true : false;
			setSpecialSale(hasSpecial);
			setBoothActiveItems(
				hasSpecial && isTravelBenefitsActive() ? true : false
			);
		}
		validateSpecialSale();
		if (window.location.hostname === 'localhost') {
			setIsLocalHost(true);
			const salarioItem = randomNumber(15000, 150000);
			const valesItem = randomNumber(3000, 5000);
			setPrivateDataSelected({
				aguinaldo: salarioItem,
				bonoAutomovil: 0,
				bonoAutomovilMensual: 0,
				bonoDesempeno: salarioItem * 2,
				bonoUtiles: 0,
				bonoUtilesMensual: 0,
				compensacion: 0,
				compensacionMensual: 0,
				fondoAhorro: salarioItem - salarioItem * 0.4,
				lti: 0,
				primaVacacional: salarioItem / 4.286,
				ratio: '.90',
				salario: salarioItem,
				salarioAnual: salarioItem * 12,
				sumaAnual: 0,
				sumaAnualTotal:
					salarioItem * 12 +
					salarioItem +
					salarioItem / 4.286 +
					(salarioItem - salarioItem * 0.4) +
					valesItem * 12 +
					salarioItem * 2,
				sumaMensual: salarioItem + valesItem,
				valesDespensa: valesItem * 12,
				valesMensual: valesItem
			});
			setPublicDataSelected({
				antiguedadEmpleado: '2 Años 3 Meses ',
				antiguedadrol: '04/11/2019',
				apellidoMaterno: 'BRISEÑO',
				apellidoPaterno: 'GARCIA',
				codigo_empresa: '02',
				compania: 'Aeroméxico',
				country: 'MX',
				direccion: 'RECURSOS HUMANOS',
				direccionArea: 'RECURSOS HUMANOS',
				emailhrbp: 'eparada@aeromexico.com',
				fechaContra: '04/11/2019',
				gradopydid: null,
				gradopydnombre: null,
				grupo: '02 NSD - NO SINDICALIZADOS',
				grupopydid: null,
				grupopydnombre: null,
				nivel: '6',
				noEmpleado: '357919',
				nombre: 'JUAN RODRIGO DE LA CRUZ',
				nombrehrbp: 'PARADA ELVIRA',
				posicion: 'ESPECIALISTA SR HERRAMIENTAS DIGITALES RH',
				subordinados: null,
				subsidiaria: 'Aerovías de México S.A. de C.V.',
				territorio_mexicano: '1',
				tipo_empleado: 'NS',
				ubicacion: 'CECAM',
				variable1: '14',
				variable2: '140'
			});
		} else {
			setPrivateDataSelected(privateData);
			setPublicDataSelected(publicData);
			const fiveMinutes = 60 * 5 * 1000;
			if (
				!privateUserData ||
				new Date() - new Date(privateLoadedAt) > fiveMinutes
			) {
				// LOADS PRIVATE DATA FROM REDUX
				getPrivateUser();
				setReduxLoading(false);
			}
		}
	}, []); */

	return (
		<div className="profile">
			{/* LEFSIDE MENU */}
			<div className="contProfileMenu">
				<div className="profileContainers" id="profileMenu">
					{isLocalHost ? (
						<AsideProfile
							publicData={publicDataSelected}
							privateData={privateDataSelected}
						/>
					) : (
						<>
							{privateUserData && publicUserData && (
								<AsideProfile
									publicData={publicData}
									privateData={privateData}
								/>
							)}
						</>
					)}
				</div>
			</div>
			{/* MIDDLE PART */}
			<div className="profileContainers" id="profileContent">
				{/* HEADER */}
				{publicUserData && (
					<NewHeaderProfile
					// loading={loading}
					// loadingPriv={loadingPriv}
					// data={data}
					// privateData={dataPriv}
					/>
				)}
				<div className="ProfileWrapperCont">
					{/* TABS INIT */}
					<div className={`profile-tabs`}>
						<div className="container-tabs">
							<ul
								className={`${
									flexActive
										? boothActiveItems
											? // ? 'should-have-5-columns'
											  'grid grid-cols-5'
											: 'grid grid-cols-4'
										: boothActiveItems
										? // ? 'should-have-4-columns'
										  'grid grid-cols-4'
										: // : 'only-3-columns'
										  'grid grid-cols-3'
								}`}
							>
								<li
									className={`${
										tabActive === 'rewards'
											? 'profile-tab-active rewards'
											: 'profile-tab'
									}`}
									onClick={() => setTabActive('rewards')}
									name="rewards"
								>
									<div className="textTabsCont">
										<img
											src={statement}
											alt="Icon Profile"
											className="icon_profile"
										/>
										<p>
											{i18next.t(
												'MiExperAM.compensacion.tab'
											)}
										</p>
									</div>
								</li>
								{isTravelBenefitsActive() && (
									<li
										className={`${
											tabActive === 'travel'
												? 'profile-tab-active travel'
												: 'profile-tab'
										}`}
										onClick={() => setTabActive('travel')}
										name="travel"
									>
										<div className="textTabsCont">
											<img
												src={money}
												alt="Icon Profile"
												className="icon_profile"
											/>
											<p>
												{i18next.t(
													'MiExperAM.travel.tab'
												)}
											</p>
										</div>
									</li>
								)}
								{/* VENTA ESPECIAL */}
								{specialSale && (
									<li
										className={`${
											tabActive === 'ventaespecial'
												? 'profile-tab-active ventaespecial'
												: 'profile-tab'
										}`}
										onClick={() =>
											setTabActive('ventaespecial')
										}
										name="ventaespecial"
									>
										<div className="textTabsCont">
											<img
												src={money}
												alt="Icon Profile"
												className="icon_profile"
											/>
											<p>
												{i18next.t(
													'MiExperAM.venta.tab'
												)}
											</p>
										</div>
									</li>
								)}
								{/* FIN DE VENTA ESPECIAL */}
								<li
									className={`${
										tabActive === 'benefits'
											? 'profile-tab-active benefits'
											: 'profile-tab'
									}`}
									onClick={() => setTabActive('benefits')}
									name="benefits"
								>
									<div className="textTabsCont">
										<img
											src={money2}
											alt="Icon Profile"
											className="icon_profile"
										/>
										<p>
											{i18next.t('MiExperAM.benef.tab')}
										</p>
									</div>
								</li>
								{flexActive ? (
									<li
										className={`${
											tabActive === 'flexibility'
												? 'profile-tab-active'
												: 'profile-tab'
										}`}
										onClick={() =>
											setTabActive('flexibility')
										}
										name="flexibility"
									>
										<div
											className="textTabsCont"
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center'
											}}
										>
											{/* <img
												src={Flexi}
												alt="Icon Profile"
												style={{
													height: '30px',
													width: 'auto'
												}}
											/> */}
											<FlexiSVG className="order-1 mr-2 h-6 w-auto" />
											<p className="order-2">
												My Flexibility
											</p>
										</div>
									</li>
								) : null}
							</ul>
						</div>
					</div>
					{/* TABS END */}
					{/* // VALIDACION */}
					<div className="profile-tabs-content">
						<AnimatePresence>
							{tabActive === 'rewards' && (
								<>
									{!isLocalHost ? (
										<Fragment>
											{!privateUserData ? (
												<motion.div
													style={{
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',

														justifyContent: 'center'
													}}
													initial={{ opacity: 0 }}
													animate={{ opacity: 1 }}
													transition={{
														repeat: 'infinity',
														repeatType: 'loop',
														duration: 1
													}}
												>
													<p className="loader-rewards">
														Estamos cargando tu
														información, en 30
														segundos podrás
														consultarla.
													</p>
													<Caballero
														style={{
															height: '100px',
															width: 'auto'
														}}
													/>
												</motion.div>
											) : (
												<TotalRewards
													data={publicData}
													dataPriv={privateData}
												/>
											)}
										</Fragment>
									) : (
										<Fragment>
											<TotalRewards
												data={publicDataSelected}
												dataPriv={privateDataSelected}
											/>
										</Fragment>
									)}
								</>
							)}
							{tabActive === 'flexibility' && <Flexibility />}
							{tabActive === 'travel' && <TravelBenefits />}
							{/* {tabActive === 'ventaespecial' && <VentaEspecial />} */}
							{tabActive === 'benefits' && <BenefitsDiscounts />}
						</AnimatePresence>
					</div>
					{/* {loading && !error ? (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",

								justifyContent: "center"
							}}
						>
							<p className="loader-rewards">
								Estamos cargando tu información, en 30 segundos
								podrás consultarla.
							</p>
							<Caballero
								style={{ height: "100px", width: "auto" }}
							/>
						</div>
					) : (
						<div className="profile-tabs-content">
							{tabActive === "rewards" && <TotalRewards />}
							{tabActive === "travel" && <TravelBenefits />}
							{tabActive === "ventaespecial" && <VentaEspecial />}
							{tabActive === "benefits" && <BenefitsDiscounts />}
						</div>
					)} */}
					{/* {error && (
						<div className="profile-tabs-content">
							{tabActive === "rewards" && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center"
									}}
								>
									<p className="loader-rewards">{theError}</p>
									<Caballero
										style={{
											height: "100px",
											width: "auto"
										}}
									/>
								</div>
							)}
							{tabActive === "travel" && <TravelBenefits />}
							{tabActive === "ventaespecial" && <VentaEspecial />}
							{tabActive === "benefits" && <BenefitsDiscounts />}
						</div>
					)} */}
				</div>
			</div>
		</div>
	);
	// } else {
	// 	return <h1>Cargando por favor espere</h1>;
	// }
}

/*
-██████╗ ██████╗ ███╗   ██╗███╗   ██╗███████╗ ██████╗████████╗             
██╔════╝██╔═══██╗████╗  ██║████╗  ██║██╔════╝██╔════╝╚══██╔══╝             
██║     ██║   ██║██╔██╗ ██║██╔██╗ ██║█████╗  ██║        ██║                
██║     ██║   ██║██║╚██╗██║██║╚██╗██║██╔══╝  ██║        ██║                
╚██████╗╚██████╔╝██║ ╚████║██║ ╚████║███████╗╚██████╗   ██║                
-╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝  ╚═══╝╚══════╝ ╚═════╝   ╚═╝                
██╗    ██╗██╗████████╗██╗  ██╗    ██████╗ ███████╗██████╗ ██╗   ██╗██╗  ██╗
██║    ██║██║╚══██╔══╝██║  ██║    ██╔══██╗██╔════╝██╔══██╗██║   ██║╚██╗██╔╝
██║ █╗ ██║██║   ██║   ███████║    ██████╔╝█████╗  ██║  ██║██║   ██║ ╚███╔╝ 
██║███╗██║██║   ██║   ██╔══██║    ██╔══██╗██╔══╝  ██║  ██║██║   ██║ ██╔██╗ 
╚███╔███╔╝██║   ██║   ██║  ██║    ██║  ██║███████╗██████╔╝╚██████╔╝██╔╝ ██╗
-╚══╝╚══╝ ╚═╝   ╚═╝   ╚═╝  ╚═╝    ╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝ ╚═╝  ╚═╝
*/
const mapStateToProps = (state) => ({
	privateUserData: state.privateUser.privateUserData,
	publicUserData: state.publicUser.publicUserData,
	privateData: state.privateUser.privateData,
	privateLoadedAt: state.privateUser.privateLoadedAt,
	publicData: state.publicUser.publicData
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ getPrivateUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileRewards);
