import React, { useEffect, useState } from "react";
//import "./css/myHR.css";
import { Link } from "react-router-dom";
/* import Aside from './AsideProfile'; */
import Modal from "react-awesome-modal";
import ReactMarkdown from "react-markdown";
import i18next from "i18next";
import imageconsiderar01 from "../../assets/images/sidebar/vacantes01.svg";
import imageconsiderar02 from "../../assets/images/sidebar/vacantes02.svg";
import imageconsiderar03 from "../../assets/images/sidebar/vacantes03.svg";
import close from "../../assets/logo/close.svg";
import VBanner from "../../assets/vacantes/vacantes-banner.jpg";
/* import Header from "../../components/Header"; */
import Index from "../Footer/index";
import "./css/vacantes.css";
import moment from "moment";
import Arriba from "../Header/Arriba";
/* import IndexHeader from "../Header"; */

const noScroll = require("no-scroll");

const Vacantes = () => {
	const language = i18next.language;
	const isSpanish = language === "es";
	const [loading, setLoading] = useState(true);
	const [vacantes, setVacantes] = useState([]);
	const [categorias, setCategorias] = useState([]);

	const cabecera = new Headers({
		"Content-type": "application/json",
		Authorization: "Bearer " + process.env.REACT_APP_TOKEN_LOCAL
	});

	useEffect(() => {
		fetch(`${process.env.REACT_APP_LOCAL}vacantes-categorias`, { headers: cabecera })
			.then((res) => res.json())
			.then((categoriasData) => {
				console.log("CATEGORIAS DETAIL: ", categoriasData.data);
				// setCategorias(categoriasData.data);
				fetch(`${process.env.REACT_APP_LOCAL}vacantes`, { headers: cabecera })
					.then((res) => res.json())
					.then((vacantesData) => {
						console.log("vacantes -> ", vacantesData);
						const tempArray = [];
						for (let i = 0; i < vacantesData.data.length; i++) {
							if (vacantesData.data[i].attributes.categoria) {
								tempArray.push(vacantesData.data[i]);
								
								const categoria = categoriasData.data.find(item => item.attributes.title === vacantesData.data[i].attributes.categoria.title);
								
								if (categoria) { 
									setCategorias([categoria]);
								}
							}
						} 
						setVacantes(tempArray);
						setLoading(false);
					})
					.catch(console.error);
			})
			.catch(console.error);
	}, []);

	return (
		<div className="full-container w-screen bg-gray-50 pb-10">
			<div class=" w-screen pb-10">
				{/* <Header /> */}
				{/* <IndexHeader /> */}
				<div class="vacantesHeader">
					<div class="cellFilterHeader">
						<div class="midHeader">
							<div class="midCellHeader">
								<h1 className="opacity-100">{i18next.t("MiExperAM.carrera.title")}</h1>
								<p className="opacity-100">{i18next.t("MiExperAM.carrera.sub")}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="flex w-full flex-col items-center justify-center pt-10">
					<p className="text-sm text-gray-400">
						{isSpanish ? "Secciones de interés" : "Sections of interest"}
					</p>
					<div className="mt-2 flex w-full items-center justify-center space-x-5">
						<Link
							to="/buy-and-sell"
							className="rounded-lg bg-amBlue bg-opacity-30 px-3 py-1 text-sm hover:bg-opacity-70 hover:text-white">
							{isSpanish ? "Triques y Trueques" : "Triques and Barter"}
						</Link>
						<Link
							to="/recompensa-total/perfil/mis-beneficios/disfruta-tu-trabajo/transportacion"
							className="rounded-lg bg-amBlue bg-opacity-30 px-3 py-1 text-sm hover:bg-opacity-70 hover:text-white">
							{isSpanish ? "Transportación interna" : "Internal transportation"}
						</Link>
					</div>
				</div>
				<div className="midWrapVacantes">
					<p className="mb-3 w-full text-center text-gray-400">
						{i18next.t("MiExperAM.carrera.aplicar.title")}
					</p>
					<div class="hottoplay">
						<div class="opciones">
							<img src={imageconsiderar01} alt="Explora las diferentes vacantes" />
							<p>{i18next.t("MiExperAM.carrera.aplicar.explora.title")}</p>
							<p>{i18next.t("MiExperAM.carrera.aplicar.explora.sub")}</p>
						</div>

						<div class="opciones">
							<img src={imageconsiderar02} alt="Asegúrate de:" />
							<p>{i18next.t("MiExperAM.carrera.aplicar.asegura.title")}</p>
							<p>{i18next.t("MiExperAM.carrera.aplicar.asegura.sub")}</p>
						</div>

						<div class="opciones">
							<img src={imageconsiderar03} alt="Envía tu aplicación" />
							<p>{i18next.t("MiExperAM.carrera.aplicar.envia.title")}</p>
							<p>{i18next.t("MiExperAM.carrera.aplicar.envia.sub")}</p>
						</div>
					</div>
				</div>

				<a
					href="https://www.facebook.com/TalentoAeromexico"
					className="midWrapVacantes sticky top-10 block h-20 w-full overflow-hidden rounded-md shadow-xl"
					target="_blank"
					rel="noopener noreferrer"
					style={{
						top: 60,
						zIndex: 20,
						backgroundImage: `url(${VBanner})`,
						backgroundPosition: "-150px 25%",
						backgroundSize: "100%"
					}}>
					<div
						className="absolute left-0 top-0 z-10 h-full w-full"
						style={{
							zIndex: 10,
							background: "linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(1,43,92,1) 70%)"
						}}></div>
					<div
						className="intel-holder absolute left-1/2 top-1/2 z-20 flex w-1/2 -translate-y-1/2 items-center justify-end"
						style={{ zIndex: 20 }}>
						<p className="text-center text-lg font-semibold text-white">
							{isSpanish
								? "¿Ya conoces nuestra página de Talento Aeroméxico en Facebook?"
								: "Do you already know our Aeroméxico Talent page on Facebook?"}
						</p>
						<button type="button" className="rounded-full bg-[#FA0073] px-4 py-2 font-semibold text-white">
							<span className="block" style={{ marginTop: "-4px" }}>
								{isSpanish ? "¡Síguenos!" : "Follow us!"}
							</span>
						</button>
					</div>
				</a>
				{loading ? (
					<div class="midWrapVacantes">
						<div className="flex h-48 w-full flex-col items-center justify-center rounded-lg bg-white">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="50"
								height="50"
								viewBox="0 0 50 50"
								className="animate-spin fill-current text-amDarkBlue">
								<g>
									<path d="M41.9 23.9c-.3-6.1-4-11.8-9.5-14.4-6-2.7-13.3-1.6-18.3 2.6-4.8 4-7 10.5-5.6 16.6 1.3 6 6 10.9 11.9 12.5 7.1 2 13.6-1.4 17.6-7.2-3.6 4.8-9.1 8-15.2 6.9-6.1-1.1-11.1-5.7-12.5-11.7-1.5-6.4 1.5-13.1 7.2-16.4 5.9-3.4 14.2-2.1 18.1 3.7 1 1.4 1.7 3.1 2 4.8.3 1.4.2 2.9.4 4.3.2 1.3 1.3 3 2.8 2.1 1.3-.8 1.2-2.5 1.1-3.8 0-.4.1.7 0 0z"></path>
								</g>
							</svg>
							<p className="text-md mt-1 text-amDarkBlue">{isSpanish ? "cargando" : "loading"}</p>
						</div>
					</div>
				) : (
					<>
						{categorias.map((cate) => {
							const theVacante = vacantes.find(
								(vac) => vac.attributes.categoria.id !== cate.attributes.id
							);
							if (theVacante) {
								return (
									<div key={cate.id} class="midWrapVacantes flex flex-wrap">
										<div class="totalBenefits__title__container w-full">
											<div class="iconTitleProfile">
												<img
													src={cate.attributes.icon}
													alt={
														isSpanish
															? cate.attributes.title
															: cate.attributes.Localization.title
													}
												/>
											</div>
											<h1 className="!text-[#035cf7]">
												{isSpanish ? cate.attributes.title : cate.attributes.Localization.title}
											</h1>
										</div>
										<div className="w-full overflow-hidden rounded-lg border-b border-l border-r border-t border-gray-200 bg-white shadow-xl">
											
											{	vacantes.map((vacante) => (
												<>
												{	console.log("Esto es vacante modal", vacante)}
												{	console.log("Esto es categoria modal", cate)}
													{vacante.attributes.categoria.title === cate.attributes.title && (
														<Componente key={vacante.id} vacante={vacante} />
													)}
												</>
											))}
										</div>
									</div>
								);
							} else {
								return null;
							}
						})}
					</>
				)}
			</div>
			<Index />
		</div>
	);
};

export default Vacantes;

const Componente = ({ vacante }) => {
	const [modal, setModal] = useState(false);
	const language = i18next.language;
	const isSpanish = language === "es";
	const english = vacante.attributes.Localization;
	console.log("Esto es vacantes modal");
	console.log(vacante);
	return (
		<>
			<button
				onClick={() => {
					noScroll.on();
					setModal(true);
				}}
				className="group flex w-full cursor-pointer items-stretch justify-center border-b border-gray-200 bg-amRegularBlue bg-opacity-0 outline-none ring-0 hover:bg-opacity-5 focus:outline-none focus:ring-0">
				<div className="flex w-8/12 flex-col p-6 md:w-4/12">
					<p className="text-left text-lg font-semibold leading-tight text-amDarkBlue">
						{/* {isSpanish ? vacante.title : (vacante.Localization.title==null?"":vacante.Localization.title)} */}
						{isSpanish ? vacante.attributes.title : english?.title ?? ""}
					</p>
					<p className="mt-1 text-left text-sm leading-tight text-gray-500">
						{isSpanish ? vacante.attributes.direction_area : english?.direction_area ?? ""}
					</p>
				</div>
				<div className="hidden w-3/12 flex-col items-start justify-center p-6 md:flex">
					<p className="text-sm leading-tight text-gray-500">{isSpanish ? "Ubicación:" : "Location:"}</p>
					<p className="text-md font-semibold leading-tight text-amDarkBlue">
						{isSpanish ? vacante.attributes.location : english?.location ?? ""}
					</p>
				</div>
				<div className="hidden w-1/12 flex-col items-start justify-center p-6 md:flex">
					<p className="text-sm leading-tight text-gray-500">{isSpanish ? "Nivel:" : "Level:"}</p>
					<p className="text-md font-semibold leading-tight text-amDarkBlue">L{vacante.attributes.level}</p>
				</div>
				<div className="hidden w-2/12 items-center justify-center md:flex">
					<div className="flex flex-col items-start justify-center">
						<p className="text-sm leading-tight text-gray-500">
							{isSpanish ? "Válido hasta:" : "Valid until:"}
						</p>
						<p className="text-md font-semibold leading-tight text-amDarkBlue">
							{moment(vacante.attributes.until).format("DD/MM/YYYY")}
						</p>
					</div>
				</div>
				<div className="flex w-4/12 items-center justify-center md:w-2/12">
					<div className="whitespace-nowrap rounded-full bg-amRegularBlue bg-opacity-30 px-2.5 py-1.5 text-xs text-amBlue group-hover:bg-opacity-100 group-hover:text-white">
						{isSpanish ? "Más información" : "More information"}
					</div>
				</div>
			</button>
			<Modal
				visible={modal}
				width="100%"
				height="auto"
				effect="fadeInDown"
				onClickAway={() => {
					noScroll.off();
					setModal(false);
				}}>
				<div className="whiteVacantes">
					<div
						class="closeModal"
						onClick={() => {
							noScroll.off();
							setModal(false);
						}}>
						<img src={close} alt="Cerrar" />
					</div>
					<div className="padding">
						<div className="header">
							<p className="titleVacante">
								{isSpanish ? vacante.attributes.title : english?.title ?? ""}
							</p>
							<p>
								{isSpanish ? vacante.location : english?.location ?? ""} |{" "}
								{i18next.t("MiExperAM.carrera.vacantes.vacante.modal.nivel")}
								{vacante.attributes.level}
							</p>
						</div>

						<div className="wrapperContenfutlVacantes">
							<h4>{i18next.t("MiExperAM.carrera.vacantes.vacante.modal.desc")}</h4>
							<ReactMarkdown
								className="description prose"
								children={isSpanish ? vacante.attributes.description : english?.description ?? ""}
							/>
						</div>

						<div className="wrapperContenfutlVacantes">
							<h4>{i18next.t("MiExperAM.carrera.vacantes.vacante.modal.resp")}</h4>
							<ReactMarkdown
								className="description prose"
								children={
									isSpanish ? vacante.attributes.responsibilities : english?.responsibilities ?? ""
								}
							/>
						</div>

						<div className="wrapperContenfutlVacantes">
							<h4>{i18next.t("MiExperAM.carrera.vacantes.vacante.modal.req")}</h4>
							<ReactMarkdown
								className="description prose"
								children={isSpanish ? vacante.attributes.requirements : english?.requirements ?? ""}
							/>
						</div>
					</div>

					<div className="applyHere">
						<Link
							// to={`/recompensa-total/oportunidades-carrera/aplicar-vacante/${vacante.id}`}
							to={`/oportunidades-carrera/aplicar-vacante/${vacante.id}`}
							className="applynowModal">
							<span className="m-0">{i18next.t("MiExperAM.carrera.vacantes.vacante.modal.aplicar")}</span>
							<p className="m-0 w-full text-center text-xs text-white">
								{isSpanish
									? `Válido hasta el ${moment(vacante.attributes.until).format("DD/MM/YYYY")}`
									: `Valid until ${moment(vacante.attributes.until).format("DD/MM/YYYY")}`}
							</p>
						</Link>
					</div>
				</div>
			</Modal>
		</>
	);
};
