import React, { useEffect, useState } from 'react';

import './learning.css';
import Header from './assets/header-learning.jpg';
import MyLearning from './assets/my-learning-logo-white.png';
import LinkedinL from './assets/linkedin-learning-blue.png';
import Mlicon from './assets/ml-icon-left.png';
import MliconC from './assets/ml-icon-center.png';
import MliconR from './assets/ml-icon-right.png';
import LearningTable from './LearningTable';
import {
	learn_14,
	learn_10_12,
	learn_7_9,
	learn_5_6,
	learn_1_4
} from './learningPaths';
import { parseInt } from 'lodash';
import { Navigate } from 'react-router-dom';
import i18next from 'i18next';
import Arriba from '../Header/Arriba';
import Index from '../Footer';


const MyLearningPage = () => {
	const [subjects, setSubjects] = useState([]);
	const [loading, setLoading] = useState(true);
	const [canDisplay, setCanDisplay] = useState(true);
	const email = localStorage.getItem('email');
	const specialCases =
		email === 'paznavurian@aeromexico.com' || //12
		email === 'jcgonzalez@aeromexico.com' // 10
			? localStorage.getItem('email')
			: null;
	const specialLevel =
		email === 'paznavurian@aeromexico.com'
			? 12
			: email === 'jcgonzalez@aeromexico.com'
			? 10
			: null;

	const settingSubjects = (nivel) => {
		if (nivel < 5) {
			setSubjects(learn_1_4);
		} else if (nivel > 4 && nivel < 7) {
			setSubjects(learn_5_6);
		} else if (nivel > 6 && nivel < 10) {
			setSubjects(learn_7_9);
		} else if (nivel > 9 && nivel < 14) {
			setSubjects(learn_10_12);
		} else if (nivel > 12) {
			setSubjects(learn_14);
		}
	};

	useEffect(() => {
		const nivel =
			specialCases !== null
				? specialLevel
				: parseInt(localStorage.getItem('nivel'));
		if (
			nivel === undefined ||
			nivel === null ||
			(isNaN(nivel) && window.location.hostname !== 'localhost')
		) {
			setCanDisplay(false);
			return;
		}
		if (window.location.hostname === 'localhost') {
			setCanDisplay(true);
			settingSubjects(6);
			setLoading(false);
			return;
		}
		settingSubjects(nivel);
		setLoading(false);
	}, []);

	if (!loading && !canDisplay) {
		return <Navigate to="/inicio" />;
	} else {
		return (
			<>
			<Arriba/>
				<header
					className="myLearning-header flex h-72 w-full flex-col items-center justify-center"
					style={{ background: `url(${Header})` }}
				>
					<h1 className="myLearning-header-text text-center text-6xl">
						<span className="text-5xl font-medium">
							{i18next.t('MyLearning.title1')}
						</span>
						<br />
						<span className="font-bold">
							{i18next.t('MyLearning.title2')}
						</span>
					</h1>
					<p className="myLearning-header-description mt-8 w-full px-10 text-center text-lg sm:px-0 md:w-6/12 lg:w-5/12">
						{i18next.t('MyLearning.subtitle')}
					</p>
				</header>
				<section className="flex w-full items-center justify-center bg-amBlue py-4">
					<img
						src={MyLearning}
						className="h-8 w-auto"
						alt="LinkedIn Learning"
					/>
				</section>
				<section className="w-full bg-white py-5">
					<article className="container">
						<div className="flex w-full flex-wrap items-start justify-start px-10 sm:px-0">
							{/* <p className="text-amBlack text-center w-full">
								Con My Learning tendrás acceso a distintos
								recursos y herramientas que te permitirán:
							</p> */}
							<div className="mx-auto flex w-full items-center justify-center px-10 sm:px-0 md:w-10/12 lg:w-8/12">
								<div className="ml-item flex flex-col items-center justify-center">
									<img
										className="h-24 w-auto"
										src={Mlicon}
										alt="Desarrolla competencias"
									/>
									<p className="px-2 text-center text-amBlack">
										{i18next.t('MyLearning.rec1')}
									</p>
								</div>
								<div className="mx-4 h-32 w-1 bg-gray-200"></div>
								<div className="ml-item flex flex-col items-center justify-center">
									<img
										className="h-24 w-auto"
										src={MliconC}
										alt="Desarrolla competencias"
									/>
									<p className="px-2 text-center text-amBlack">
										{i18next.t('MyLearning.rec2')}
									</p>
								</div>
								<div className="mx-4 h-32 w-1 bg-gray-200"></div>
								<div className="ml-item flex flex-col items-center justify-center">
									<img
										className="h-24 w-auto"
										src={MliconR}
										alt="Desarrolla competencias"
									/>
									<p className="px-2 text-center text-amBlack">
										{i18next.t('MyLearning.rec3')}
									</p>
								</div>
							</div>
							<div className="mx-auto mt-4 flex w-full items-center justify-center px-10 sm:px-0 md:w-10/12 lg:w-8/12">
								<img
									src={LinkedinL}
									className="h-16 w-auto"
									alt="LinkedIn Learning"
								/>
							</div>
							<div className="mx-auto mt-4 flex w-full flex-wrap items-start justify-start px-10 text-left sm:px-0 md:w-10/12 lg:w-8/12">
								<div
									className="prose w-full max-w-full text-amBlack"
									dangerouslySetInnerHTML={{
										__html: i18next.t(
											'MyLearning.description'
										)
									}}
								/>
							</div>
							<div className="mx-auto mt-4 w-full flex-wrap items-center justify-center xl:w-9/12">
								<div className="table-holder-my-learning w-full overflow-hidden rounded-md">
									<LearningTable subjects={subjects} />
								</div>
							</div>
							<div className="mx-auto mt-4 flex w-full items-center justify-start px-10 sm:px-0 md:w-10/12 lg:w-8/12">
								<div
									className="prose w-full max-w-full text-amBlack"
									dangerouslySetInnerHTML={{
										__html: i18next.t(
											'MyLearning.under_cal'
										)
									}}
								/>
							</div>
							<div className="mx-auto mt-4 flex w-full flex-col items-center justify-center px-10 sm:px-0 md:w-10/12 lg:w-8/12">
								<p className="text-sm font-bold text-amBlack">
									¡Da clic aquí para comenzar!
								</p>
								<a
									href="https://www.linkedin.com/learning/"
									target="_blank"
									rel="noopener noreferrer"
									className="mt-2 cursor-pointer rounded-full bg-amRed px-5 py-3 font-bold text-white opacity-100 hover:bg-red-600"
								>
									My Learning
								</a>
								<p className="mx-auto mt-3 w-7/12 text-center text-xs text-amRed">
									{i18next.t('MyLearning.important')}
								</p>
							</div>
						</div>
					</article>
				</section>
				<Index/>
			</>
		);
	}
};

export default MyLearningPage;
