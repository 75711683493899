import React, { Fragment, useContext } from 'react';
import './css/totalrewards.css';
// import { PDFExport } from '@progress/kendo-react-pdf';

import logobenefit02re from '../../assets/images/profile/mybenefitslogorewards.svg';
import InfoEmployee from './TotalReward/EmployeeInfo';
import InfoEmployeeCan from './TotalReward/EmployeeInfoCan';
import InfoEmployeeUsa from './TotalReward/EmployeeInfoUsa';
import Guaranteed from './TotalReward/Guaranteed';
import GuaranteedCan from './TotalReward/GuaranteedCan';
import GuaranteedUsa from './TotalReward/GuaranteedUsa';
// import Variable from './TotalReward/Variable';
// import Print from "./TotalReward/Report/Print"
// import userLocation from "../../utils/userLocation";

// const sha1 = require("sha1");
// const parseString = require("xml2js").parseString;
// const soapRequest = require("easy-soap-request");
// import { PrivateContext, PublicContext } from '../../globalState';
import { motion } from 'framer-motion';

function guaranteedLocation(info, publicInfo) {
	var location = localStorage.getItem('location');

	var option;
	switch (location) {
		case 'CAN':
			option = (
				<GuaranteedCan privateInfo={info} publicInfo={publicInfo} />
			);
			break;
		case 'USA':
			option = (
				<GuaranteedUsa privateInfo={info} publicInfo={publicInfo} />
			);
			break;
		default:
			option = <Guaranteed privateInfo={info} publicInfo={publicInfo} />;
			break;
	}
	return option;
}

function infoEmployeeLocation(info, myPrivate) {
	var location = localStorage.getItem('location') || 'MX';
	var option;
	switch (location) {
		case 'CAN':
			option = (
				<InfoEmployeeCan generalInfo={info} privateData={myPrivate} />
			);
			break;
		case 'USA':
			option = (
				<InfoEmployeeUsa generalInfo={info} privateData={myPrivate} />
			);
			break;
		default:
			option = (
				<InfoEmployee generalInfo={info} privateData={myPrivate} />
			);
			break;
	}
	return option;
}

const TotalRewards = ({ dataPriv, data }) => {
	

	return (
		<motion.div
			initial={{ opacity: 0, y: 100 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 100 }}
			className="Profile_inform"
		>
			<div className="totalBenefits__title__container">
				<div className="logoTabBenefit">
					<img
						className="Travel"
						src={logobenefit02re}
						alt="Travel"
					/>
				</div>
			</div>
			<div className="Container-rewards">
				<div className="info-employee">
					{infoEmployeeLocation(data, dataPriv)}

					{/* CONTEXT obteniendo COUNTRY de PUBLIC */}
					{/* {infoEmployeeLocation(this.state.generalInfo)} */}
					<div className="type-rewards">
						{guaranteedLocation(dataPriv, data)}

						{/* {isTruePriv && <p>Private Content Loaded</p>} */}
						{/* <Variable privateInfo={dataPriv} /> */}
						{/* Guaranteed */}
						{/* variable */}
					</div>
				</div>
			</div>
		</motion.div>
	);
};

export default TotalRewards;
