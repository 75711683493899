import React, { Component } from "react";
import "./css/identidad.css";
import PowerPoint from "../../assets/identidad/powerpoint.svg";
import Word from "../../assets/identidad/word.svg";
// import unologo from "../../assets/identidad/uno.svg";
// import text from "../../assets/identidad/text.svg";
import icons from "../../assets/identidad/icons.svg";
// import gpo from "../../assets/identidad/gpo.svg";
// import am from "../../assets/identidad/am.svg";
// import form from "../../assets/identidad/for.svg";
// import cargo from "../../assets/identidad/cargo.svg";
// import connect from "../../assets/identidad/connect.svg";
// import servicios from "../../assets/identidad/servicios.svg";
// import comp01 from "../../assets/identidad/comp01.svg";
// import comp02 from "../../assets/identidad/comp02.svg";
// import comp03 from "../../assets/identidad/comp03.svg";
// import comp04 from "../../assets/identidad/comp04.svg";
import caballero from "../../assets/identidad/caballero.svg";
import i18next from "i18next";

// import delta from "../../assets/identidad/delta-am.svg";
 
import Index from "../Footer";

export default class SliderSwiper extends Component {
	render() {
		return (
			<div style={{ background: "white" }}>
				
			 

				<div class="nosotrosWrapper !bg-[#0000E3]">
					<div class="nosotrosHeader">
						<h4 className="text-white">{i18next.t("IdentCorp.titulo")}</h4>
						<p className="text-white">{i18next.t("IdentCorp.subtitulo")}</p>
					</div>
				</div>


				<div id="logotiposIdentidad">
					<div class="totalBenefits__title__container">
						<h1>{i18next.t("IdentCorp.logotipos")}</h1>
					</div>
				</div>

				<div className="wrapperDownload">
					<div className="wrapperIdenditad">
						{/* <div className="wrapperbuttonBig">
              <div className="wrapperTop">
                <div className="wrapperTopCell">
                  <img src={text}></img>
                  <p>Tipografía</p>
                </div>
              </div>
              <a className="wrapperBottom">
                <div className="downloadIdenti"><span></span><p>Descargar</p></div>
              </a>
            </div> */}

						<div className="wrapperbuttonBig SKYTEAM">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImg"
									
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/aeromexico--estar-cerca-llegar-lejos.png"
										alt="Estar cerca, llegar lejos"></img>
									<p className="noText">Aeroméxico</p>
								</div>
							</div>
							<a
								id="SKYTEAM LOGO"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/LOGO%20AM.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig GRUPO AEROMEXICO">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImg"
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/grupo-aeromexico.png"
										alt="GPO"
									/>
									<p className="noText">Grupo Aeroméxico</p>
								</div>
							</div>
							<a
								id="GRUPO AEROMEXICO"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/GAM.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig CABALLERO AGUILA">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img className="noTextImg" src={caballero} alt="Caballero Águila" />
									<p className="noText">Caballero Aguila</p>
								</div>
							</div>
							<a
								id="CABALLERO AGUILA"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/CABALLERO.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig CONNECT">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImg"
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/connect.png"
										alt="Connect"
									/>
									<p className="noText">Aeroméxico Connect</p>
								</div>
							</div>
							<a
								id="CONNECT"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/AMC.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig FORMACION">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImg"
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/formacion.png"
										alt="Formación"
									/>
									<p className="noText">Aeroméxico Formación</p>
								</div>
							</div>
							<a
								id="FORMACION"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/AFI.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig CARGO">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImg"
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/cargo.png"
										alt="Cargo"
									/>
									<p className="noText">Aeroméxico Cargo</p>
								</div>
							</div>
							<a id="CARGO" className="wrapperBottom" href="" target="_blank" rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig SERVICIOS">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImg"
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/servicios.png"
										alt="Servicios"
									/>
									<p className="noText">Aeroméxico Servicios</p>
								</div>
							</div>
							<a
								id="SERVICIOS"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/AMS.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig DELTA">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImg"
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/delta.png"
										alt="Delta"
									/>
									<p className="noText">Delta - Aeroméxico</p>
								</div>
							</div>
							<a
								id="DELTA"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/AM-DL.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig CSC">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImg"
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/CSC.png"
										alt="Servicios Compartidos"
									/>
									<p className="noText">Centro de Servicios Compartidos</p>
								</div>
							</div>
							<a
								id="CSC"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/CSC.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>
					</div>
				</div>

				<div id="logotiposIdentidad">
					<div class="totalBenefits__title__container">
						<h1>Comportamientos</h1>
					</div>
				</div>

				<div className="wrapperDownload">
					<div className="wrapperIdenditad">
						{/* <div className="wrapperbuttonBig #1">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img src={unologo} alt="Logo 1"></img>
									<p>{i18next.t("IdentCorp.unologo")}</p>
								</div>
							</div>
							<a
								id="#1"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/NUMERO%20UNO.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div> */}

						<div className="wrapperbuttonBig SEGURIDAD PRIMERO">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImgComp"
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/AGIL.png"
										alt="Comp01"
									/>
									<p className="">{i18next.t("IdentCorp.idiomas")}</p>
								</div>
							</div>
							<a
								id="SEGURIDAD PRIMERO"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/AGIL.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig SIRVE EXCELENCIA">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImgComp"
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/DISFRUTA.png"
										alt="Comp04"
									/>
									<p className="">{i18next.t("IdentCorp.idiomas")}</p>
								</div>
							</div>
							<a
								id="SIRVE EXCELENCIA"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/DISFRUTA.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig INTEGRIDAD">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImgComp"
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/INTEGRIDAD.png"
										alt="Comp02"
									/>
									<p className="">{i18next.t("IdentCorp.idiomas")}</p>
								</div>
							</div>
							<a
								id="INTEGRIDAD"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/INTEGRIDAD.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig COLABORA">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImgComp"
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/LIDERA.png"
										alt="Comp03"
									/>
									<p className="">{i18next.t("IdentCorp.idiomas")}</p>
									<p className="noText"></p>
								</div>
							</div>
							<a
								id="COLABORA"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/LIDERA.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig COLABORA">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImgComp"
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/SEGURIDAD.png"
										alt="Comp03"
									/>
									<p className="">{i18next.t("IdentCorp.idiomas")}</p>
									<p className="noText"></p>
								</div>
							</div>
							<a
								id="COLABORA"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/SEGURIDAD.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig COLABORA">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										className="noTextImgComp"
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/SIRVE.png"
										alt="Comp03"
									/>
									<p className="">{i18next.t("IdentCorp.idiomas")}</p>
									<p className="noText"></p>
								</div>
							</div>
							<a
								id="COLABORA"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/SIRVE.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>
					</div>
				</div>

				<div id="logotiposIdentidad">
					<div class="totalBenefits__title__container">
						<h1>Nuestro ADN</h1>
					</div>
				</div>

				<div className="wrapperDownload">
					<div className="wrapperIdenditad">
						<div className="wrapperbuttonBig ICONOS">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/frontal/ADN-10.png"
										alt="Icon"
									/>
									{/* <p>{i18next.t("IdentCorp.iconografia")}</p> */}
								</div>
							</div>
							<a
								id="ICONOS"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/ADN.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>
					</div>
				</div>

				<div id="logotiposIdentidad">
					<div class="totalBenefits__title__container">
						<h1>{i18next.t("IdentCorp.iconos")}</h1>
					</div>
				</div>

				<div className="wrapperDownload">
					<div className="wrapperIdenditad">
						{/* <div className="wrapperbuttonBig">
              <div className="wrapperTop">
                <div className="wrapperTopCell">
                  <img src={text}></img>
                  <p>Tipografía</p>
                </div>
              </div>
              <a className="wrapperBottom">
                <div className="downloadIdenti"><span></span><p>Descargar</p></div>
              </a>
            </div> */}

						<div className="wrapperbuttonBig ICONOS">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img src={icons} alt="Icon" />
									<p>{i18next.t("IdentCorp.iconografia")}</p>
								</div>
							</div>
							<a
								id="ICONOS"
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/I%CC%81conos.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img src={Word} alt="Word" />
									<p>{i18next.t("IdentCorp.hoja")}</p>
								</div>
							</div>
							<a
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/hoja%20membretada.zip"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>

						<div className="wrapperbuttonBig">
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img src={PowerPoint} alt="Power Point" />
									<p>{i18next.t("IdentCorp.presentacion")}</p>
								</div>
							</div>
							<a
								className="wrapperBottom"
								href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/logos/2023/press_AMNEW.pptx"
								target="_blank"
								rel="noopener noreferrer">
								<div className="downloadIdenti">
									<span></span>
									<p className="!text-[#0000E3]">{i18next.t("Sostenib.informes.descargar")}</p>
								</div>
							</a>
						</div>
					</div>

					<div id="logotiposIdentidad">
						<div class="totalBenefits__title__container anyquestions">
							<p>
								{i18next.t("IdentCorp.duda")}{" "}
								<a className="!text-[#0000E3]" href="mailto:amcomunicacioninterna@aeromexico.com">
									amcomunicacioninterna@aeromexico.com
								</a>
							</p>
						</div>
					</div>
				</div>
				<Index/>
			</div>
		);
	}
}
