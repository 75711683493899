import React, { Component, Fragment } from 'react';
import '../css/totalrewards.css';
import comsimg01 from '../../../assets/images/profile/comps/icon-comps-01.svg';
import comsimg02 from '../../../assets/images/profile/comps/icon-comps-02.svg';
import comsimg03 from '../../../assets/images/profile/comps/icon-comps-03.svg';
import comsimg04 from '../../../assets/images/profile/comps/icon-comps-04.svg';
import comsimg05 from '../../../assets/images/profile/comps/icon-comps-05.svg';
import comsimg06 from '../../../assets/images/profile/comps/icon-comps-06.svg';
import { UncontrolledTooltip } from 'reactstrap';

import CurrencyFormat from 'react-currency-format';

// import "../css/totalrewards.css"

import navidad from '../../../assets/images/profileBeneet/navidad.png';
import comsimg07 from '../../../assets/images/profile/comps/icon-comps-07.svg';
import isMockUser from '../../../utils/isMockUser';
import isMockSuperUser from '../../../utils/isMockSuperUser';
import isMockLevel from '../../../utils/isMockLevel';
import isMockNotLevel from '../../../utils/isMockNotLevel';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { HRBP } from './tempDB/hrbp_aeromexico';

const ArrayLodash = require('lodash/array');

// import navidad from "../../../assets/images/profileBeneet/navidad.png"

export default class Guaranteed extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.props.privateInfo,
			hrbp: !!localStorage.getItem('nombreHRBP')
				? localStorage.getItem('nombreHRBP')
				: null,
			hrbp_email: !!localStorage.getItem('emailHRBP')
				? localStorage.getItem('emailHRBP')
				: null,
			hasSecondHrbp: 0,
			secondHrbp: 'Daniela Leal',
			secondHrbp_email: 'dlealr@aeromexico.com',
			/*
      salario: Math.floor(parseFloat(localStorage.getItem('salario'))),
      salarioAnual: Math.floor(parseFloat(localStorage.getItem('salarioAnual'))),
      aguinaldo: Math.floor(parseFloat(localStorage.getItem('aguinaldo'))),
      primaVacacional: Math.floor(parseFloat(localStorage.getItem('primaVacacional'))),
      fondoAhorro: Math.floor(parseFloat(localStorage.getItem('fondoAhorro'))),
      exedente: Math.floor(parseFloat(localStorage.getItem('compensacion'))),
      valesDespensa:  Math.floor(parseFloat(localStorage.getItem('valesDespensa'))),
      bonoUtiles: Math.floor(parseFloat(localStorage.getItem('bonoUtiles'))),
      bonoAutomovil: Math.floor(parseFloat(localStorage.getItem('bonoAutomovil'))),*/

			salarioNum: this.props.privateInfo.salario,
			salarioAnualNum: this.props.privateInfo.salarioAnual,
			aguinaldoNum: this.props.privateInfo.aguinaldo,
			primaVacacionalNum: this.props.privateInfo.primaVacacional,
			fondoAhorroNum: this.props.privateInfo.fondoAhorro,
			compensacionNum: this.props.privateInfo.compensacion,
			valesDespensaNum: this.props.privateInfo.valesDespensa,
			bonoUtilesNum: this.props.privateInfo.bonoUtiles,
			bonoAutomovilNum: this.props.privateInfo.bonoAutomovil,
			bonoDesempenoNum: this.props.privateInfo.bonoDesempeno,
			// ltiNum: (localStorage.getItem('lti')),

			salario: this.props.privateInfo.salario,
			salarioAnual: this.props.privateInfo.salarioAnual,
			aguinaldo: this.props.privateInfo.aguinaldo,
			primaVacacional: this.props.privateInfo.primaVacacional,
			fondoAhorro: this.props.privateInfo.fondoAhorro,
			compensacion: this.props.privateInfo.compensacion,
			valesDespensa: this.props.privateInfo.valesDespensa,
			bonoUtiles: this.props.privateInfo.bonoUtiles,
			bonoAutomovil: this.props.privateInfo.bonoAutomovil,
			bonoDesempeno: this.props.privateInfo.bonoDesempeno,
			lti: this.props.privateInfo.lti,
			has_desempeno: false,
			performance: 0,
			percentage: 0,
			user_email: !!localStorage.getItem('email')
				? localStorage.getItem('email')
				: null
			// user_email: 'lahernandez@aeromexico.com'
			// bonoDesempeno: Math.floor(parseFloat(localStorage.getItem('bonoDesempeno').replace(/,/g, ''))),
		};
	}

	componentDidMount() {
		
		// if (window.location.hostname !== 'localhost') {
		// 	if (this.state.hrbp === null) {
		// 		const returned = ArrayLodash.findIndex(HRBP, function(item) {
		// 			return item.email === this.state.user_email;
		// 		});
		// 		console.log('RESULTADO DE HRBP BUSQUEDA: ', returned);
		// 		if (returned !== -1) {
		// 			this.setState({
		// 				...this.state,
		// 				hrbp: HRBP[returned].hrbp,
		// 				hrbp_email: HRBP[returned].hrbp_email,
		// 				hasSecondHrbp:
		// 					HRBP[returned].has_second === 0 ? false : true
		// 			});
		// 		}
		// 	}
		// }
		// # Variables que switchean las ligas que se consumen segun sea elcaso
const isDev = false; // Tomara entorno local
const isFromDev = false; //Se pondra en true para ambiente DEV
const isFromQA = true; //Se pondra en true para ambiente QA
/* Para productivo se ponen todas en falso */

// #URl de ambiente
const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
// const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
// const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

// # Liga validada
const urlValid = isDev ? urlLocal 
	: isFromDev ? urlDev
	: isFromQA ? urlQA
	: ''

// Se genera el token segun el ambiente
const tokenValid = isDev ? tokenLocal
	: isFromDev ? tokenDev
	: isFromQA  ? tokenQA
	: ''
	const cabecera = new Headers({
		'Content-type': 'application/json',
		'Authorization': `Bearer ${tokenValid}`
	});
		if (this.state.user_email !== null) {
			fetch(`${urlValid}/raises?email_eq=${this.state.user_email}`, {headers:cabecera} )
				.then((res) => res.json())
				.then((resData) => {
					
					if (resData.length > 0) {
						this.setState({
							...this.state,
							has_desempeno: true,
							performance: resData[0].performance,
							percentage: resData[0].percentage
						});
					}
				})
				.catch(console.error);
		}
	}

	validarCorreos(item) {
		// const theEmail = localStorage.getItem("email");
		if (
			item !== 0
			// (theEmail === "jgarciab@aeromexico.com" && item !== 0) ||
			// (theEmail === "jabadia@aeromexico.com" && item !== 0) ||
			// (theEmail === "aberber@aeromexico.com" && item !== 0) ||
			// (theEmail === "ediazo@aeromexico.com" && item !== 0) ||
			// (theEmail === "jtapias@aeromexico.com" && item !== 0) ||
			// (theEmail === "elmartinezh@aeromexico.com" && item !== 0)
		) {
			return true;
		} else {
			return false;
		}
	}

	bonoDesempenoInfoEs() {
		// var nivel = localStorage.getItem('nivel');
		var nivel = this.props.publicInfo.nivel;
		var posicionItem = this.props.publicInfo.posicion;
		var posicion = '';

		if (posicionItem !== null && posicionItem !== '') {
			posicion = posicionItem.toLowerCase();
		}

		/* Asistentes */
		if (nivel === '6' || nivel === '7') {
			if (
				posicion === 'asistente ejecutiva' ||
				posicion === 'asistente de direccion general'
			) {
				return 'Target 1.5 meses de sueldo';
			}
		}

		/* Niveles 6,7 y 8 */
		if (nivel === '6' || nivel === '7' || nivel === '8') {
			return 'Target 2 meses de sueldo';
		}
		if (nivel === '9') {
			return 'Target 20% de sueldo anual';
		}
		if (nivel === '10') {
			return 'Target 35% de sueldo anual';
		}
		if (nivel === '11') {
			return 'Target 35% de sueldo anual';
		}
		if (nivel === '12') {
			return 'Target 40% de sueldo anual';
		} else {
			return '--';
		}
	}

	bonoDesempenoInfoEn() {
		var nivel = this.props.publicInfo.nivel;
		var posicionItem = this.props.publicInfo.posicion;
		var posicion = '';

		if (posicionItem !== null && posicionItem !== '') {
			posicion = posicionItem.toLowerCase();
		}

		/* Asistentes */
		if (nivel === '6' || nivel === '7') {
			if (
				posicion === 'asistente ejecutiva' ||
				posicion === 'asistente de direccion general'
			) {
				return 'Target 1.5 months of salary';
			}
		}

		/* Niveles 6,7 y 8 */
		if (nivel === '6' || nivel === '7' || nivel === '8') {
			return 'Target 2 months of salary';
		}
		if (nivel === '9') {
			return 'Target 20% of annual salary';
		}
		if (nivel === '10') {
			return 'Target 35% of annual salary';
		}
		if (nivel === '11') {
			return 'Target 35% of annual salary';
		}
		if (nivel === '12') {
			return 'Target 40% of annual salary';
		} else {
			return '--';
		}
	}

	bonoDesempenoInfo() {
		if (this.isMockUser()) return 'Target 0%';

		if (i18next.language === 'en') {
			return this.bonoDesempenoInfoEs();
		} else {
			return this.bonoDesempenoInfoEn();
		}
	}

	isMockUser() {
		return (
			localStorage.getItem('email') === 'amaccesodelta@aeromexico.com' ||
			localStorage.getItem('email') ===
				'amaccesoskyteam@aeromexico.com' ||
			localStorage.getItem('email') ===
				'amaccesoinvitado@aeromexico.com' ||
			localStorage.getItem('email') ===
				'amskyteamaccess@aeromexico.com' ||
			localStorage.getItem('email') === 'amacessdelta@aeromexico.com' ||
			localStorage.getItem('email') === 'amaccessguest@aeromexico.com'
		);
	}

	validatePerformance(thePerf) {
		switch (thePerf) {
			case 3:
				return 'Excedió';
			case 2:
				return 'Cumplió';
			default:
				return 'Por debajo de las expectativas';
		}
	}

	render() {
		
		localStorage.setItem('sumaMensual', this.props.privateInfo.sumaMensual);
		localStorage.setItem('sumaAnual', this.props.privateInfo.sumaAnual);

		localStorage.setItem(
			'sumaAnualTotal',
			this.props.privateInfo.sumaAnualTotal
		);

		// Math.floor((localStorage.setItem('sumaAnualTotal', (this.state.salarioAnual + this.state.aguinaldo + this.state.primaVacacional +
		//   this.state.fondoAhorro + this.state.compensacion + this.state.valesDespensa + this.state.bonoUtiles +
		//   this.state.bonoAutomovil + this.state.bonoDesempeno))))

		// Math.floor(
		localStorage.setItem(
			'valesMenusal',
			this.props.privateInfo.valesMensual
		);
		// );
		// Math.floor(
		localStorage.setItem(
			'bonoAutomovilMensual',
			this.props.privateInfo.bonoAutomovilMensual
		);
		// );
		// Math.floor(
		localStorage.setItem(
			'compensacionMensual',
			this.props.privateInfo.compensacionMensual
		);
		// );

		return (
			<div className="container-guaranteed">
				<div className="contTitleComs">
					<h1 className="rewards_title">
						{i18next.t('MiExperAM.compensacion.garantizada.title')}
					</h1>
					{/* <a class="download benefitsRewards" href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/ERbEWztDH5JPjbYsJi4Gvm4BsabzEBQFBtns8wGO6JhKWQ?e=PTmHwg" target="_blank"><p>Descagar</p></a> */}
				</div>

				<div className="SalarioMoney nomarginSalario">
					<div className="widthMovilSalario">
						<div className="concepSalary">
							<p>
								{i18next.t(
									'MiExperAM.compensacion.garantizada.sueldo'
								)}
							</p>
						</div>

						<div className="MonthlySalary">
							<p className="smallText">
								{i18next.t('MiExperAM.compensacion.mensual')}
							</p>
							<p>
								<CurrencyFormat
									decimalSeparator="."
									decimalScale="2"
									fixedDecimalScale={true}
									value={this.state.salarioNum}
									displayType="text"
									thousandSeparator=","
									prefix=""
								/>
							</p>
						</div>

						<div className="MonthlySalary">
							<p className="smallText">
								{i18next.t('MiExperAM.compensacion.anual')}
							</p>
							<p>
								<CurrencyFormat
									decimalSeparator="."
									decimalScale="2"
									fixedDecimalScale={true}
									value={this.state.salarioAnualNum}
									displayType="text"
									thousandSeparator=","
									prefix=""
								/>
							</p>
						</div>
					</div>
				</div>

				<div className="SalarioMoneySmall nomarginSalario">
					<div className="MonthlySalary">
						<p className="smallText">
							{i18next.t(
								'MiExperAM.compensacion.garantizada.sueldo'
							)}{' '}
							{i18next.t('MiExperAM.compensacion.mensual')}
						</p>
						<p>{this.state.salarioNum} </p>
					</div>
					<div className="MonthlySalary">
						<p className="smallText">
							{i18next.t(
								'MiExperAM.compensacion.garantizada.sueldo'
							)}{' '}
							{i18next.t('MiExperAM.compensacion.anual')}
						</p>
						<p>{this.state.salarioAnualNum} </p>
					</div>
				</div>

				{this.state.aguinaldoNum !== 0 &&
				this.state.primaVacacionalNum !== 0 &&
				this.state.compensacionNum !== 0 &&
				this.state.fondoAhorroNum !== 0 &&
				this.state.valesDespensaNum !== 0 &&
				this.state.bonoUtilesNum !== 0 ? (
					<p className="moreInformationComp">
						{i18next.t(
							'MiExperAM.compensacion.garantizada.detalle'
						)}
					</p>
				) : (
					''
				)}

				{/* TABLA DE SALARIOS Y BONOS */}

				<div className="TableSalaryBonus">
					{this.state.aguinaldoNum !== 0 ? (
						<div
							className="widthConecpSalary concepComp01"
							id="AguinaldoTool"
						>
							{/**31 DIAS*/}
							{localStorage.getItem('compania') ===
								'Aeroméxico Cargo' ||
							localStorage.getItem('compania') ===
								'Aeroméxico Servicios' ? (
								// <div class="tooltipCompensations concepComp01tool">
								//   <div>
								//     <span></span>
								//     <p>31 días de sueldo proporcional de acuerdo a la fecha de ingreso.</p>
								//   </div>
								// </div>

								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="AguinaldoTool"
									delay={{ show: 0, hide: 0 }}
								>
									31{' '}
									{i18next.t(
										'MiExperAM.compensacion.garantizada.aguinaldoTip'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{/**30 DIAS*/}
							{localStorage.getItem('compania') ===
								'Aeroméxico' ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '10') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '8') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '10') ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="AguinaldoTool"
									delay={{ show: 0, hide: 0 }}
								>
									30{' '}
									{i18next.t(
										'MiExperAM.compensacion.garantizada.aguinaldoTip'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{/**22 DIAS*/}

							{(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '1') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '2') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '3') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '4') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '5') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '6') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '7') ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="AguinaldoTool"
									delay={{ show: 0, hide: 0 }}
								>
									22{' '}
									{i18next.t(
										'MiExperAM.compensacion.garantizada.aguinaldoTip'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{/**15 DIAS*/}
							{localStorage.getItem('compania') ===
								'Centro de Servicios Compartidos' ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '1') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '2') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '3') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '4') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '5') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '6') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '7') ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="AguinaldoTool"
									delay={{ show: 0, hide: 0 }}
								>
									15{' '}
									{i18next.t(
										'MiExperAM.compensacion.garantizada.aguinaldoTip'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							<div className="concepSalary">
								<div className="iconSalary">
									<img src={comsimg01} />
								</div>
								<p>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.aguinaldo'
									)}
								</p>
							</div>

							<div className="MonthlySalary">{/* <p></p>*/}</div>

							<div className="MonthlySalary">
								<p>
									{' '}
									<CurrencyFormat
										decimalSeparator="."
										decimalScale="2"
										fixedDecimalScale={true}
										value={this.state.aguinaldoNum}
										displayType="text"
										thousandSeparator=","
										prefix=""
									/>
								</p>
							</div>
						</div>
					) : (
						''
					)}
					{/* PRIMA VACACIONAL */}
					{/* {localStorage.getItem("primaVacacional") != "0" ||
					(isMockUser() && this.state.primaVacacionalNum != "0") ? ( */}
					{this.props.privateInfo.primaVacacional !== 0 ? (
						<div
							className="widthConecpSalary concepComp02"
							id="primaVacaional"
						>
							{localStorage.getItem('compania') ===
							'Aeroméxico' ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="primaVacaional"
									delay={{ show: 0, hide: 0 }}
								>
									<Trans i18nKey="MiExperAM.compensacion.garantizada.prima.tier1"></Trans>
								</UncontrolledTooltip>
							) : (
								''
							)}

							{localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
							localStorage.getItem('nivel') === '10' ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="primaVacaional"
									delay={{ show: 0, hide: 0 }}
								>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.prima.tier2'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{/* {(localStorage.getItem("compania") ===
								"Aeroméxico Connect" &&
								localStorage.getItem("nivel") === "1") ||
							(localStorage.getItem("compania") ===
								"Aeroméxico Connect" &&
								localStorage.getItem("nivel") === "2") ||
							(localStorage.getItem("compania") ===
								"Aeroméxico Connect" &&
								localStorage.getItem("nivel") === "3") ||
							(localStorage.getItem("compania") ===
								"Aeroméxico Connect" &&
								localStorage.getItem("nivel") === "4") ||
							(localStorage.getItem("compania") ===
								"Aeroméxico Connect" &&
								localStorage.getItem("nivel") === "5") ||
							(localStorage.getItem("compania") ===
								"Aeroméxico Connect" &&
								localStorage.getItem("nivel") === "6") ||
							(localStorage.getItem("compania") ===
								"Aeroméxico Connect" &&
								localStorage.getItem("nivel") === "7") ||
							(localStorage.getItem("compania") ===
								"Aeroméxico Connect" &&
								localStorage.getItem("nivel") === "8") ||
							localStorage.getItem("compania") ===
								"Centro de Servicios Compartidos" ? ( */}
							{(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') < 9) ||
							localStorage.getItem('compania') ===
								'Centro de Servicios Compartidos' ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="primaVacaional"
									delay={{ show: 0, hide: 0 }}
								>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.prima.tier3'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{localStorage.getItem('compania') ===
								'Aeroméxico Servicios' ||
							localStorage.getItem('compania') ===
								'Aeroméxico Cargo' ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="primaVacaional"
									delay={{ show: 0, hide: 0 }}
								>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.prima.tier4'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{localStorage.getItem('compania') ===
							'Aeroméxico Formación' ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="primaVacaional"
									delay={{ show: 0, hide: 0 }}
								>
									<Trans i18nKey="MiExperAM.compensacion.garantizada.prima.tier5"></Trans>
								</UncontrolledTooltip>
							) : (
								''
							)}

							<div className="concepSalary">
								<div className="iconSalary">
									<img src={comsimg02} />
								</div>
								<p>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.prima.title'
									)}
								</p>
							</div>

							<div className="MonthlySalary">{/* <p></p>*/}</div>

							<div className="MonthlySalary">
								<p>
									<CurrencyFormat
										decimalSeparator="."
										decimalScale="2"
										fixedDecimalScale={true}
										value={this.state.primaVacacionalNum}
										displayType="text"
										thousandSeparator=","
										prefix=""
									/>
								</p>
								{/* <p>{this.state.primaVacacionalNum} </p> */}
							</div>
						</div>
					) : (
						''
					)}
					{/* FONDO*/}
					{/* {(localStorage.getItem("compania") ===
						"Aeroméxico Connect" &&
						localStorage.getItem("fondoAhorro") != "0") ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Servicios" &&
						localStorage.getItem("fondoAhorro") != "0") ||
					(localStorage.getItem("compania") === "Aeroméxico Cargo" &&
						localStorage.getItem("fondoAhorro") != "0") ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Formación" &&
						localStorage.getItem("fondoAhorro") != "0") ||
					(localStorage.getItem("compania") === "Aeroméxico" &&
						localStorage.getItem("fondoAhorro") != "0") ||
					(isMockUser() && this.state.fondoAhorroNum != "0") ? ( */}
					{/* FONDO DE AHORRO */}
					{this.props.privateInfo.fondoAhorro !== 0 ? (
						<div
							className="widthConecpSalary concepComp03"
							id="fondoAhorro"
						>
							{localStorage.getItem('compania') ===
							'Aeroméxico' ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="fondoAhorro"
									delay={{ show: 0, hide: 0 }}
								>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.ahorroTip1'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{localStorage.getItem('compania') ===
								'Aeroméxico Connect' ||
							localStorage.getItem('compania') ===
								'Aeroméxico Servicios' ||
							localStorage.getItem('compania') ===
								'Aeroméxico Cargo' ||
							localStorage.getItem('compania') ===
								'Aeroméxico Formación' ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="fondoAhorro"
									delay={{ show: 0, hide: 0 }}
								>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.ahorroTip2'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							<div className="concepSalary">
								<div className="iconSalary">
									<img src={comsimg03} />
								</div>
								<p>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.ahorro'
									)}
								</p>
							</div>

							<div className="MonthlySalary">{/* <p></p>*/}</div>

							<div className="MonthlySalary">
								<p>
									<CurrencyFormat
										decimalSeparator="."
										decimalScale="2"
										fixedDecimalScale={true}
										value={this.state.fondoAhorroNum}
										displayType="text"
										thousandSeparator=","
										prefix=""
									/>
								</p>
							</div>
						</div>
					) : (
						''
					)}
					{/* EXCEDENTE*/}
					{/* EXCEDENTE */}
					{/* {localStorage.getItem("email") ===
						"jgarciab@aeromexico.com" &&
					localStorage.getItem("compensacion") !== 0 ? ( */}
					{/* {(localStorage.getItem('compania') === 'Aeroméxico' &&
						this.props.privateInfo.compensacion !== 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Servicios' &&
						this.props.privateInfo.compensacion !== 0) ||
					(isMockUser() && this.state.compensacionNum !== 0)  */}
					{this.props.privateInfo.compensacionMensual !== 0 ? (
						<div
							className="widthConecpSalary concepComp010"
							id="ExcedentefondoAhorro"
						>
							<UncontrolledTooltip
								placement="auto"
								autohide={false}
								target="ExcedentefondoAhorro"
								delay={{ show: 0, hide: 0 }}
							>
								{localStorage.getItem('compania') ===
								'Aeroméxico'
									? i18next.t(
											'MiExperAM.compensacion.garantizada.faTip1'
									  )
									: i18next.t(
											'MiExperAM.compensacion.garantizada.faTip2'
									  )}
							</UncontrolledTooltip>
							{/* {(localStorage.getItem('compania') ===
								'Aeroméxico' &&
								this.props.privateInfo.compensacion !== 0) ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Servicios' &&
								this.props.privateInfo.compensacion !== 0) ||
							isMockUser() ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="ExcedentefondoAhorro"
									delay={{ show: 0, hide: 0 }}
								>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.faTip2'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{localStorage.getItem('compania') ===
							'Aeroméxico Formación' ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="ExcedentefondoAhorro"
									delay={{ show: 0, hide: 0 }}
								>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.faTip2'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)} */}

							<div className="concepSalary">
								<div className="iconSalary">
									<img src={comsimg03} />
								</div>
								<p>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.fa'
									)}
								</p>
							</div>
							<div className="MonthlySalary">
								<CurrencyFormat
									decimalSeparator="."
									decimalScale="2"
									fixedDecimalScale={true}
									value={
										this.props.privateInfo
											.compensacionMensual
									}
									displayType={'text'}
									thousandSeparator=","
									// decimalScale={'2'}
									prefix={''}
									renderText={(value) => <p>{value}  </p>}
								/>
							</div>
							<div className="MonthlySalary">
								<CurrencyFormat
									decimalSeparator="."
									decimalScale="2"
									fixedDecimalScale={true}
									value={this.props.privateInfo.compensacion}
									displayType={'text'}
									thousandSeparator=","
									// decimalScale={'2'}
									prefix={''}
									renderText={(value) => <p>{value}  </p>}
								/>
								{/* <p>{this.state.compensacionNum} </p> */}
							</div>
						</div>
					) : (
						''
					)}
					{/* Vales Despensa*/}
					{this.props.privateInfo.valesDespensa !== 0 ? (
						<div
							className="widthConecpSalary concepComp04"
							id="ValesDespensa"
						>
							<UncontrolledTooltip
								placement="auto"
								autohide={false}
								target="ValesDespensa"
								delay={{ show: 0, hide: 0 }}
							>
								{i18next.t(
									'MiExperAM.compensacion.garantizada.despensaTip'
								)}
							</UncontrolledTooltip>

							<div className="concepSalary">
								<div className="iconSalary">
									<img src={comsimg04} />
								</div>
								<p>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.despensa'
									)}
								</p>
							</div>

							<div className="MonthlySalary">
								{/*<p>{localStorage.getItem('valesMenusal')}  </p>*/}
								<CurrencyFormat
									decimalSeparator="."
									decimalScale="2"
									fixedDecimalScale={true}
									value={this.props.privateInfo.valesMensual}
									displayType={'text'}
									thousandSeparator=","
									// decimalScale={'2'}
									prefix={''}
									renderText={(value) => <p>{value}  </p>}
								/>
							</div>

							<div className="MonthlySalary">
								<CurrencyFormat
									decimalSeparator="."
									decimalScale="2"
									fixedDecimalScale={true}
									value={this.props.privateInfo.valesDespensa}
									displayType={'text'}
									thousandSeparator=","
									prefix={''}
									renderText={(value) => <p>{value}  </p>}
								/>
								{/* <p>{this.state.valesDespensaNum} </p> */}
							</div>
						</div>
					) : (
						''
					)}
					{/* RowColumn*/}
					{/* BONOS UTILES */}
					{/* {(localStorage.getItem("compania") ===
						"Aeroméxico Servicios" &&
						localStorage.getItem("nivel") === "1" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Servicios" &&
						localStorage.getItem("nivel") === "2" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Servicios" &&
						localStorage.getItem("nivel") === "3" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Servicios" &&
						localStorage.getItem("nivel") === "4" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Servicios" &&
						localStorage.getItem("nivel") === "5" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Servicios" &&
						localStorage.getItem("nivel") === "6" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Servicios" &&
						localStorage.getItem("nivel") === "7" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Servicios" &&
						localStorage.getItem("nivel") === "8" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Servicios" &&
						localStorage.getItem("nivel") === "10" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico Cargo" &&
						localStorage.getItem("nivel") === "1" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico Cargo" &&
						localStorage.getItem("nivel") === "2" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico Cargo" &&
						localStorage.getItem("nivel") === "3" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico Cargo" &&
						localStorage.getItem("nivel") === "4" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico Cargo" &&
						localStorage.getItem("nivel") === "5" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico Cargo" &&
						localStorage.getItem("nivel") === "6" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico Cargo" &&
						localStorage.getItem("nivel") === "7" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico Cargo" &&
						localStorage.getItem("nivel") === "8" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico Cargo" &&
						localStorage.getItem("nivel") === "9" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico Cargo" &&
						localStorage.getItem("nivel") === "10" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico Cargo" &&
						localStorage.getItem("nivel") === "11" &&
						localStorage.getItem("bonoUtiles") != 0) ||
					isMockSuperUser() ||
					this.state.bonoUtilesNum != 0 ? ( */}
					{this.validarCorreos(this.state.bonoUtiles) && (
						<div
							className="widthConecpSalary concepComp05"
							id="utilesEscolares"
						>
							<UncontrolledTooltip
								placement="auto"
								autohide={false}
								target="utilesEscolares"
								delay={{ show: 0, hide: 0 }}
							>
								{i18next.t(
									'MiExperAM.compensacion.garantizada.escolaresTip'
								)}
							</UncontrolledTooltip>

							<div className="concepSalary">
								<div className="iconSalary">
									<img src={comsimg05} />
								</div>
								<p>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.escolares'
									)}
								</p>
							</div>

							<div className="MonthlySalary">
								<CurrencyFormat
									decimalSeparator="."
									decimalScale="2"
									fixedDecimalScale={true}
									value={
										this.props.privateInfo.bonoUtilesMensual
									}
									displayType={'text'}
									thousandSeparator=","
									prefix={''}
									renderText={(value) => <p>{value}  </p>}
								/>
								{/* <p>{Math.floor(this.state.bonoUtiles / 12)} </p> */}
							</div>

							<div className="MonthlySalary">
								<CurrencyFormat
									decimalSeparator="."
									decimalScale="2"
									fixedDecimalScale={true}
									value={this.props.privateInfo.bonoUtiles}
									displayType={'text'}
									thousandSeparator=","
									prefix={''}
									renderText={(value) => <p>{value}  </p>}
								/>
								{/* <p>{this.state.bonoUtilesNum} </p> */}
							</div>
						</div>
					)}
					{/* RowColumn*/}
					{/* {(localStorage.getItem("compania") === "Aeroméxico" &&
						localStorage.getItem("nivel") === "8" &&
						localStorage.getItem("bonoAutomovil") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico" &&
						localStorage.getItem("nivel") === "9" &&
						localStorage.getItem("bonoAutomovil") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico" &&
						localStorage.getItem("nivel") === "10" &&
						localStorage.getItem("bonoAutomovil") != 0) ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Connect" &&
						localStorage.getItem("nivel") === "10" &&
						localStorage.getItem("bonoAutomovil") != 0) ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Connect" &&
						localStorage.getItem("nivel") === "8" &&
						localStorage.getItem("bonoAutomovil") != 0) ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Servicios" &&
						localStorage.getItem("nivel") === "10" &&
						localStorage.getItem("bonoAutomovil") != 0) ||
					(localStorage.getItem("compania") === "Aeroméxico Cargo" &&
						localStorage.getItem("nivel") === "10" &&
						localStorage.getItem("bonoAutomovil") != 0) ||
					(localStorage.getItem("compania") ===
						"Aeroméxico Formación" &&
						localStorage.getItem("nivel") === "10" &&
						localStorage.getItem("bonoAutomovil") != 0) ||
					isMockLevel(8) ||
					isMockLevel(9) ||
					isMockLevel(10) ||
					isMockSuperUser() ? ( */}
					{this.state.bonoAutomovilNum !== 0 && (
						<div
							className="widthConecpSalary concepComp06"
							id="bonoAutomovil"
						>
							<UncontrolledTooltip
								placement="auto"
								autohide={false}
								target="bonoAutomovil"
								delay={{ show: 0, hide: 0 }}
							>
								{i18next.t(
									'MiExperAM.compensacion.garantizada.autoTip'
								)}
							</UncontrolledTooltip>

							<div className="concepSalary">
								<div className="iconSalary">
									<img src={comsimg06} />
								</div>
								<p>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.auto'
									)}
								</p>
							</div>
							<div className="MonthlySalary">
								
								{/* {localStorage.getItem('nivel') === '7' || */}
								{parseInt(localStorage.getItem('nivel')) <
									10 && (
									<CurrencyFormat
										decimalSeparator="."
										decimalScale="2"
										fixedDecimalScale={true}
										value={
											this.props.privateInfo
												.bonoAutomovilMensual
										}
										displayType={'text'}
										thousandSeparator=","
										prefix={''}
										renderText={(value) => <p>{value}  </p>}
									/>
								)}
							</div>
							{/* CIFRA ANUAL BONOAUTOMOVIL */}
							<div className="MonthlySalary">
								<CurrencyFormat
									decimalSeparator="."
									decimalScale="2"
									fixedDecimalScale={true}
									value={this.props.privateInfo.bonoAutomovil}
									displayType={'text'}
									thousandSeparator=","
									prefix={''}
									renderText={(value) => <p>{value}  </p>}
								/>
								{/* <p>{this.state.bonoAutomovilNum} </p> */}
							</div>
						</div>
					)}
				</div>

				{/* VERSION SMALL PARA TABLA DE SALDOS */}
				<div className="TableSalaryBonusSmall">
					{this.state.aguinaldoNum !== 0 ? (
						<div id="AguinaldoToolSmall">
							{/**31 DIAS*/}
							{localStorage.getItem('compania') ===
								'Aeroméxico Cargo' ||
							localStorage.getItem('compania') ===
								'Aeroméxico Servicios' ? (
								// <div class="tooltipCompensations concepComp01tool">
								//   <div>
								//     <span></span>
								//     <p>31 días de sueldo proporcional de acuerdo a la fecha de ingreso.</p>
								//   </div>
								// </div>

								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="AguinaldoToolSmall"
									delay={{ show: 0, hide: 0 }}
								>
									31{' '}
									{i18next.t(
										'MiExperAM.compensacion.garantizada.aguinaldoTip'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{/**30 DIAS*/}
							{localStorage.getItem('compania') ===
								'Aeroméxico' ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '10') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '8') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '10') ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="AguinaldoToolSmall"
									delay={{ show: 0, hide: 0 }}
								>
									30{' '}
									{i18next.t(
										'MiExperAM.compensacion.garantizada.aguinaldoTip'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{/**22 DIAS*/}

							{(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '1') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '2') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '3') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '4') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '5') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '6') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '7') ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="AguinaldoToolSmall"
									delay={{ show: 0, hide: 0 }}
								>
									22{' '}
									{i18next.t(
										'MiExperAM.compensacion.garantizada.aguinaldoTip'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{/**15 DIAS*/}
							{localStorage.getItem('compania') ===
								'Centro de Servicios Compartidos' ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '1') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '2') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '3') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '4') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '5') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '6') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Formación' &&
								localStorage.getItem('nivel') === '7') ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="AguinaldoToolSmall"
									delay={{ show: 0, hide: 0 }}
								>
									15{' '}
									{i18next.t(
										'MiExperAM.compensacion.garantizada.aguinaldoTip'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							<div className="MonthlySalary">
								<div className="iconSalary">
									<img src={comsimg01} />
								</div>
								<p>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.aguinaldo'
									)}
								</p>
								<div className="amount">
									<p>
										-- (
										{i18next.t(
											'MiExperAM.compensacion.mensual'
										)}
										)
									</p>
									<CurrencyFormat
										decimalSeparator="."
										decimalScale="2"
										fixedDecimalScale={true}
										value={this.props.privateInfo.aguinaldo}
										displayType={'text'}
										thousandSeparator=","
										prefix={''}
										renderText={(value) => <p>{value}  </p>}
									/>
									{/* <p>
										{this.state.aguinaldoNum} (
										{i18next.t(
											'MiExperAM.compensacion.anual'
										)}
										)
									</p> */}
								</div>
							</div>
						</div>
					) : (
						''
					)}

					{localStorage.getItem('primaVacacional') !== 0 ||
					(isMockUser() && this.state.primaVacacionalNum !== 0) ? (
						<div id="primaVacaionalSmall">
							{localStorage.getItem('compania') ===
							'Aeroméxico' ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="primaVacaionalSmall"
									delay={{ show: 0, hide: 0 }}
								>
									<Trans i18nKey="MiExperAM.compensacion.garantizada.prima.tier1"></Trans>
								</UncontrolledTooltip>
							) : (
								''
							)}

							{localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
							localStorage.getItem('nivel') === '10' ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="primaVacaionalSmall"
									delay={{ show: 0, hide: 0 }}
								>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.prima.tier2'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '1') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '2') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '3') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '4') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '5') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '6') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '7') ||
							(localStorage.getItem('compania') ===
								'Aeroméxico Connect' &&
								localStorage.getItem('nivel') === '8') ||
							localStorage.getItem('compania') ===
								'Centro de Servicios Compartidos' ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="primaVacaionalSmall"
									delay={{ show: 0, hide: 0 }}
								>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.prima.tier3'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{localStorage.getItem('compania') ===
								'Aeroméxico Servicios' ||
							localStorage.getItem('compania') ===
								'Aeroméxico Cargo' ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="primaVacaionalSmall"
									delay={{ show: 0, hide: 0 }}
								>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.prima.tier4'
									)}
								</UncontrolledTooltip>
							) : (
								''
							)}

							{localStorage.getItem('compania') ===
							'Aeroméxico Formación' ? (
								<UncontrolledTooltip
									placement="auto"
									autohide={false}
									target="primaVacaionalSmall"
									delay={{ show: 0, hide: 0 }}
								>
									<Trans i18nKey="MiExperAM.compensacion.garantizada.prima.tier5"></Trans>
								</UncontrolledTooltip>
							) : (
								''
							)}
							<div className="MonthlySalary">
								<div className="iconSalary">
									<img src={comsimg02} />
								</div>
								<p>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.prima.title'
									)}
								</p>
								<div className="amount">
									<p>
										-- (
										{i18next.t(
											'MiExperAM.compensacion.mensual'
										)}
										)
									</p>
									<p>
										<CurrencyFormat
											decimalSeparator="."
											decimalScale="2"
											fixedDecimalScale={true}
											value={
												this.state.primaVacacionalNum
											}
											displayType="text"
											thousandSeparator=","
											prefix=""
										/>
										(
										{i18next.t(
											'MiExperAM.compensacion.anual'
										)}
										)
									</p>
								</div>
							</div>
						</div>
					) : (
						''
					)}

					{/* FONDO*/}
					{(localStorage.getItem('compania') ===
						'Aeroméxico Connect' &&
						this.props.privateInfo.fondoAhorro !== 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Servicios' &&
						this.props.privateInfo.fondoAhorro !== 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
						this.props.privateInfo.fondoAhorro !== 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Formación' &&
						this.props.privateInfo.fondoAhorro !== 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico' &&
						this.props.privateInfo.fondoAhorro !== 0) ||
					isMockUser() ? (
						<div>
							{this.state.fondoAhorroNum != 0 ? (
								<div id="fondoAhorroSmall">
									{localStorage.getItem('compania') ===
									'Aeroméxico' ? (
										<UncontrolledTooltip
											placement="auto"
											autohide={false}
											target="fondoAhorroSmall"
											delay={{ show: 0, hide: 0 }}
										>
											{i18next.t(
												'MiExperAM.compensacion.garantizada.ahorroTip1'
											)}
										</UncontrolledTooltip>
									) : (
										''
									)}

									{localStorage.getItem('compania') ===
										'Aeroméxico Connect' ||
									localStorage.getItem('compania') ===
										'Aeroméxico Servicios' ||
									localStorage.getItem('compania') ===
										'Aeroméxico Cargo' ||
									localStorage.getItem('compania') ===
										'Aeroméxico Formación' ? (
										<UncontrolledTooltip
											placement="auto"
											autohide={false}
											target="fondoAhorroSmall"
											delay={{ show: 0, hide: 0 }}
										>
											{i18next.t(
												'MiExperAM.compensacion.garantizada.ahorroTip2'
											)}
										</UncontrolledTooltip>
									) : (
										''
									)}
									<div className="MonthlySalary">
										<div className="iconSalary">
											<img src={comsimg03} />
										</div>
										<p>
											{i18next.t(
												'MiExperAM.compensacion.garantizada.ahorro'
											)}
										</p>
										<div className="amount">
											<p>
												-- (
												{i18next.t(
													'MiExperAM.compensacion.mensual'
												)}
												)
											</p>
											<p>
												{this.state.fondoAhorroNum} (
												{i18next.t(
													'MiExperAM.compensacion.anual'
												)}
												)
											</p>
										</div>
									</div>
								</div>
							) : (
								''
							)}
						</div>
					) : (
						''
					)}

					{/* EXCEDENTE*/}
					{(localStorage.getItem('compania') === 'Aeroméxico' &&
						localStorage.getItem('compensacion') !== 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Servicios' &&
						localStorage.getItem('compensacion') !== 0) ||
					isMockUser() ? (
						<div>
							{this.state.compensacionNum !== 0 ? (
								<div id="ExcedentefondoAhorroSmall">
									{(localStorage.getItem('compania') ===
										'Aeroméxico' &&
										localStorage.getItem('compensacion') !==
											0) ||
									(localStorage.getItem('compania') ===
										'Aeroméxico Servicios' &&
										localStorage.getItem('compensacion') !==
											0) ||
									isMockUser() ? (
										<UncontrolledTooltip
											placement="auto"
											autohide={false}
											target="ExcedentefondoAhorroSmall"
											delay={{ show: 0, hide: 0 }}
										>
											{i18next.t(
												'MiExperAM.compensacion.garantizada.faTip2'
											)}
										</UncontrolledTooltip>
									) : (
										''
									)}
									{localStorage.getItem('compania') ===
									'Aeroméxico Formación' ? (
										<UncontrolledTooltip
											placement="auto"
											autohide={false}
											target="ExcedentefondoAhorroSmall"
											delay={{ show: 0, hide: 0 }}
										>
											{i18next.t(
												'MiExperAM.compensacion.garantizada.faTip2'
											)}
										</UncontrolledTooltip>
									) : (
										''
									)}
									<div className="MonthlySalary">
										<div className="iconSalary">
											<img src={comsimg03} />
										</div>
										<p>
											{i18next.t(
												'MiExperAM.compensacion.garantizada.fa'
											)}
										</p>
										<div className="amount">
											<CurrencyFormat
												decimalSeparator="."
												decimalScale="2"
												fixedDecimalScale={true}
												value={
													this.props.privateInfo
														.compensacionMensual
												}
												displayType={'text'}
												thousandSeparator=","
												prefix={''}
												renderText={(value) => (
													<p>
														{value}   (
														{i18next.t(
															'MiExperAM.compensacion.mensual'
														)}
														)
													</p>
												)}
											/>
											<CurrencyFormat
												decimalSeparator="."
												decimalScale="2"
												fixedDecimalScale={true}
												value={
													this.props.privateInfo
														.compensacion
												}
												displayType={'text'}
												thousandSeparator=","
												prefix={''}
												renderText={(value) => (
													<p>
														{value}   (
														{i18next.t(
															'MiExperAM.compensacion.mensual'
														)}
														)
													</p>
												)}
											/>
											{/* <p>
												
												{this.state.compensacionNum} (
												{i18next.t(
													'MiExperAM.compensacion.anual'
												)}
												)
											</p> */}
										</div>
									</div>
								</div>
							) : (
								''
							)}
						</div>
					) : (
						''
					)}

					{/* Vales Despensa*/}

					{(localStorage.getItem('valesDespensa') !== 0 &&
						localStorage.getItem('nivel') !== '13' &&
						localStorage.getItem('nivel') !== '14' &&
						localStorage.getItem('nivel') !== '16') ||
					isMockNotLevel(13) ||
					isMockNotLevel(14) ||
					isMockNotLevel(16) ? (
						<div>
							{this.state.valesDespensaNum != 0 ? (
								<div id="ValesDespensaSmall">
									<UncontrolledTooltip
										placement="auto"
										autohide={false}
										target="ValesDespensaSmall"
										delay={{ show: 0, hide: 0 }}
									>
										{i18next.t(
											'MiExperAM.compensacion.garantizada.despensaTip'
										)}
									</UncontrolledTooltip>
									<div className="MonthlySalary">
										<div className="iconSalary">
											<img src={comsimg04} />
										</div>
										<p>
											{i18next.t(
												'MiExperAM.compensacion.garantizada.despensa'
											)}
										</p>
										<div className="amount">
											<CurrencyFormat
												decimalSeparator="."
												decimalScale="2"
												fixedDecimalScale={true}
												value={
													this.props.privateInfo
														.valesMenusal
												}
												displayType={'text'}
												thousandSeparator=","
												// decimalScale={'2'}
												prefix={''}
												renderText={(value) => (
													<p>
														{value}   (
														{i18next.t(
															'MiExperAM.compensacion.mensual'
														)}
														)
													</p>
												)}
											/>
											<p>
												{this.state.valesDespensaNum} (
												{i18next.t(
													'MiExperAM.compensacion.anual'
												)}
												)
											</p>
										</div>
									</div>
								</div>
							) : (
								''
							)}
						</div>
					) : (
						''
					)}

					{/* RowColumn*/}
					{(localStorage.getItem('compania') ===
						'Aeroméxico Servicios' &&
						localStorage.getItem('nivel') === '1' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Servicios' &&
						localStorage.getItem('nivel') === '2' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Servicios' &&
						localStorage.getItem('nivel') === '3' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Servicios' &&
						localStorage.getItem('nivel') === '4' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Servicios' &&
						localStorage.getItem('nivel') === '5' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Servicios' &&
						localStorage.getItem('nivel') === '6' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Servicios' &&
						localStorage.getItem('nivel') === '7' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Servicios' &&
						localStorage.getItem('nivel') === '8' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Servicios' &&
						localStorage.getItem('nivel') === '10' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
						localStorage.getItem('nivel') === '1' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
						localStorage.getItem('nivel') === '2' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
						localStorage.getItem('nivel') === '3' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
						localStorage.getItem('nivel') === '4' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
						localStorage.getItem('nivel') === '5' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
						localStorage.getItem('nivel') === '6' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
						localStorage.getItem('nivel') === '7' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
						localStorage.getItem('nivel') === '8' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
						localStorage.getItem('nivel') === '9' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
						localStorage.getItem('nivel') === '10' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
						localStorage.getItem('nivel') === '11' &&
						localStorage.getItem('bonoUtiles') != 0) ||
					isMockSuperUser() ? (
						<div>
							{this.state.bonoUtilesNum != 0 ? (
								<div id="utilesEscolaresSmall">
									<UncontrolledTooltip
										placement="auto"
										autohide={false}
										target="utilesEscolaresSmall"
										delay={{ show: 0, hide: 0 }}
									>
										{i18next.t(
											'MiExperAM.compensacion.garantizada.escolaresTip'
										)}
									</UncontrolledTooltip>
									<div className="MonthlySalary">
										<div className="iconSalary">
											<img src={comsimg05} />
										</div>
										<p>
											{i18next.t(
												'MiExperAM.compensacion.garantizada.escolares'
											)}
										</p>
										<div className="amount">
											<p>
												{Math.floor(
													this.state.bonoUtiles / 12
												)}{' '}
												(
												{i18next.t(
													'MiExperAM.compensacion.mensual'
												)}
												)
											</p>
											<p>
												{this.state.bonoUtilesNum} (
												{i18next.t(
													'MiExperAM.compensacion.anual'
												)}
												)
											</p>
										</div>
									</div>
								</div>
							) : (
								''
							)}
						</div>
					) : (
						''
					)}
					{/* RowColumn*/}
					{(localStorage.getItem('compania') === 'Aeroméxico' &&
						localStorage.getItem('nivel') === '8' &&
						localStorage.getItem('bonoAutomovil') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico' &&
						localStorage.getItem('nivel') === '9' &&
						localStorage.getItem('bonoAutomovil') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico' &&
						localStorage.getItem('nivel') === '10' &&
						localStorage.getItem('bonoAutomovil') != 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Connect' &&
						localStorage.getItem('nivel') === '10' &&
						localStorage.getItem('bonoAutomovil') != 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Connect' &&
						localStorage.getItem('nivel') === '8' &&
						localStorage.getItem('bonoAutomovil') != 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Servicios' &&
						localStorage.getItem('nivel') === '10' &&
						localStorage.getItem('bonoAutomovil') != 0) ||
					(localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
						localStorage.getItem('nivel') === '10' &&
						localStorage.getItem('bonoAutomovil') != 0) ||
					(localStorage.getItem('compania') ===
						'Aeroméxico Formación' &&
						localStorage.getItem('nivel') === '10' &&
						localStorage.getItem('bonoAutomovil') != 0) ||
					isMockLevel(8) ||
					isMockLevel(9) ||
					isMockLevel(10) ||
					isMockSuperUser() ? (
						<div>
							{this.state.bonoAutomovilNum != 0 ? (
								<div id="bonoAutomovilSmall">
									<UncontrolledTooltip
										placement="auto"
										autohide={false}
										target="bonoAutomovilSmall"
										delay={{ show: 0, hide: 0 }}
									>
										{i18next.t(
											'MiExperAM.compensacion.garantizada.autoTip'
										)}
									</UncontrolledTooltip>
									<div className="MonthlySalary">
										<div className="iconSalary">
											<img src={comsimg06} />
										</div>
										<p>
											{i18next.t(
												'MiExperAM.compensacion.garantizada.auto'
											)}
										</p>
										<div className="amount">
											{localStorage.getItem('nivel') ===
												'7' ||
											localStorage.getItem('nivel') ===
												'8' ||
											localStorage.getItem('nivel') ===
												'9' ? (
												<CurrencyFormat
													decimalSeparator="."
													decimalScale="2"
													fixedDecimalScale={true}
													value={
														this.props.privateInfo
															.bonoAutomovilMensual
													}
													displayType={'text'}
													thousandSeparator=","
													prefix={''}
													renderText={(value) => (
														<p>
															{value}   (
															{i18next.t(
																'MiExperAM.compensacion.mensual'
															)}
															)
														</p>
													)}
												/>
											) : (
												''
											)}
											<CurrencyFormat
												decimalSeparator="."
												decimalScale="2"
												fixedDecimalScale={true}
												value={
													this.props.privateInfo
														.bonoAutomovil
												}
												displayType={'text'}
												thousandSeparator=","
												prefix={''}
												renderText={(value) => (
													<p>
														{value}   (
														{i18next.t(
															'MiExperAM.compensacion.mensual'
														)}
														)
													</p>
												)}
											/>
											{/* <p>
												{this.state.bonoAutomovilNum} (
												{i18next.t(
													'MiExperAM.compensacion.anual'
												)}
												)
											</p> */}
										</div>
									</div>
								</div>
							) : (
								''
							)}
						</div>
					) : (
						''
					)}
				</div>

				{isMockUser() ? (
					<div>
						<div className="SalarioMoney BlueBackground">
							<div className="widthMovilSalario">
								<div className="concepSalary">
									<p>
										{i18next.t(
											'MiExperAM.compensacion.garantizada.totalGarantizada'
										)}
									</p>
								</div>
								<div className="MonthlySalary">
									{/*<p className="smallText">Mensual</p>*/}
									<CurrencyFormat
										decimalSeparator="."
										decimalScale="2"
										fixedDecimalScale={true}
										value={
											this.props.privateInfo.sumaMensual
										}
										displayType={'text'}
										thousandSeparator=","
										// decimalScale={'2'}
										prefix={''}
										renderText={(value) => <p>{value}  </p>}
									/>
								</div>
								<div className="MonthlySalary">
									{/*<p className="smallText">Anual</p>*/}
									<CurrencyFormat
										decimalSeparator="."
										decimalScale="2"
										fixedDecimalScale={true}
										value={this.props.privateInfo.sumaAnual}
										displayType={'text'}
										thousandSeparator=","
										// decimalScale={'2'}
										prefix={''}
										renderText={(value) => <p>{value}  </p>}
									/>
									{/*<p>{ localStorage.getItem('sumaAnual') } </p>*/}
								</div>
							</div>
						</div>

						<div
							className="SalarioMoneySmall"
							style={{ backgroundColor: '#007DC3' }}
						>
							<div className="MonthlySalary">
								<p
									className="smallText"
									style={{ color: 'white' }}
								>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.totalGarantizada'
									)}{' '}
									{i18next.t(
										'MiExperAM.compensacion.mensual'
									)}
								</p>
								<CurrencyFormat
									decimalSeparator="."
									decimalScale="2"
									fixedDecimalScale={true}
									value={this.props.privateInfo.sumaMensual}
									displayType={'text'}
									thousandSeparator=","
									// decimalScale={'2'}
									prefix={''}
									renderText={(value) => (
										<p style={{ color: 'white' }}>
											{value}  
										</p>
									)}
								/>
							</div>
							<div className="MonthlySalary">
								<p
									className="smallText"
									style={{ color: 'white' }}
								>
									{i18next.t(
										'MiExperAM.compensacion.garantizada.totalGarantizada'
									)}{' '}
									{i18next.t('MiExperAM.compensacion.anual')}
								</p>
								<CurrencyFormat
									decimalSeparator="."
									decimalScale="2"
									fixedDecimalScale={true}
									value={this.props.privateInfo.sumaAnual}
									displayType={'text'}
									thousandSeparator=","
									// decimalScale={'2'}
									prefix={''}
									renderText={(value) => (
										<p style={{ color: 'white' }}>
											{value}  
										</p>
									)}
								/>
							</div>
						</div>
					</div>
				) : (
					''
				)}

				{/*

        <table className="table-Compensation guaranted-table">
          <tr>
            {/* <img className="icon rewards" src={navidad} alt="" /> */}
				{/*
            <td>Guaranted</td>
            <td>Monthly</td>
            <td>Annual</td>
          </tr>
          <tr className="special-row">
            <td>Base Salary</td>
            <td>$20,000 </td>
            <td>$230,000 </td>
          </tr>
          <tr>
            <td>Christmas Bonus</td>
            <td>$20,000 </td>
            <td>$230,000 </td>
          </tr>

          <tr>
            <td>Vacation Premium</td>
            <td>$20,000 </td>
            <td>$230,000 </td>
          </tr>

          <tr>
            <td>Saving Fund</td>
            <td>$20,000 </td>
            <td>$230,000 </td>
          </tr>
          <tr>
            <td>Food Coupons</td>
            <td>$20,000 </td>
            <td>$230,000 </td>
          </tr>
          <tr>
            <td>School Supplies</td>
            <td>$20,000 </td>
            <td>$230,000 </td>
          </tr>
          <tr>
            <td>Car Bonus</td>
            <td>$20,000 </td>
            <td>$230,000 </td>
          </tr>
        </table>
        */}
				{/* BONO DESEMPEÑO */}
				{/* {isMockUser() ? ( */}
				{this.validarCorreos(this.state.bonoDesempeno) ? (
					<div className="container-variable">
						<div className="contTitleComs">
							<h1 className="rewards_title VariableCompsTitle">
								{i18next.t(
									'MiExperAM.compensacion.variable.title'
								)}
							</h1>
						</div>
						<div className="TableSalaryBonus">
							{localStorage.getItem('bonoDesempeno') != 0 ? (
								<div
									className="widthConecpSalary concepComp07 grid grid-cols-3 rounded-lg"
									id="bonoDesempeno"
								>
									{/* L6 - L8 = 2 meses */}
									{(localStorage.getItem('nivel') === '6' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '7' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '8' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ? (
										<UncontrolledTooltip
											placement="auto"
											autohide={false}
											target="bonoDesempeno"
											delay={{ show: 0, hide: 0 }}
										>
											{this.bonoDesempenoInfo()}
											{i18next.t(
												'MiExperAM.compensacion.variable.bono.nontier'
											)}
										</UncontrolledTooltip>
									) : (
										''
									)}

									{/* L9 - L12 = porcentaje */}
									{(localStorage.getItem('nivel') === '9' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '10' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '11' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '12' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ? (
										<UncontrolledTooltip
											placement="auto"
											autohide={false}
											target="bonoDesempeno"
											delay={{ show: 0, hide: 0 }}
										>
											{this.bonoDesempenoInfo()}{' '}
											{i18next.t(
												'MiExperAM.compensacion.variable.bono.nontier'
											)}
										</UncontrolledTooltip>
									) : (
										''
									)}

									{/* L6 - L8 = 2 meses */}
									{(localStorage.getItem('nivel') === '6' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '7' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '8' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '9' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '10' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '11' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '12' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ? (
										<UncontrolledTooltip
											placement="auto"
											autohide={false}
											target="bonoDesempeno"
											delay={{ show: 0, hide: 0 }}
										>
											{i18next.t(
												'MiExperAM.compensacion.variable.bono.tier1'
											)}
										</UncontrolledTooltip>
									) : (
										''
									)}

									<div
										className="concepSalary col-span-2 flex items-center justify-start"
										style={{ width: '100%' }}
									>
										<div className="iconSalary">
											<img
												src={comsimg07}
												alt="Desempeno Icon"
											/>
										</div>
										<p>
											{i18next.t(
												'MiExperAM.compensacion.variable.bono.title'
											)}
										</p>
									</div>

									{/* <div className="MonthlySalary"> */}
									{/*<p>200</p>*/}
									{/* </div> */}

									<div
										className="MonthlySalary col-span-1 whitespace-nowrap"
										style={{ width: '100%' }}
									>
										<CurrencyFormat
											decimalSeparator="."
											decimalScale="2"
											fixedDecimalScale={true}
											value={
												this.props.privateInfo
													.bonoDesempeno
											}
											displayType={'text'}
											thousandSeparator=","
											// decimalScale={'2'}
											prefix={''}
											renderText={(value) => (
												<p>{value}  </p>
											)}
										/>
									</div>
								</div>
							) : (
								''
							)}

							{this.props.privateInfo.lti != 0 ||
							isMockLevel(10) ||
							isMockLevel(11) ||
							isMockLevel(12) ||
							isMockSuperUser() ? (
								<div
									className="widthConecpSalary concepComp07"
									id="lti"
								>
									<div className="concepSalary">
										<div className="iconSalary">
											<img src={comsimg07} />
										</div>
										<p className="lti">
											Incentivo a largo plazo
										</p>
									</div>

									<div className="MonthlySalary"></div>

									<div className="MonthlySalary">
										<CurrencyFormat
											decimalSeparator="."
											decimalScale="2"
											fixedDecimalScale={true}
											value={this.props.privateInfo.lti}
											displayType={'text'}
											thousandSeparator=","
											// decimalScale={'2'}
											prefix={''}
											renderText={(value) => (
												<p>{value}  </p>
											)}
										/>
									</div>
								</div>
							) : (
								''
							)}
						</div>

						<div className="TableSalaryBonusSmall">
							{localStorage.getItem('bonoDesempeno') != 0 ? (
								<div id="bonoDesempenoSmall">
									{/* L6 - L8 = 2 meses */}
									{(localStorage.getItem('nivel') === '6' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '7' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '8' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ? (
										<UncontrolledTooltip
											placement="auto"
											autohide={false}
											target="bonoDesempenoSmall"
											delay={{ show: 0, hide: 0 }}
										>
											{this.bonoDesempenoInfo()}
											{i18next.t(
												'MiExperAM.compensacion.variable.bono.nontier'
											)}
										</UncontrolledTooltip>
									) : (
										''
									)}

									{/* L9 - L12 = porcentaje */}
									{(localStorage.getItem('nivel') === '9' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '10' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '11' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '12' &&
										localStorage.getItem('compania') !=
											'Centro de Servicios Compartidos') ? (
										<UncontrolledTooltip
											placement="auto"
											autohide={false}
											target="bonoDesempenoSmall"
											delay={{ show: 0, hide: 0 }}
										>
											{this.bonoDesempenoInfo()}{' '}
											{i18next.t(
												'MiExperAM.compensacion.variable.bono.nontier'
											)}
										</UncontrolledTooltip>
									) : (
										''
									)}

									{/* L6 - L8 = 2 meses */}
									{(localStorage.getItem('nivel') === '6' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '7' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '8' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '9' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '10' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '11' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ||
									(localStorage.getItem('nivel') === '12' &&
										localStorage.getItem('compania') ===
											'Centro de Servicios Compartidos') ? (
										<UncontrolledTooltip
											placement="auto"
											autohide={false}
											target="bonoDesempenoSmall"
											delay={{ show: 0, hide: 0 }}
										>
											{i18next.t(
												'MiExperAM.compensacion.variable.bono.tier1'
											)}
										</UncontrolledTooltip>
									) : (
										''
									)}

									<div className="MonthlySalary">
										<div className="iconSalary">
											<img src={comsimg07} />
										</div>
										<p>
											{i18next.t(
												'MiExperAM.compensacion.variable.bono.title'
											)}
										</p>
										<div className="amount">
											<p>
												-- (
												{i18next.t(
													'MiExperAM.compensacion.mensual'
												)}
												)
											</p>
											<CurrencyFormat
												decimalSeparator="."
												decimalScale="2"
												fixedDecimalScale={true}
												value={
													this.props.privateInfo
														.bonoDesempeno
												}
												displayType={'text'}
												thousandSeparator=","
												// decimalScale={'2'}
												prefix={''}
												renderText={(value) => (
													<p>
														{value}   (
														{i18next.t(
															'MiExperAM.compensacion.anual'
														)}
														)
													</p>
												)}
											/>
										</div>
									</div>
								</div>
							) : (
								''
							)}

							{this.props.privateInfo.lti !== 0 ||
							isMockLevel(10) ||
							isMockLevel(11) ||
							isMockLevel(12) ||
							isMockSuperUser() ? (
								<div id="lti">
									<div className="MonthlySalary">
										<div className="iconSalary">
											<img src={comsimg07} />
										</div>
										<p className="lti">
											Incentivo a largo plazo
										</p>
										<div className="amount">
											<p>
												-- (
												{i18next.t(
													'MiExperAM.compensacion.mensual'
												)}
												)
											</p>
											<CurrencyFormat
												decimalSeparator="."
												decimalScale="2"
												fixedDecimalScale={true}
												value={
													this.props.privateInfo.lti
												}
												displayType={'text'}
												thousandSeparator=","
												// decimalScale={'2'}
												prefix={''}
												renderText={(value) => (
													<p>
														{value}   (
														{i18next.t(
															'MiExperAM.compensacion.anual'
														)}
														)
													</p>
												)}
											/>
										</div>
									</div>
								</div>
							) : (
								''
							)}
						</div>
					</div>
				) : (
					''
				)}

				{/*AQUI*/}

				<div className="SalarioMoney BlueBackground backgroundBlueBlack">
					<div className="widthMovilSalario">
						<div className="concepSalary">
							<p>
								{i18next.t(
									'MiExperAM.compensacion.variable.total'
								)}
							</p>
							<p>
								{i18next.t(
									'MiExperAM.compensacion.variable.mas'
								)}
							</p>
						</div>

						<div className="MonthlySalary">
							{/*<p className="smallText">Mensual</p>*/}
							<CurrencyFormat
								decimalSeparator="."
								decimalScale="2"
								fixedDecimalScale={true}
								value={this.props.privateInfo.sumaMensual}
								displayType={'text'}
								thousandSeparator=","
								// decimalScale={'2'}
								prefix={''}
								renderText={(value) => <p>{value}  </p>}
							/>
							{/*<p>{ localStorage.getItem('sumaMensual') } </p>*/}
						</div>

						<div className="MonthlySalary">
							{/*<p className="smallText">Anual</p>*/}
							<CurrencyFormat
								decimalSeparator="."
								decimalScale="2"
								fixedDecimalScale={true}
								value={this.props.privateInfo.sumaAnualTotal}
								displayType={'text'}
								thousandSeparator=","
								// decimalScale={'2'}
								prefix={''}
								renderText={(value) => <p>{value}  </p>}
							/>
							{/*<p>{ localStorage.getItem('sumaAnualTotal')} </p>*/}
						</div>
					</div>
				</div>

				<div
					className="SalarioMoneySmall"
					style={{ backgroundColor: '#0B2343' }}
				>
					<div className="MonthlySalary">
						<p className="smallText" style={{ color: 'white' }}>
							{i18next.t('MiExperAM.compensacion.variable.total')}{' '}
							({i18next.t('MiExperAM.compensacion.mensual')})
						</p>
						<CurrencyFormat
							decimalSeparator="."
							decimalScale="2"
							fixedDecimalScale={true}
							value={this.props.privateInfo.sumaMensual}
							displayType={'text'}
							thousandSeparator=","
							// decimalScale={'2'}
							prefix={''}
							renderText={(value) => (
								<p style={{ color: 'white' }}>{value}  </p>
							)}
						/>
					</div>
					<div className="MonthlySalary">
						<p className="smallText" style={{ color: 'white' }}>
							{i18next.t('MiExperAM.compensacion.variable.total')}{' '}
							({i18next.t('MiExperAM.compensacion.anual')})
						</p>
						<CurrencyFormat
							decimalSeparator="."
							decimalScale="2"
							fixedDecimalScale={true}
							value={this.props.privateInfo.sumaAnualTotal}
							displayType={'text'}
							thousandSeparator=","
							// decimalScale={'2'}
							prefix={''}
							renderText={(value) => (
								<p style={{ color: 'white' }}>{value}  </p>
							)}
						/>
					</div>
				</div>

				{/* AQUI VA EL DESEMPENO 2021 */}
				{this.state.has_desempeno ? (
					<div className="container-variable">
						<div className="contTitleComs">
							<h1 className="rewards_title VariableCompsTitle">
								Desempeño 2021
							</h1>
						</div>
						<div className="grid grid-cols-3 gap-x-4">
							<div className="col-span-1 flex h-12 items-center justify-center rounded-lg bg-white shadow-lg">
								<p className="w-full text-center text-sm leading-tight text-gray-500">
									{this.validatePerformance(
										this.state.performance
									)}
								</p>
							</div>
							<div className="col-span-2 grid h-12 grid-cols-3 rounded-lg bg-amBlack shadow-lg">
								<p className="col-span-2 flex h-full items-center justify-center border-r border-gray-400 border-opacity-20 text-sm text-white">
									Porcentaje de incremento
								</p>
								<p className="col-span-1 flex h-full items-center justify-center text-sm font-bold text-white">
									{this.state.percentage === 0
										? '-----'
										: `${this.state.percentage.toFixed(
												2
										  )}%`}
								</p>
							</div>
						</div>
					</div>
				) : null}
				{/* DESEMPENO 2021 END */}
				<p className="contactHRBP">
					{i18next.t('MiExperAM.compensacion.hrbp')}
					{/* Recursos Humanos */}
					{!this.state.hasSecondHrbp ? (
						<span>
							<br />
							<span
								className="name"
								style={{ marginRight: '3px' }}
							>
								{this.state.hrbp !== null
									? this.state.hrbp
									: '--------'}
							</span>
							{this.state.hrbp !== null ? (
								<a href={`mailto:${this.state.hrbp_email}`}>
									{this.state.hrbp_email}
								</a>
							) : null}
						</span>
					) : (
						<span>
							<br />
							<span
								className="name"
								style={{ marginRight: '3px' }}
							>
								{this.state.hrbp}
							</span>
							<a href={`mailto:${this.state.hrbp_email}`}>
								{this.state.hrbp_email}
							</a>
							<span
								className="name"
								style={{ marginRight: '3px' }}
							>
								{this.state.secondHrbp}
							</span>
							<a href={`mailto:${this.state.secondHrbp_email}`}>
								{this.state.secondHrbp_email}
							</a>
						</span>
					)}
				</p>
			</div>
		);
	}
}
