import isMockUser from './isMockUser';

export default function isMockNotLevel(level) {
	if (!isMockUser()) return false;

	var email = localStorage.getItem('email');
	switch (level) {
		case 1:
			return email !== 'amuserl1@aeromexico.com';
		case 2:
			return email !== 'amuserl2@aeromexico.com';
		case 3:
			return email !== 'amuserl3@aeromexico.com';
		case 4:
			return email !== 'amuserl4@aeromexico.com';
		case 5:
			return email !== 'amuserl5@aeromexico.com';
		case 6:
			return email !== 'amuserl6@aeromexico.com';
		case 7:
			return email !== 'amuserl7@aeromexico.com';
		case 8:
			return email !== 'amuserl8@aeromexico.com';
		case 9:
			return (
				email !== 'amaccesodelta@aeromexico.com' &&
				email !== 'amaccesoskyteam@aeromexico.com' &&
				email !== 'amskyteamaccess@aeromexico.com' &&
				email !== 'amacessdelta@aeromexico.com' &&
				email !== 'amaccessguest@aeromexico.com' &&
				email !== 'amusercan@aeromexico.com'
			);
		case 10:
			return (
				email !== 'amuserl10@aeromexico.com' &&
				email !== 'amaccesoinvitado@aeromexico.com'
			);
		case 11:
			return email !== 'amuserl11@aeromexico.com';
		case 12:
			return email !== 'amuserl12@aeromexico.com';
		default:
			return true;
	}
}
