import React, { Component,PureComponent } from "react";
import "./css/customerexp.css"
import LogrosCardTodos from "./logrosCardTodos"
import styled from 'styled-components';
import LoadingScreen from "react-loading-screen";
import i18next from "i18next";

const LogrosCxHeader = styled.div`
    width:100%;
    height:auto;
    overflow:hidden;
    background-image: linear-gradient(to right top, #0b2343, #0a2c55, #083668, #043f7c, #004990);
`
const MidLogros = styled.div`
    width:90%;
    max-width:1400px;
    height:auto;
    overflow:hidden;
    margin: 0 auto;
    padding:100px 0;
    .title{
        color:white;
        font-size: 52px;
        font-weight: 500;
        color: white;
        margin: 0;
            margin-top: 0px;
        padding: 0;
        line-height: 1;
        text-align: center;
    }
    .subtitle{
        font-size: 22px;
        font-weight: 400;
        color: white;
        max-width: 800px;
        margin-top: 20px;
        opacity: 1;
        line-height: 1.6;
        text-align:center;
        margin:10px auto 0;
    }
`
const MidLogrosCards = styled.div`
    max-width:1400px;
    margin:0 auto;
    width:90%;
    margin-top:-68px;
    .ArticleCardWrap{
      grid-template-columns: repeat(4, 1fr);
      .ArticleImageWrap{
        p{
        display:none;
        }
      }
    }
`


export default class SliderSwiper extends Component {

  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 1500);
  }
  
  render() {
    const { loading } = this.state;
    return (
      <LoadingScreen
        loading={loading}
        bgColor="#f5f5f5"
        spinnerColor="#007dc3"
        textColor="#0b2343"
        //logoSrc={avion}
        text={i18next.t('News.cargando')}
      >
      <div>
          <LogrosCxHeader>
            <MidLogros>
                <p className="title">{i18next.t('Logroscx.title')}</p>
                <p className="subtitle">{i18next.t('Logroscx.desc')}</p>
            </MidLogros>
          </LogrosCxHeader>
          
          <MidLogrosCards>
            <LogrosCardTodos/>
          </MidLogrosCards>

      </div>
      </LoadingScreen>
    );
  }
}
