import React, { Fragment, useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import '../Slider/css/swiper.css';
import '../Slider/css/style.css';
import arrow from '../../assets/images/slider/arrow-blue.svg';
import i18next from 'i18next';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';


const params = {
	slidesPerView: 1,
	spaceBetween: 0,
	grabCursor: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: true
	},
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
		dynamicBullets: true
	},
	mousewheel: false,
	freeMode: false,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
	}
};

function Swiper2() {
	const [entries, setEntries] = useState([]);
	const [loading, setLoading] = useState(true);
	const country = localStorage.getItem('pais');
	const isSpanish = i18next.language === 'es';

	useEffect(() => {
		async function fetchData() {
			try {
				const cabecera = new Headers({
					'Content-type': 'application/json',
					'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_LOCAL}`
				});
				const response = await fetch(`${process.env.REACT_APP_LOCAL}home-slides`, { headers: cabecera });
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const data = await response.json();
				if (data.data.length > 0) {
					setEntries(data.data);
					setLoading(false);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		}
	
		fetchData();
	
	}, []);

	return (
		<Fragment>
			{!loading && (
				<Swiper {...params} className="swiper-home-slider">
					{entries.map(slide => {
						if (
							slide.attributes.available_for === "Everyone" ||
							slide.attributes.available_for === country
						) {
							
							return (
								<div key={slide.id} className="hellow">
									<div className="height">
										<div className="Swiperfilter"></div>
										<div className="BackFilterBlur"></div>
										<div className="VideoFilter">
											{slide.attributes.video_url ? (
												<video
													className="video"
													autoPlay
													muted
													loop
													playsInline
												>
													<source
														src={encodeURI(slide.attributes.video_url)}
													/>
												</video>
											) : null}
										</div>
										{!slide.attributes.video_url && slide.attributes.Img && (
											<>
												<img
													className="img-slider SwiperImage noMobile"
													src={encodeURI(slide.attributes.Img.url)}
													alt={slide.attributes.Img.name.replace(
														slide.attributes.Img.ext,
														''
													)}
												/>
												<img
													className="img-slider SwiperImage noDesktop"
													src={encodeURI(slide.attributes.Img.url)}
													alt={slide.attributes.Img.name.replace(
														slide.attributes.Img.ext,
														''
													)}
												></img>
											</>
										)}
										<div className="SwipeCenter">
											<div className="SwipeCenterCell">
												<p className="TagSwipe">
													{isSpanish
														? slide.attributes.company
														: slide.attributes.Localization
																.company}
												</p>
												<div className="WrapperTextSwipe">
													<p className="TitleSwipe">
														{isSpanish
															? slide.attributes.title
															: slide.attributes
																	.Localization
																	.title}
													</p>
													<ReactMarkdown className="IntroSwipe">
														{
															isSpanish
															? slide.attributes.subtitle
															: slide.attributes
																	.Localization
																	.subtitle
														}
													</ReactMarkdown>
													{slide.attributes.url.includes(
														'https://miaeromexico.com/'
													) ? (
														<Link
															to={slide.attributes.url.replace(
																'https://miaeromexico.com/',
																'/'
															)}
															className="slider-read flex items-center justify-center"
															style={{
																display: 'flex'
															}}
														>
															{isSpanish? 'Conoce más':'know more'}
															<img
																className="blue-arrow"
																src={arrow}
																alt="blue-arrow"
															></img>
														</Link>
													) : (
														<a
															className="slider-read flex items-center justify-center"
															href={encodeURI(slide.attributes.url)}
															style={{
																display: 'flex'
															}}
														>
															{isSpanish ? 'Conoce más' : 'Know more'}
															<img
																className="blue-arrow"
																src={arrow}
																alt="blue-arrow"
															></img>
														</a>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						} else {
							return null;
						}
					})}
				</Swiper>
			)}
		</Fragment>
	);
}

export default Swiper2;
