import React, { Component } from "react";
import "./css/identidad.css"
import i18next from "i18next";
import Header from '../../components/Header';
import Index from '../../components/Footer/index';

export default class alfabetoWrapperPage extends Component {
  render() {
    return (
      <div id="alfabetoIdentidad">
        <Header/>
        <div class="nosotrosWrapper">
          <div class="nosotrosHeader">
            <h4>{i18next.t('MainMenu.nosotros.otros.alfabeto')}</h4>
            {/* <p>Con el objetivo de mantener la calidad y congruencia en la imagen de Grupo Aeroméxico, hemos organizado un conjunto de elementos de utilidad para las actividades diarias de nuestros colaboradores. </p> */}
          </div>
        </div>

        <div className="wrapperDownload">
            <ul className="alphaWrapperMid">
                    <li>
                        <p className="spanAlphabet">A</p>
                        <p className="text">Alfa</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">B</p>
                        <p className="text">Bravo</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">C</p>
                        <p className="text">Coca</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">D</p>
                        <p className="text">Delta</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">E</p>
                        <p className="text">Eco</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">F</p>
                        <p className="text">Fox</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">G</p>
                        <p className="text">Golfo</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">H</p>
                        <p className="text">Hotel</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">I</p>
                        <p className="text">India</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">J</p>
                        <p className="text">Julieta</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">K</p>
                        <p className="text">Kilo</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">L</p>
                        <p className="text">Lima</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">M</p>
                        <p className="text">Metro</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">N</p>
                        <p className="text">Néctar</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">O</p>
                        <p className="text">Oscar</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">P</p>
                        <p className="text">Papa</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">Q</p>
                        <p className="text">Québec</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">R</p>
                        <p className="text">Romeo</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">S</p>
                        <p className="text">Sierra</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">T</p>
                        <p className="text">Tango</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">U</p>
                        <p className="text">Unión</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">V</p>
                        <p className="text">Victor</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">W</p>
                        <p className="text">Whiskey</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">E</p>
                        <p className="text">Extra</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">Y</p>
                        <p className="text">Yanki</p>
                    </li>
                    <li>
                        <p className="spanAlphabet">Z</p>
                        <p className="text">Zulu</p>
                    </li>


            </ul>
        </div>
    <Index/>
     </div>

      
    );
  }
}
