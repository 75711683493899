import React, { Fragment, useState } from 'react';
//import ClasificadoHome from './Clasificado-Home';
import i18next from 'i18next';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import 'rc-collapse/assets/index.css';
import './style.css';
import AMPlane from './amplane.png';
import Sky from './assets/nubes.png';
import AirPlane from './assets/avionfrente.png';
import Arriba  from '../../components/Header/Arriba';
import Index from '../../components/Footer/index';

const ClasificadoHome= () => {
	const [isPlaying, setIsPlaying] = useState(false);
	const language = i18next.language;

	return (
		<Fragment>
			<Arriba/>
			<motion.section
				animate={{
					height: 'auto'
				}}
				className="container-fluid classified-header p-0"
			>
				<article
					className="flex w-full flex-col items-center justify-center py-24"
					style={{
						background: `url(${Sky})`,
						backgroundPosition: 'bottom',
						backgroundRepeat: 'repeat-x',
						backgroundSize: 'cover',
						minHeight: '500px'
					}}
				>
					<div className="w-full px-10 md:w-8/12 lg:w-6/12">
						<img
							className="h-auto w-full"
							src={AirPlane}
							alt="Avión"
						/>
					</div>
					<div className="mt-10 w-full px-10 md:w-10/12 lg:w-8/12">
						<p
							className="px-10 text-center font-bold leading-tight text-amDarkBlue"
							style={{ fontSize: '24px' }}
						>
							Hoy concluyó exitosamente nuestro proceso voluntario
							de reestructura financiera bajo el Capítulo 11.
						</p>
					</div>
					<div className="mt-8 w-full px-10 md:w-11/12 lg:w-9/12">
						<p
							className="px-10 text-center leading-tight text-amDarkBlue"
							style={{ fontSize: '20px' }}
						>
							Gracias al esfuerzo de todos y cada uno de ustedes
							hemos salido fortalecidos de este proceso.
						</p>
					</div>
					<div
						className="my-10 flex w-full items-center justify-center py-8"
						style={{
							background: `#DDE8F6`
						}}
					>
						<div className="container mx-auto flex items-center justify-center px-10">
							<p className="w-full text-center text-lg text-amDarkBlue md:w-8/12 lg:w-6/12">
								Da{' '}
								<span
									onClick={() => setIsPlaying(!isPlaying)}
									className="cursor-pointer font-bold text-amBlue"
								>
									clic aquí
								</span>{' '}
								para ver el mensaje de{' '}
								<strong>Andrés Conesa</strong>, Director General
								de Grupo Aeroméxico
							</p>
						</div>
					</div>
					<div className="mb-10 flex w-full items-center justify-center px-10 md:w-10/12 lg:w-8/12">
						<div
							className="video-container"
							style={{
								position: 'relative',
								paddingTop: '56.25%',
								width: '100%'
							}}
						>
							<ReactPlayer
								style={{
									position: 'absolute',
									top: 0,
									left: '50%',
									transform: 'translateX(-50%)',
									zIndex: 10
								}}
								//url="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/capitulo-11/MENSAJE_ANDRES_CONESA_CAPITULO11.mp4"
								url={`${process.env.REACT_APP_SRC_URL}miam_docs/capitulo-11/MENSAJE_ANDRES_CONESA_CAPITULO11.mp4`}
								controls={true}
								//volume={100}
								playing={isPlaying}
								width="100%"
								height="100%"
							/>
						</div>
					</div>
				</article>
			</motion.section>
			<Index/>
		</Fragment>
	);
};


export default ClasificadoHome;
