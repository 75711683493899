import React, { Component } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Login from "../../pages/Login";
import ReactGA from "react-ga";
import Welcome from "../../pages/Home/Welcome";
import GolfoAirlines from "../../components/DeltaEmirates/amgolfo";
import GolfoAirlinesEN from "../../components/DeltaEmirates/amgolgoen";
import NewsCategory from "../../components/News/NewsCategory";
import NewsHome from "../../components/News/noticiaslanding";
import SingleNews from "../../components/News/SingleNews";
import SingleNewsSlider from "../../components/News/SingleNewsSlider";
import GamTalks from "../GamTalks/gamtalks";
import SolicitarNota from "../Aeronews/formsolicitar";
import InfoEnvironment from "../../utils/info.environment";
import DesempenoDesarrollo from "../Talento/talento";
import FlotaPage from "../Nosotros/flota/flota";
import ProfileRewards from "../../pages/Profile/ProfileRewards"; // NEW PROFILE REWARDS
import Triques from "../TriquesTrueques/triquestrueques";
import Search from "../../pages/Search";
import Notfound from "../../pages/404/notfound";
import TotalBenefits from "../../components/Profile/TotalBenefits";
import TotalBenefitsTrans from "../../components/Profile/TotalBenefitsTrans";
import SingleBenefitUSA from "../../components/Profile/Benefits/SingleBenefitUSA";
import ViajesServicio from "../ViajesServicio/viajesservicio";
import DiscountViewSalud from "../../components/Profile/Discounts/DescuentosSalud";
import DiscountViewEntre from "../../components/Profile/Discounts/DescuentosEntre";
import DiscountViewGym from "../../components/Profile/Discounts/DescuentosGym";
import DiscountViewCompras from "../../components/Profile/Discounts/DescuentosCompras";
import DiscountViewEduca from "../../components/Profile/Discounts/DescuentosEduca";
import DiscountViewResta from "../../components/Profile/Discounts/DescuentosResta";
import DiscountViewCar from "../../components/Profile/Discounts/DescuentosCar";
import DiscountViewSer from "../../components/Profile/Discounts/DescuentosSer";
import DiscountViewGuar from "../../components/Profile/Discounts/DescuentosGuar";
import Finanzas from "../../components/Profile/Finanzas/finanzas";
import Experience from "../../components/Profile/Experience/experience";
import LovedBenefits from "../Profile/LovedBenefits/lovedBenefits";
import ComiteEjecutivo from "../../components/ComiteEjecutivo/comiteEjecutivo";
import CustomerExperience from "../../components/CustomerExperience/customerexperience";
import Nosotros from "../../components/Nosotros/transformacion";
import Responsabilidad from "../../components/Nosotros/responsabilidad";
import Vacantes from "../Profile/vacantes";
import CXevolution from "../../components/CXevolution/cxevolution";
import Seguridad from "../../components/seguridad/seguridad";
import NOM035 from "../../pages/NOM035/Nom035";
import Flightplan from "../flightplan/fligthplan";
import Flightplanpor from "../flightplan/fligthplanpor";
import Flightplanen from "../flightplan/fligthplanen";
import Flightplandut from "../flightplan/flightplandut";
import Flightplanjap from "../flightplan/flightplanjap";
import Flightplanfr from "../flightplan/flightplanfr";
import Flightplanchino from "../flightplan/flightplanchino";
import Flightplancor from "../flightplan/flightplancor";
import Logrocx from "../CustomerExperience/logroscx";
import Identidad from "../Nosotros/identidad";
import Alfabeto from "../Nosotros/alfabeto";
import Reportesostenibilidad from "../Nosotros/reportesostenibilidad";
import Reportetrimestral from "../Nosotros/reportetrimestral";
import Reporteoperacional from "../Nosotros/reporteoperacional";
import Reporteanual from "../Nosotros/reporteanual";
import Boletin from "../Nosotros/boletin";
import Jcaaeromexicodelta from "../Nosotros/jcaaeromexicodelta";
import ErrorMessage from "../../ErrorMessage";
import ApplyForm from "../../components/Profile/Apply/Form";
import config from "../../Config";
import { UserAgentApplication } from "msal";
import Maps from "../../pages/Geolocation/Mapa";
import Map2 from "../../components/Geolocation/Map/Map2";
import ReporteSostenibilidad from "../../components/Geolocation/Map/framer";
import userLocation from "../../utils/userLocation";
import isMockUser from "../../utils/isMockUser";
import setLevelMockUser from "../../utils/setLevelMockUser";
import setMockCompany from "../../utils/setMockCompany";
import CompliancePage from "../../pages/Compliance/CompliancePage";
import EmailSearch from "../../pages/Hidden/EmailSearch";
import { ComunicadosPage } from "../../pages/Comunicados/ComunicadosPage";
import Clasificado from "../../pages/Clasificado/Clasificado";
import { createBrowserHistory } from "history";
import Juntos from "../../pages/Juntos/Juntos";
import DesdeCasa from "../../pages/DesdeCasa/DesdePage";
import FuturoPage from "../../pages/Futuro/FuturoPage";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import rootReducer from "../../redux/rootReducer";
import Max737 from "../../pages/737Max";
import Teletrabajo from "../../pages/Teletrabajo";
import { validateMenu } from "../../utils/NewBenefitsValidation/benefits.validation";
import TalentPlanningPage from "../../pages/TalentPlanning";
import MyLearningPage from "../MyLearning";
import MiLineaAtencion from "../../pages/LineaAtencion";
import PageProcesosAdministrativos from "../../pages/ProcesosAdministrativos";
import BenefitsOpenMX from "../Profile/Benefits/BenefitsOpenMX";
import BazarNavidad from "../BazarNavidad";
import MyHR from "../TriquesTrueques/triquestrueques";
import Triques2 from "../TriquesTrueques/triquestrueques2";
import IndexHeader from "../Header";
import ReloadOnUrlChange from "../News/ReloadOnUrlChange";
import { Switch } from "react-router-dom";

const middleware = [logger, thunk];

const store = createStore(rootReducer, load(), composeWithDevTools(applyMiddleware(...middleware, save())));

const sha1 = require("sha1");
ReactGA.initialize("UA-138315711-1");
ReactGA.event({
	category: "Log In",
	action: "User logged into the site"
});

const history = createBrowserHistory();

history.listen((location) => {
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

ReactGA.set();

ReactGA.pageview("/login");
ReactGA.pageview("/inicio");
ReactGA.pageview("/nosotros");
ReactGA.modalview("/inicio");
ReactGA.modalview("/recompensa-total/perfil/venta-especial");
ReactGA.pageview("/recompensa-total/perfil/venta-especial");
ReactGA.pageview("/defendamos-nuestro-cielo");
ReactGA.pageview("/articles/", ["tracker2"]);
ReactGA.pageview("/nosotros/plan-de-vuelo");
ReactGA.pageview("/nosotros/responsabilidad-social");
ReactGA.pageview("/nosotros/jca-aeromexico-delta");
ReactGA.pageview("/nosotros/gam-talks");
ReactGA.pageview("/seguridad");
ReactGA.pageview("/oportunidades-carrera");
ReactGA.pageview("/recompensa-total/my-hr");
ReactGA.pageview("/recompensa-total/viajes-aeromexico");
ReactGA.pageview("/experiencia-cliente");
ReactGA.pageview("/experiencia-cliente/logros");
ReactGA.pageview("/nosotros/responsabilidad-social/informe-sostenibilidad");
ReactGA.pageview("recompensa-total/mi-desempeno-desarrollo");

ReactGA.pageview("/articles/5I59C1oQqeZRjBANfYJCYP");

ReactGA.pageview("/experiencia-cliente/cx-evolution");
ReactGA.pageview("/buy-and-sell");

ReactGA.pageview("/recompensa-total/perfil/mi-compensaciones");
ReactGA.pageview("/recompensa-total/perfil/my-travel-benefits");
ReactGA.pageview("/recompensa-total/perfil/mis-beneficios");
ReactGA.pageview("/recompensa-total/perfil");
ReactGA.pageview("/seguridad#gamreport");

ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/salud-y-belleza");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/entretenimiento");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/gimnasios");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/de-compras");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/educacion");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/restaurantes");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/automoviles");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/servicios");
ReactGA.pageview("/recompensa-total/mis-beneficios/descuentos/guarderias");

/* SET USER ID TO ANALYTICS */
if (localStorage.getItem("email") !== null) {
	ReactGA.set({ userId: localStorage.getItem("email") });
	ReactGA.set({ dimension1: localStorage.getItem("email") });
}

if (
	localStorage.getItem("nivel") !== null &&
	localStorage.getItem("nivel") !== "" &&
	localStorage.getItem("nivel") !== ""
) {
	ReactGA.set({ dimension2: "NSD" });
} else {
	ReactGA.set({ dimension2: "SND" });
}

// OKTA CLIENT ID: 0oasu4e8ivTw8wA0I0h7
// OKTA CLIENT SECRET: YnpN5xP5w4nd_IWX2Q5quDzo6WlxzmaQzZqyrUC6

const PRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			localStorage.getItem("token") !== null ? (
				<Component {...props} />
			) : (
				<Navigate
					to={`/login?redirectTo=${window.encodeURIComponent(
						props.location.pathname + props.location.search
					)}`}
				/>
			)
		}
	/>
);

const url = new URL(window.location.href);
const pathElements = url.pathname.split("/");
const elementoSeguridad = pathElements[pathElements.length - 1];

export default class App extends Component {
	/**OFFICE */
	constructor(props) {
		super(props);
		this.infoEnvironment = new InfoEnvironment();
		this.userAgentApplication = new UserAgentApplication(config.appId, null, null);
		var user = this.userAgentApplication.getUser();
		this.state = {
			isAuthenticated: user !== null, // Se quita el signo ! para entrar por pruebas y se pone para activar el redireccionamiento
			user: {},
			error: null,
			charging: true,
			errorApi: false
		};

		if (user) {
			// Enhance user object with data from Graph
			// this.getUserProfile(); REVISARALEX
		}
	}

	async login() {
		try {
			await this.userAgentApplication.loginPopup(config.scopes); //Validar si esta gate-one para probar la auth
			// await this.getUserProfile();  REVISARALEX
		} catch (err) {
			var errParts = err.split("|");
			this.setState({
				isAuthenticated: false,
				user: {},
				error: { message: errParts[1], debug: errParts[0] }
			});
		}
	}

	logout() {
		this.userAgentApplication.logout();
	}
	componentDidMount() {
		this.getInfo();
	}

	setErrorMessage(message, debug) {
		this.setState({
			error: { message: message, debug: debug }
		});
	}

	setUserLocation() {
		var location = userLocation(
			localStorage.getItem("email"),
			localStorage.getItem("ubicacion"),
			localStorage.getItem("compania")
		);

		localStorage.setItem("location", location);
	}

	getInfo = async () => {
		if (typeof Buffer !== "undefined") {
			if (localStorage.getItem("email") !== null && localStorage.getItem("nombre") !== null) {
				const HOST = "NOMBREHOST";

				const token = sha1(`${HOST}|${localStorage.getItem("email")}`).toUpperCase();
				const email = new Buffer(localStorage.getItem("email")).toString("hex").toUpperCase();
				const URL = this.infoEnvironment.api_url({
					host: window.location.hostname
				});

				let formData = new FormData();

				formData.append("token", token);
				formData.append("email", email);
				formData.append("tipo", "1");
				formData.append("tipo_salida", "JSON");

				fetch(URL, {
					body: formData,
					method: "post"
				})
					.then((data) => data.json())
					.then((data) => {
						if (!!data.subordinados) {
							localStorage.setItem("subordinados", data.subordinados);
						}
						localStorage.setItem("nombreHRBP", data.nombrehrbp);
						localStorage.setItem("emailHRBP", data.emailhrbp);
						localStorage.setItem("nombre", data.nombre);
						localStorage.setItem("grupo", data.grupo);
						localStorage.setItem("pais", data.country);
						localStorage.setItem("codigo_empresa_AM", data.codigo_empresa);
						localStorage.setItem("tipo_empleado_AM", data.tipo_empleado);
						localStorage.setItem("no_empleado_AM", data.noEmpleado);

						this.setState({
							error: false,
							charging: false
						});

						if (data.noEmpleado !== "") {
							localStorage.setItem("noEmpleado", data.noEmpleado);
							localStorage.setItem("compania", data.compania);
							localStorage.setItem("nombre", data.nombre);
							localStorage.setItem("pais", data.country);
							localStorage.setItem("apellidoMaterno", data.apellidoMaterno);
							localStorage.setItem("apellidoPaterno", data.apellidoPaterno);
							localStorage.setItem("grupo", data.grupo);
							localStorage.setItem("nivel", data.nivel);
							localStorage.setItem("ubicacion", data.ubicacion);
							localStorage.setItem("posicion", data.posicion);
							localStorage.setItem("direccion", data.direccion);
							localStorage.setItem("direccionArea", data.direccionArea);
							localStorage.setItem("esquemaColaborador", data.esquemacolaborador);
							localStorage.setItem("colaboradores", JSON.stringify(data.COLABORADORES));
							localStorage.setItem("fechaContra", data.fechaContra);
							localStorage.setItem("antiguedadEmpleado", data.antiguedadEmpleado);
						} else {
							if (isMockUser()) {
								localStorage.setItem("noEmpleado", "-");
								setMockCompany();
								localStorage.setItem("nombre", "Usuario");
								localStorage.setItem("pais", "MX");
								localStorage.setItem("apellidoMaterno", "-");
								localStorage.setItem("apellidoPaterno", "-");
								localStorage.setItem("grupo", "XXXX");
								setLevelMockUser();
								localStorage.setItem("ubicacion", "-");
								localStorage.setItem("posicion", "");
								localStorage.setItem("direccion", "-");
								localStorage.setItem("direccionArea", "-");
								localStorage.setItem("fechaContra", "-");
								localStorage.setItem("antiguedadEmpleado", "-");
							} else {
								localStorage.setItem("noEmpleado", "-");
								localStorage.setItem("compania", "-");
								localStorage.setItem("nombre", "-");
								localStorage.setItem("pais", "MX");
								localStorage.setItem("apellidoMaterno", "-");
								localStorage.setItem("apellidoPaterno", "-");
								localStorage.setItem("grupo", "un grupo");
								localStorage.setItem("nivel", 0);
								localStorage.setItem("ubicacion", "-");
								localStorage.setItem("posicion", "");
								localStorage.setItem("direccion", "-");
								localStorage.setItem("direccionArea", "-");
								localStorage.setItem("fechaContra", "-");
								localStorage.setItem("antiguedadEmpleado", "-");
							}
						}
					})
					.catch((error) => {
						this.setState({
							errorApi: error
						});
					});

				this.setUserLocation();
			}
		}
	};

	render() {
		let error = null;
		if (this.state.error) {
			error = <ErrorMessage message={this.state.error.message} debug={this.state.error.debug} />;
		}

		return (
			<Provider store={store}>
				
				<Routes>
					{error}
					<Route history={history} exact path="/login" element={<Login />} />
					<Route history={history} exact path="/" element={<RedirectLogin />} />
					<Route
						history={history}
						exact
						path="/inicio"
						element={
							<>
								<div className="Layout">
									{/* <Arriba/> */}
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <Welcome /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					{/* <Route history={history} exact path="/inicio" element={ this.state.isAuthenticated ?<Welcome />:<Navigate to="/login"/>} /> */}

					<Route
						history={history}
						exac
						path="/bazar-navideno"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <BazarNavidad /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exac
						path="/creandoelfuturo"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <FuturoPage /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>

					<Route history={history} exact path="/solicitar-nota-aeronews" element={<SolicitarNota />} />

					{/* <Route
						history={history}
						element={
							<Layout>
								<Routes> */}
					<Route
						history={history}
						exact
						path="/defendamos-nuestro-cielo"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <GolfoAirlines /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/defendamos-nuestro-cielo"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <GolfoAirlines /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/lets-defend-our-sky"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <GolfoAirlinesEN /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <GolfoAirlinesEN /> : <RedirectLogin />}</main>
									<Welcome />
								</div>
							</>
						}
					/>

					{/* <Route history={history} exact path="/print" element={<Print />} /> */}
					<Route
						history={history}
						exact
						path="/nosotros/gam-talks"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <GamTalks /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total"
						element={(props) => <ProfileRewards activeTab="rewards" {...props} />}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/perfil"
						element={(props) => <ProfileRewards {...props} activeTab="rewards" />}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/perfil/mi-compensaciones"
						element={(props) => (
							<>
								{validateMenu() ? (
									<ProfileRewards {...props} activeTab="rewards" />
								) : (
									{
										/* <Navigate to="/" /> */
									}
								)}
							</>
						)}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/perfil/my-flexibility"
						element={(props) => <ProfileRewards {...props} activeTab="flexibility" />}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/perfil/my-travel-benefits"
						element={(props) => <ProfileRewards {...props} activeTab="travel" />}
					/>
					<Route
						history={history}
						exact
						path="/compliance"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <CompliancePage /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>

					<Route
						history={history}
						exact
						path="/comunicados"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <ComunicadosPage /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/juntoscontinuaremosvolando"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <Juntos /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/nosotros/seguimos-y-seguiremos-volando"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <Clasificado /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/juntos-desde-casa"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <DesdeCasa /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/hidden/emailSearch"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <EmailSearch /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/perfil/venta-especial"
						element={(props) => <ProfileRewards {...props} activeTab="ventaespecial" />}
					/>
					<Route history={history} exact path="/ayuda-teletrabajo" element={(props) => <Teletrabajo />} />
					<Route
						history={history}
						exact
						path="/recompensa-total/perfil/mis-beneficios"
						element={(props) => <ProfileRewards {...props} activeTab="benefits" />}
					/>
					<Route
						history={history}
						exact
						path="/mis-beneficios"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <BenefitsOpenMX /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>

					<Route
						history={history}
						exact
						path="/benefits-usa/:articleId"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <SingleBenefitUSA /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/my-hr"
						component={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <MyHR /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					{/* <Navigate to="/inicio" />
					</Route> */}

					<Route
						history={history}
						exact
						path="/recompensa-total/talent-planning"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										{this.state.isAuthenticated ? <TalentPlanningPage /> : <RedirectLogin />}
									</main>
								</div>
							</>
						}
					/>

					<Route
						history={history}
						exact
						path="/oportunidades-carrera"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <Vacantes /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/mi-desempeno-desarrollo"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										{this.state.isAuthenticated ? <DesempenoDesarrollo /> : <RedirectLogin />}
									</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/my-learning"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <MyLearningPage /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>

					<Route
						history={history}
						exact
						path="/oportunidades-carrera/aplicar-vacante/:jobId"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <ApplyForm /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route history={history} exact path="/maps" element={<Maps />} />
					<Route
						history={history}
						exact
						path="/nosotros/nuestras-rutas"
						// component={Map2}
					/>
					{/* <Navigate to="/inicio" />
					</Route> */}
					<Route
						history={history}
						exact
						path="/nosotros/responsabilidad-social/informe-sostenibilidad"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										{this.state.isAuthenticated ? <ReporteSostenibilidad /> : <RedirectLogin />}
									</main>
								</div>
							</>
						}
					/>

					<Route history={history} exact path="/map2" element={<Map2 />} />
					<Route
						history={history}
						exact
						path="/recompensa-total/perfil/mis-beneficios/disfruta-tu-trabajo"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <TotalBenefits /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>

					<Route
						history={history}
						exact
						path="/recompensa-total/perfil/mis-beneficios/disfruta-tu-trabajo/transportacion"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										{this.state.isAuthenticated ? <TotalBenefitsTrans /> : <RedirectLogin />}
									</main>
								</div>
							</>
						}
					/>

					<Route
						history={history}
						exact
						path="/recompensa-total/perfil/mis-beneficios/administra-tus-finanzas"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <Finanzas /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/perfil/mis-beneficios/vive-aeromexico"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <Experience /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/perfil/mis-beneficios/cuida-de-ti"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <LovedBenefits /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/mis-beneficios/descuentos/salud-y-belleza"
						element={<DiscountViewSalud />}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/mis-beneficios/descuentos"
						element={<allDiscount />}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/mis-beneficios/descuentos/entretenimiento"
						element={<DiscountViewEntre />}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/mis-beneficios/descuentos/gimnasios"
						element={<DiscountViewGym />}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/mis-beneficios/descuentos/de-compras"
						element={<DiscountViewCompras />}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/mis-beneficios/descuentos/educacion"
						element={<DiscountViewEduca />}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/mis-beneficios/descuentos/restaurantes"
						element={<DiscountViewResta />}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/mis-beneficios/descuentos/automoviles"
						element={<DiscountViewCar />}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/mis-beneficios/descuentos/servicios"
						element={<DiscountViewSer />}
					/>
					<Route
						history={history}
						exact
						path="/recompensa-total/mis-beneficios/descuentos/guarderias"
						element={<DiscountViewGuar />}
					/>

					<Route
						history={history}
						exact
						path="/recompensa-total/viajes-aeromexico"
						element={<ViajesServicio />}
					/>

					<Route
						history={history}
						exact
						path="/buy-and-sell"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										<Triques2 />
									</main>
								</div>
							</>
						}
					/>

					<Route
						history={history}
						exact
						path="/noticias"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										<NewsHome />
									</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/noticias/ultimas-noticias"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										<NewsHome />
									</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						path="/noticias/:newsId"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										<ReloadOnUrlChange />
										<NewsCategory newsId={elementoSeguridad} />
									</main>
								</div>
							</>
						}
					/>

					<Route
						history={history}
						exact
						path="/articles/:articleId"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										<SingleNews />
									</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						path="/articles/slider/:articleSliderId"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										<SingleNewsSlider />
									</main>
								</div>
							</>
						}
					/>
					<Route history={history} exact path="/buscar" element={<Search />} />

					<Route
						history={history}
						exact
						path="/seguridad"
						element={
							<>
								<div className="Layout">
								<IndexHeader />
									<main>
										<Seguridad />
									</main>
								</div>
							</>
						}
					/>
					<Route history={history} exact path="/seguridad#principios-de-seguridad" element={<Seguridad />} />
					<Route
						history={history}
						exact
						path="/seguridad/nom-035"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										<NOM035 />
									</main>
								</div>
							</>
						}
					/>
					<Route history={history} exact path="/mi-linea-atencion" element={<MiLineaAtencion />} />
					<Route
						history={history}
						exact
						path="/servicios-personal"
						element={<PageProcesosAdministrativos />}
					/>
					<Route history={history} exact path="/boeing-737-max" element={<Max737 />} />

					<Route history={history} exact path="/nosotros/sobre-nosotros" element={<Nosotros />} />
					<Route history={history} exact path="/nosotros/nuestra-flota" element={<FlotaPage />} />
					<Route
						history={history}
						exact
						path="/nosotros"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>{this.state.isAuthenticated ? <Nosotros /> : <RedirectLogin />}</main>
								</div>
							</>
						}
					/>
					<Route history={history} exact path="/nosotros#nuestros-aeropuertos" element={<Nosotros />} />

					<Route
						history={history}
						exact
						path="/nosotros/responsabilidad-social"
						element={<Responsabilidad />}
					/>
					<Route
						history={history}
						exact
						path="/nosotros/nuestra-organizacion"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										<ComiteEjecutivo />
									</main>
								</div>
							</>
						}
					/>
					<Route
						history={history}
						exact
						path="/nosotros/logos-templates"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										<Identidad />
									</main>
								</div>
							</>
						}
					/>
					<Route history={history} exact path="/nosotros/alfabeto" element={<Alfabeto />} />
					<Route
						history={history}
						exact
						path="/nosotros/reporte-sustentabilidad"
						element={<Reportesostenibilidad />}
					/>
					<Route
						history={history}
						exact
						path="/nosotros/reporte-operacional"
						element={<Reporteoperacional />}
					/>
					<Route history={history} exact path="/nosotros/reporte-anual" element={<Reporteanual />} />
					<Route
						history={history}
						exact
						path="/nosotros/reporte-trimestral"
						element={<Reportetrimestral />}
					/>

					<Route history={history} exact path="/nosotros/boletin-industria" element={<Boletin />} />

					<Route
						history={history}
						exact
						path="/nosotros/jca-aeromexico-delta"
						element={<Jcaaeromexicodelta />}
					/>

					<Route history={history} exact path="/nosotros/plan-de-vuelo" element={<Flightplan />} />
					<Route history={history} exact path="/nosotros/plan-de-vuelo/ingles" element={<Flightplanen />} />
					<Route
						history={history}
						exact
						path="/nosotros/plan-de-vuelo/portugues"
						element={<Flightplanpor />}
					/>
					<Route
						history={history}
						exact
						path="/nosotros/plan-de-vuelo/holandes"
						element={<Flightplandut />}
					/>
					<Route history={history} exact path="/nosotros/plan-de-vuelo/japones" element={<Flightplanjap />} />
					<Route history={history} exact path="/nosotros/plan-de-vuelo/frances" element={<Flightplanfr />} />
					<Route history={history} exact path="/nosotros/plan-de-vuelo/chino" element={<Flightplanchino />} />
					<Route history={history} exact path="/nosotros/plan-de-vuelo/coreano" element={<Flightplancor />} />

					<Route
						history={history}
						exact
						path="/experiencia-cliente"
						element={
							<>
								<div className="Layout">
									<IndexHeader />
									<main>
										<CustomerExperience />
									</main>
								</div>
							</>
						}
					/>

					<Route history={history} exact path="/experiencia-cliente/logros" element={<Logrocx />} />
					<Route
						history={history}
						exact
						path="/experiencia-cliente/net-promoter-score"
						element={<CustomerExperience />}
					/>
					<Route history={history} exact path="/experiencia-cliente/cx-evolution" element={<CXevolution />} />
					<Route
						history={history}
						exact
						path="/reciclandoando"
						element={() => {
							const getMobileOS = () => {
								const userAgent = navigator.userAgent || navigator.vendor || window.opera;
								if (/android/i.test(userAgent)) {
									return true;
								}
								if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
									return true;
								}
								return false;
							};
							if (!getMobileOS()) {
								return (
									<iframe
										className="reciclando-iframe"
										title="Reciclando Ando"
										src="https://pixpik.org/aeromexico/"
										// width="100%"
										// height="700"
										frameborder="0"
										style={{
											height: "700px !important"
										}}></iframe>
								);
							} else {
								return (window.location.href = "https://pixpik.org/aeromexico/");
							}
						}}
					/>
					<Route history={history} element={<Notfound />} />
					{/* 	</Routes>
							</Layout>
						}
					/> */}
				</Routes>
			</Provider>
		);
	}
}
const RedirectLogin = () => <Navigate to="/login" />;
