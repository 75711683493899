import React, { Component } from "react";
import Aside from "../Profile/AsideProfile";
import './css/triques.css'
import TriquesAutos from "./triques-autos";
import coche from "../../assets/triques/carro.png";
import imac from "../../assets/triques/imac.png";
import estufa from "../../assets/triques/estufa.png";
import playera from "../../assets/triques/playera.png";
import box from "../../assets/triques/box.png";
import triqueslogo from "../../assets/triques/triques.svg";
import CookieConsent from "react-cookie-consent";
import i18next from "i18next";

import { Trans } from "react-i18next";
import Arriba from "../Header/Arriba";
import Index from '../Footer/index';

export default class MyHR extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			tabActive: "todos"
		};
	}

	componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1500);
	}

	tabSelector = (evt) => {
		this.setState({
			tabActive: evt.currentTarget.attributes.name.textContent
		});
	};

	render() {
		// const { loading } = this.state;
		// # Variables que switchean las ligas que se consumen segun sea elcaso
const isDev = false; // Tomara entorno local
const isFromDev = false; //Se pondra en true para ambiente DEV
const isFromQA = true; //Se pondra en true para ambiente QA
/* Para productivo se ponen todas en falso */

// #URl de ambiente
const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
// const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
// const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

// # Liga validada
const urlValid = isDev ? urlLocal 
	: isFromDev ? urlDev
	: isFromQA ? urlQA
	: ''

// Se genera el token segun el ambiente
const tokenValid = isDev ? tokenLocal
	: isFromDev ? tokenDev
	: isFromQA  ? tokenQA
	: ''
		return (
			

			<div className="full-container">
				<Arriba/>
				<CookieConsent
					location="none"
					containerClasses="containerCoookie"
					buttonText={i18next.t("Trueques.comenzar")}
					cookieName="modalCookieTriques"
					// onAccept={() => {alert("yay!")}}
					buttonClasses="buttonAcceptCookieTriques"
					disableButtonStyles="true"
					style={{
						background: "rgba(0,0,0,.8)",
						position: "fixed",
						top: "0%",
						width: "100%",
						height: "100vh",
						left: "0"
					}}
					// buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
					expires={365} // es en dias el maximo valor es 365 para un año
				>
					<div className="modalWhiteWelcome modalTextTriques">
						<p className="title">
							<Trans i18nKey="Trueques.title"></Trans>
						</p>
						<p className="subtitle">{i18next.t("Trueques.desc")}</p>
					</div>
				</CookieConsent>
				<br></br>
				<div className="contProfileMenu">
					<div class="profileContainers" id="profileMenu">
					<Aside/>
					</div>
				</div>

				<div className="myHR__container" style={{ backgroundColor: "white", marginBottom: "80px" }}>
					<div className="responsiveLayout">
						<div className="menuSelectionTriques">
							<div className="wrapSelection">
								<div
									className={
										this.state.tabActive === "todos"
											? "contOptionSelection active"
											: "contOptionSelection"
									}
									onClick={this.tabSelector}
									name="todos">
									<img src={box} alt="Todos"></img>
									<p>{i18next.t("Trueques.titlecatego4")}</p>
									<div className="activeBar"></div>
								</div>
								<div
									className={
										this.state.tabActive === "autos"
											? "contOptionSelection active"
											: "contOptionSelection"
									}
									onClick={this.tabSelector}
									name="autos">
									<img src={coche} alt="Coche"></img>
									<p>{i18next.t("Trueques.titlecatego1")}</p>
									<div className="activeBar"></div>
								</div>

								<div
									className={
										this.state.tabActive === "computo"
											? "contOptionSelection active"
											: "contOptionSelection"
									}
									onClick={this.tabSelector}
									name="computo">
									<img src={imac} alt="iMac"></img>
									<p>{i18next.t("Trueques.titlecatego2")}</p>
									<div className="activeBar"></div>
								</div>

								<div
									className={
										this.state.tabActive === "electronics"
											? "contOptionSelection active"
											: "contOptionSelection"
									}
									onClick={this.tabSelector}
									name="electronics">
									<img src={estufa} alt="Estufa"></img>
									<p>{i18next.t("Trueques.titlecatego3")}</p>
									<div className="activeBar"></div>
								</div>

								
								<div
									className={
										this.state.tabActive === "ropa"
											? "contOptionSelection active"
											: "contOptionSelection"
									}
									onClick={this.tabSelector}
									name="ropa">
									<img src={playera} alt="Playera"></img>
									<p>{i18next.t("Trueques.titlecatego5")}</p>
									<div className="activeBar"></div>
								</div>

								<div
									className={
										this.state.tabActive === "otros"
											? "contOptionSelection active"
											: "contOptionSelection"
									}
									onClick={this.tabSelector}
									name="otros">
									<img src={box} alt="Caja"></img>
									<p>{i18next.t("Trueques.titlecatego6")}</p>
									<div className="activeBar"></div>
								</div>
							</div>
						</div>

						{/* INIT ANUNCIO DE FORMULARIO DE TRIQUES Y TRUEQUES */}

						<div className="bluemidWrap">
							<div class="totalBenefits__title__container beneficiosfloatright">
								<img class="iconTitleProfile" src={triqueslogo} alt="Triques y Trueques Logo" />
								<h1>
									<Trans i18nKey="Trueques.footertitle"></Trans>
								</h1>
							</div>
							<p className="des">
								Para poder publicar tu producto en venta/renta, por favor da click en el botón y{" "}
								<strong>llena el formulario con la información necesaria</strong>.
							</p>

							<a
								href={urlValid}
								className="buynow"
								target="_blank"
								rel="noopener noreferrer">
								Enviar Información
							</a>
						</div>

						{/* END ANUNCIO DE FORMULARIO DE TRIQUES Y TRUEQUES */}

						<div className="profile-tabs-content midWrapBlueTriques">
							{this.state.tabActive === "autos" ? (
								<div id="autosBoxTriques" className="wrapperBoxesTriques">
									<h2>{i18next.t("Trueques.desccatego1")}</h2>
									<TriquesAutos category="vehiculos" />
								</div>
							) : "" || this.state.tabActive === "computo" ? (
								<div id="computoBoxTriques" className="wrapperBoxesTriques">
									<h2>{i18next.t("Trueques.desccatego2")}</h2>
									<TriquesAutos category="electronica" />
								</div>
							) : "" || this.state.tabActive === "electronics" ? (
								<div id="electroBoxTriques" className="wrapperBoxesTriques">
									<h2>{i18next.t("Trueques.desccatego3")}</h2>
									<TriquesAutos category="bienesRaices" />
								</div>
							) : "" || this.state.tabActive === "todos" ? (
								<div id="phonesBoxTriques" className="wrapperBoxesTriques">
									<h2>{i18next.t("Trueques.desccatego4")}</h2>
									<TriquesAutos category="todos" />
								</div>
							) : "" || this.state.tabActive === "ropa" ? (
								<div id="ropaBoxTriques" className="wrapperBoxesTriques">
									<h2>{i18next.t("Trueques.desccatego5")}</h2>
									<TriquesAutos category="ropaAccesorios" />
								</div>
							) : "" || this.state.tabActive === "otros" ? (
								<div id="otrosBoxTriques" className="wrapperBoxesTriques">
									<h2>{i18next.t("Trueques.desccatego6")}</h2>
									<TriquesAutos category="servicios" />
								</div>
							) : (
								""
							)}
						</div>
					</div>
				</div>

				<Index/>
			</div>
		);
	}
}
