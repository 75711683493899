import React, { Component } from "react"
import * as contentful from "../../lib/contentful"
import "./css/gamtalks.css"
import GamTalksContenful from "./gamcards"
import i18next from "i18next";
import getLocale from "../../utils/getLocale";
import Header from '../../components/Header';

import Index from '../Footer/index';


export default class GamTalksPage extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: null,
      entries: null,
    };
  }


  componentDidMount() {
    this.fetchEntries();
  }

  fetchEntries = () => {
    contentful.client
      .getEntries({
        content_type: "gamTalks",
        include: 2,
        locale: getLocale(),
      })
      .then(response => response.items)
      .then(data => {
        // console.log(data);
        this.setState({
          loading: false,
          entries: data
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: error
        });
      })
      .catch(err => console.error(err));
  };


  render() {
    const { loading, entries } = this.state;
    return (
      <div className="GamTalksPage">
        <Header/>
        <div className="wrapperPageGamTalks">
          <div className="leftGamTalks">
            <div className="middleGam">
              <div className="middleGamCell">
                <div className="logoGam"></div>
                <p className="title">{i18next.t('GamTalks.titulo')}</p>
                <p className="subtitle">{i18next.t('GamTalks.subtitulo')}</p>
              </div>
            </div>
          </div>
          <div className="rightGamTalks">
            <div className="gamRightMiddle">
              <div className="topGrid">

              {!loading &&
              entries &&
              entries.map(GamTalks => <GamTalksContenful data={GamTalks} />)}

{/* 
                <div className="squareGamTakls">
                  <div className="containerGamTalks">
                    <div className="padding">
                      <p className="area">Experiencia al Cliente</p>
                      <div className="bottom">
                        <div className="floatText">
                          <p className="title">Net Promoter Score</p>
                          <p className="name">Edouard Piquet</p>
                        </div>
                        <div className="play"></div>
                      </div>
                    </div>
                  </div>
                  <img className="imgBackground" src={gamimage}></img>
                </div> */}

          
              </div>
            </div>
            <div className="test"></div>
          </div>
        </div>
        <Index/>
      </div>
    );
  }
}
