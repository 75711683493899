import isMockUser from "./isMockUser";

export default function isMockLevel(level) {
    if (!isMockUser())
        return false;

    var email = localStorage.getItem("email");
    switch (level) {
        case 1:
            return email === "amuserl1@aeromexico.com";
            break;
        case 2:
            return email === "amuserl2@aeromexico.com";
            break;
        case 3:
            return email === "amuserl3@aeromexico.com";
            break;
        case 4:
            return email === "amuserl4@aeromexico.com";
            break;
        case 5:
            return email === "amuserl5@aeromexico.com";
            break;
        case 6:
            return email === "amuserl6@aeromexico.com";
            break;
        case 7:
            return email === "amuserl7@aeromexico.com";
            break;
        case 8:
            return email === "amuserl8@aeromexico.com";
            break;
        case 9:
            return email === "amaccesodelta@aeromexico.com" ||
                email === "amaccesoskyteam@aeromexico.com" ||
                email === "amskyteamaccess@aeromexico.com" ||
                email === "amacessdelta@aeromexico.com" ||
                email === "amaccessguest@aeromexico.com" ||
                email === "amusercan@aeromexico.com";
            break;
        case 10:
            return email === "amuserl10@aeromexico.com" || email === "amaccesoinvitado@aeromexico.com";
            break;
        case 11:
            return email === "amuserl11@aeromexico.com";
            break;
        case 12:
            return email === "amuserl12@aeromexico.com";
            break;
        default:
            return false;
            break;
    }
};
