import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
// import * as contentful from '../../lib/contentful';
import SwiperDiscountModal from '../swiperfeatured';
import FooterProfile from '../FooterProfile';

import profileIcon from '../../../assets/images/profile/icon-mybenefits.svg';
import logobenefit from '../../../assets/images/profile/mybenefitslogo.svg';
import logobenefitEN from '../../../assets/canada/my-benefits.png';
import profileIcon01 from '../../../assets/images/profile/categorias.svg';
import profileIcon02 from '../../../assets/images/profile/icon-mybenefits-02.svg';
import heart from '../../../assets/images/profile/categoria-01.svg';
import Entretenimiento from '../../../assets/images/profile/categoria-02.svg';
import pesas from '../../../assets/images/profile/categoria-03.svg';
import shopping from '../../../assets/images/profile/categoria-04.svg';
import Educacion from '../../../assets/images/profile/categoria-05.svg';
import Hoteles from '../../../assets/images/profile/hoteles.svg';
import restaurante from '../../../assets/images/profile/categoria-07.svg';
import auto from '../../../assets/images/profile/categoria-09.svg';
import Servicios from '../../../assets/images/profile/categoria-10.svg';
import Guarderia from '../../../assets/images/profile/categoria-06.svg';
import { motion } from 'framer-motion';

export default class BDContentMX extends Component {
	state = {
		language: i18next.language
	};

	render() {
		return (
			<React.Fragment>
				<motion.div
					initial={{ opacity: 0, y: 100 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 100 }}
					className="totalBenefits__title__container beneficiosfloatright"
				>
					<div className="iconTitleProfile">
						<img src={profileIcon} alt="Profile Icon" />
					</div>
					<h1>{i18next.t('MiExperAM.benef.destacados')}</h1>

					<div className="logoTabBenefit">
						{/* {this.langua} */}
						<img
							src={
								this.state.language === 'es'
									? logobenefit
									: logobenefitEN
							}
							alt="My Benefits"
						/>
					</div>

					<div class="header_small">
						<div class="iconTitleProfile_small">
							<img src={profileIcon} alt="Profile Icon" />
						</div>
						<h1>{i18next.t('MiExperAM.benef.destacados')}</h1>
					</div>
				</motion.div>

				<motion.div
					initial={{ opacity: 0, y: 100 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 100 }}
					transition={{ delay: 0.25 }}
					className="swiperDiscountsCont"
				>
					<SwiperDiscountModal />
				</motion.div>
				{/* CATEGORIAS */}
				<div className="totalBenefits__container">
					<div className="totalBenefits__title__container">
						<div className="iconTitleProfile">
							<img src={profileIcon01} alt="Profile Icon 01" />
						</div>
						<h1>{i18next.t('MiExperAM.benef.categorias.title')}</h1>
					</div>

					<div className="totalBenefits__cards__container">
						<div className="totalBenefis__card">
							<Link to="/recompensa-total/perfil/mis-beneficios/cuida-de-ti">
								<div className="totalBenefits__card__image" />
								<h5 className="totalBenefits__card__title">
									{i18next.t(
										'MiExperAM.benef.categorias.cuida.title'
									)}
								</h5>
								<p className="totalBenefits__card__text">
									{i18next.t(
										'MiExperAM.benef.categorias.cuida.sub'
									)}
								</p>
							</Link>
						</div>

						<div className="totalBenefis__card">
							<Link to="/recompensa-total/perfil/mis-beneficios/administra-tus-finanzas">
								<div className="totalBenefits__card__image" />
								{/* <img src={image} alt="image" className="img-big-card" /> */}
								<h5 className="totalBenefits__card__title">
									{i18next.t(
										'MiExperAM.benef.categorias.finanzas.title'
									)}
								</h5>
								<p className="totalBenefits__card__text">
									{i18next.t(
										'MiExperAM.benef.categorias.finanzas.title'
									)}
								</p>
							</Link>
						</div>

						<div className="totalBenefis__card">
							<Link to="/recompensa-total/perfil/mis-beneficios/vive-aeromexico">
								<div className="totalBenefits__card__image" />
								<h5 className="totalBenefits__card__title">
									{i18next.t(
										'MiExperAM.benef.categorias.vive.title'
									)}
								</h5>
								<p className="totalBenefits__card__text">
									{i18next.t(
										'MiExperAM.benef.categorias.vive.title'
									)}
								</p>
							</Link>
						</div>
						<div className="totalBenefis__card">
							<Link to="/recompensa-total/perfil/mis-beneficios/disfruta-tu-trabajo">
								<div className="totalBenefits__card__image" />
								<h5 className="totalBenefits__card__title">
									{i18next.t(
										'MiExperAM.benef.categorias.trabajo.title'
									)}
								</h5>
								<p className="totalBenefits__card__text">
									{i18next.t(
										'MiExperAM.benef.categorias.trabajo.title'
									)}
								</p>
							</Link>
						</div>
					</div>
				</div>
				{/* CATEGORIAS DE BENEFICIOS */}
				<motion.div className="discountPerks__container">
					<div className="totalBenefits__title__container">
						<div className="iconTitleProfile">
							<img src={profileIcon02} alt="Profile Icon 02" />
						</div>
						<h1>{i18next.t('MiExperAM.benef.descuentos.title')}</h1>
					</div>
					<div className="discountsPerks__list__container">
						<ul>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/salud-y-belleza">
									{' '}
									<img
										src={heart}
										className="discount_list"
										alt="Salud y belleza"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.salud'
										)}
									</span>
								</Link>
							</li>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/entretenimiento">
									{' '}
									<img
										src={Entretenimiento}
										className="discount_list"
										alt="Entretenimiento"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.entrete'
										)}
									</span>
								</Link>
							</li>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/gimnasios">
									<img
										src={pesas}
										className="discount_list"
										alt="Gimnasios"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.gym'
										)}
									</span>
								</Link>
							</li>
						</ul>
						<ul>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/de-compras">
									{' '}
									<img
										src={shopping}
										className="discount_list"
										alt="Shopping"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.shopp'
										)}
									</span>
								</Link>
							</li>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/educacion">
									<img
										src={Educacion}
										className="discount_list"
										alt="Educación"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.educa'
										)}
									</span>
								</Link>
							</li>

							{/* <li>
								<a
									href="https://www.granplan.com/gam"
									target="_blank"
								>
									<img
										src={Hoteles}
										className="discount_list"
										alt="Educación"
									/>
									<span className="discount_l">
										{i18next.t(
											"MiExperAM.benef.descuentos.hotel"
										)}
									</span>
								</a>
							</li> */}
						</ul>
						<ul>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/restaurantes">
									{' '}
									<img
										src={restaurante}
										className="discount_list"
										alt="Restaurantes"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.resta'
										)}
									</span>
								</Link>
							</li>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/automoviles">
									{' '}
									<img
										src={auto}
										className="discount_list"
										alt="autos"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.auto'
										)}
									</span>
								</Link>
							</li>
						</ul>
						<ul>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/servicios">
									<img
										src={Servicios}
										className="discount_list"
										alt="servicios"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.servi'
										)}
									</span>
								</Link>
							</li>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/guarderias">
									<img
										src={Guarderia}
										className="discount_list"
										alt="Guarderias"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.guarde'
										)}
									</span>
								</Link>
							</li>
						</ul>
					</div>
				</motion.div>
				<FooterProfile />
			</React.Fragment>
		);
	}
}
