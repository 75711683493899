// import { ventaNov21 } from '../utils/validatesTickets2020';
import { abril2022Func } from './isVentaEspecialActive';
// import {} from '../utils';
import { travelBenefitsSpecialCases } from './validateTravelBenefitsSpecialCase';
// import { diciembre2021 } from './ventaEspecial2021Diciembre';
// import { febrero2022 } from './ventaEspecialFebrero2022';
const lodash = require('lodash');

const URL = `${process.env.REACT_APP_LOCAL}`;
let notCalled = false;

export default function isTravelBenefitsActive() {
	if (
		testEmails() ||
		travelSpecificValidation() ||
		specialTravelBenefits(localStorage.getItem('email'))
	) {
		return true;
	} else {
		return false;
	}
	// const email = localStorage.getItem("email");
	// console.log("TRAVEL BENEFITS ACTIVE", email);
	// if (
	// 	email === "jgarciab@aeromexico.com" ||
	// 	email === "jabadia@aeromexico.com"
	// ) {
	// 	return true;
	// } else {
	// 	return false;
	// }
}

export const isFlexibilityActive = async () => {
	const isFlexActive = localStorage.getItem('flexIsActive');
	if (!!isFlexActive && isFlexActive !== '') {
		return isFlexActive === 'true' ? true : false;
	} else {
		const response = await fetch(
			encodeURI(`${URL}/flex-users?email=${localStorage.getItem('email')}`), 
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_LOCAL}`
				}
			}
		).then((res) => res.json());
		if (response.length !== 0) {
			localStorage.setItem('flexIsActive', true);
			return true;
		}
		localStorage.setItem('flexIsActive', false);
		return false;
	}
};

export function isBothActive() {
	if (testEmails()) {
		
		return true;
	} else {
		
		return false;
	}
}

// localStorage.setItem(
// 	"codigo_empresa_AM",
// 	data.codigo_empresa
// );
// localStorage.setItem(
// 	"tipo_empleado_AM",
// 	data.tipo_empleado
// );
// localStorage.setItem("no_empleado_AM", data.noEmpleado);

// function validateCompany(data) {
// 	const code = parseInt(data);
// 	console.log('EMPRESA NUMERO', code);
// 	switch (code) {
// 		case 8:
// 			console.log('EMPORESA NUMERO', true);
// 			return true;
// 		case 9:
// 			console.log('EMPORESA NUMERO', true);
// 			return true;
// 		case 13:
// 			console.log('EMPORESA NUMERO', true);
// 			return true;
// 		case 15:
// 			console.log('EMPORESA NUMERO', true);
// 			return true;
// 		case 37:
// 			console.log('EMPORESA NUMERO', true);
// 			return true;
// 		default:
// 			console.log('EMPORESA NUMERO', false);
// 			return false;
// 	}
// }

function validateEmployee(data) {
	if (data === 'NS') {
		return true;
	} else if (data === 'I') {
		const company = localStorage.getItem('grupo');
		const nsd = 'NSD';
		if (company.includes(nsd)) {
			return true;
		} else {
			return false;
		}
	} else {
		
		return false;
	}
}

// function employeeNumber(data) {
// 	const eNumb = parseInt(data);
// 	const resultado = lodash.findIndex(ventaNOPUEDEN, function(o) {
// 		return o.employee === eNumb;
// 	});
// 	if (resultado < 0) {
// 		console.log('employee number', true);
// 		return true;
// 	} else {
// 		console.log('employee number', false);
// 		return false;
// 	}
// }

// function everyValidation() {
// 	if (
// 		validateCompany(localStorage.getItem('codigo_empresa_AM')) &&
// 		validateEmployee(localStorage.getItem('tipo_empleado_AM'))
// 		// employeeNumber(localStorage.getItem('no_empleado_AM'))
// 	) {
// 		console.log('EVERY VALIDATION', true);
// 		return true;
// 	} else {
// 		console.log('EVERY VALIDATION', false);
// 		return false;
// 	}
// }

function testEmails() {
	if (
		localStorage.getItem('email') === 'jtapias@aeromexico.com' ||
		localStorage.getItem('email') === 'jgarciab@aeromexico.com' ||
		localStorage.getItem('email') === 'kalvaradoa@aeromexico.com'
		// localStorage.getItem('email') === 'fsoler@aeromexico.com'
		// localStorage.getItem('email') === 'dcarbajal@aeromexico.com'
		// localStorage.getItem('email') === 'irios@aeromexico.com'
	) {
		
		return true;
	} else {
		
		return false;
	}
}

export const hasSpecialSale = async (fromWhere) => {
	
	return false;
};

export async function hasSpecialSaleOLD() {
	return false;
	// if (!notCalled) {
	// 	alert('SPECIAL SALE ALERT CALL');
	// 	notCalled = true;
	// 	const hasVenta = await fetch(
	// 		`https://cadmin.miaeromexico.com/ve-usuarios?email_eq=${localStorage.getItem(
	// 			'email'
	// 		)}`
	// 	)
	// 		.then((res) => res.json())
	// 		.catch(console.error);
	// 	const ventaEnabled = await fetch(
	// 		'https://cadmin.miaeromexico.com/menu-items-validator'
	// 	)
	// 		.then((res) => res.json())
	// 		.catch(console.error);
	// 	console.log('hasVENTA: ', hasVenta);
	// 	console.log('ventaEnabled: ', ventaEnabled);
	// 	if (ventaEnabled.venta_especial && hasVenta[0]._id) {
	// 		alert('TRUE');
	// 		console.log('ESPECIAL SALE', true);
	// 		notCalled = false;
	// 		return true;
	// 	} else {
	// 		alert('FALSE');
	// 		console.log('ESPECIAL SALE', false);
	// 		notCalled = false;
	// 		return false;
	// 	}
	// }
}

export function covidTickets() {
	return false;
}

function travelCompany(data) {
	const code = parseInt(data);
	
	switch (code) {
		case 2:
			return true;
		case 3:
			return true;
		case 99:
			return true;
		default:
			return false;
	}
}

function travelSpecificValidation() {
	if (
		travelCompany(localStorage.getItem('codigo_empresa_AM')) &&
		validateEmployee(localStorage.getItem('tipo_empleado_AM'))
	) {
		return true;
	} else {
		return false;
	}
}

function specialTravelBenefits(email) {
	const resultado = lodash.findIndex(
		travelBenefitsSpecialCases,
		function (o) {
			return o.email === email;
		}
	);
	if (resultado >= 0) {
																																																																												
		return true;
	} else {
		return false;
	}
}
