import React, {
	Fragment,
	useState,
	useRef,
	useEffect,
	useContext
} from 'react';
import i18next from 'i18next';
// import { HeaderProfileSyled } from "../Styles/Styled";
import { PDFExport } from '@progress/kendo-react-pdf';
// import Print from "../../../components/Profile/TotalReward/Report/Print";
import NewPrint from './NewPrint';
import Modal from 'react-awesome-modal';
// import ReactPlaceholder from "react-placeholder";


// import ProfileModal from "./ProfileModal";
import ProfileModal from '../../../components/Profile/ProfileModal';
// import InfoEnvironment from "../../../utils/info.environment";
import '../../../components/Profile/css/headerProfile.css';
import setting from '../../../assets/images/profile/settings-profile.svg';
import downloadicon from '../../../assets/images/profile/settings-download.svg';
import close from '../../../assets/logo/close.svg';
// import { PrivateContext, PublicContext } from "../../globalState";
// import { PrivateContext, PublicContext } from '../../../globalState';
/*
██████╗ ███████╗██████╗ ██╗   ██╗██╗  ██╗    ██╗      ██████╗  █████╗ ██████╗ 
██╔══██╗██╔════╝██╔══██╗██║   ██║╚██╗██╔╝    ██║     ██╔═══██╗██╔══██╗██╔══██╗
██████╔╝█████╗  ██║  ██║██║   ██║ ╚███╔╝     ██║     ██║   ██║███████║██║  ██║
██╔══██╗██╔══╝  ██║  ██║██║   ██║ ██╔██╗     ██║     ██║   ██║██╔══██║██║  ██║
██║  ██║███████╗██████╔╝╚██████╔╝██╔╝ ██╗    ███████╗╚██████╔╝██║  ██║██████╔╝
╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝ ╚═╝  ╚═╝    ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ 
*/
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPublicUser } from '../../../redux/actions/actions-public';

const noScroll = require('no-scroll');

function NewHeaderProfile({
	// loading,
	// loadingPriv,
	publicUserData,
	publicData,
	publicLoadedAt,
	getPublicUser
}) {
	// const { data, isTrue } = useContext(PublicContext);
	// const { dataPriv, isTruePriv } = useContext(PrivateContext);
	const [reduxLoading, setReduxLoading] = useState(true);
	const PDFRef = useRef();
	const [visible, setVisible] = useState(false);
	// const privateData = dataPriv;

	useEffect(() => {
		const fiveMinutes = 60 * 5 * 1000;
		if (
			!publicUserData ||
			new Date() - new Date(publicLoadedAt) > fiveMinutes
		) {
			getPublicUser();
			setReduxLoading(false);
		} else {
			if (publicUserData) setReduxLoading(false);
		}
	}, []);

	// useEffect(() => {
	// 	console.log('HEADER PROFILE LOADING', loading);
	// 	console.log('HEADER PROFILE LOADINGPRIV', loadingPriv);
	// 	if (loading === false) {
	// 		console.log(isTrue, isTruePriv);
	// 		console.log(data, privateData);
	// 	}
	// }, [loading, loadingPriv]);

	const openModal = () => {
		setVisible(true);
		noScroll.on();
	};

	const closeModal = () => {
		setVisible(false);
		noScroll.off();
	};

	const exportPDF = () => {
		PDFRef.save();
	};

	return (
		
			<div className="headerProfile">
				{/* MODAL */}
				<Modal
					visible={visible}
					width="0"
					height="0"
					effect="fadeInDown"
					onClickAway={() => closeModal()}
				>
					<div className="modal-container">
						<div class="closeModal" onClick={() => closeModal()}>
							<img src={close} alt="Close" />
						</div>

						<ProfileModal />
					</div>
				</Modal>
				<div
					className={
						localStorage.getItem('alreadyRegistered') === 'true'
							? `complemento-back ${localStorage.getItem(
									'background'
							  )}`
							: 'headerProfileContainer'
					}
				>
					<div className="headerProfileContainerFilter">
						<div className="headerMidWrapper">
							<div className="headerMidWrapperCell">
								<div className="headerProfile__imageText">
									<div className="headerProfile__faceContainer">
										<div
											className={
												localStorage.getItem(
													'alreadyRegistered'
												) === 'true'
													? `profileImage__headerProfile ${localStorage.getItem(
															'avatar'
													  )}`
													: 'profileImage__headerProfile'
											}
										></div>
									</div>
									<div className="headerProfile__nameContainer">
										{/* {loading && data ? ( */}
										{reduxLoading ? (
											<Fragment>
												<h6 id="headerProfile__name">
													
												</h6>
												<h6 id="headerProfile__fullName">
												
												</h6>
												<h6 id="headerProfile__job">
													
												</h6>
											</Fragment>
										) : (
											<Fragment>
												<h6 id="headerProfile__name">
													{/* {data.nombre.toLowerCase()} */}
													{publicData.nombre !== null
														? publicData.nombre.toLowerCase()
														: ''}
												</h6>
												<h6 id="headerProfile__fullName">
													{/* {`${data.nombre.toLowerCase()} ${data.apellidoPaterno.toLowerCase()} ${data.apellidoMaterno.toLowerCase()}`} */}
													{`${
														publicData.nombre !==
														null
															? publicData.nombre.toLowerCase()
															: ''
													} ${
														publicData.apellidoPaterno !==
														null
															? publicData.apellidoPaterno.toLowerCase()
															: ''
													} ${
														publicData.apellidoMaterno !==
														null
															? publicData.apellidoMaterno.toLowerCase()
															: ''
													}`}
												</h6>
												<h6 id="headerProfile__job">
													{/* {data.posicion.toLowerCase()} */}
													{publicData.posicion.toLowerCase()}
												</h6>
											</Fragment>
										)}
									</div>
								</div>
								<div
									className="headerProfile__edit"
									onClick={() => openModal()}
								>
									<span className="lessSpanNew">
										<p>{i18next.t('Profile.config')}</p>
										<img
											src={setting}
											alt="Icon Setting"
											className="icon setting"
										/>
									</span>
								</div>
								{/* CHECKPDFCONDITIONS */}
								{reduxLoading ? (
								<div>
								Cargando...
							</div>
								) : (
									<div
										// onClick={exportPDF}
										// className="headerProfile__edit download-pdf"
										id="print-button"
										style={{ cursor: 'not-allowed' }}
									>
										{/* <span> */}
										{/* <p>{i18next.t("Profile.pdf")}</p>
											<img
												src={downloadicon}
												alt="Download Icon"
												className="icon setting"
											/> */}
										{/* No disponible */}
										{/* </span> */}
									</div>
								)}
								{/* END PDF CHECK CONDITIONS */}
							</div>
						</div>
					</div>
				</div>
				{/* loading && chargingPrivate */}
				<div
					className="print-container"
					id="container-print"
					style={{
						position: 'absolute',
						left: '-1000px',
						top: 0
					}}
				>
					<PDFExport
						paperSize="letter"
						fileName={`Mi Recompensa Total - ${publicData.nombre}.pdf`}
						ref={PDFRef}
						forcePageBreak=".page-break"
					>
						<div
							style={{
								height: 900,
								width: 612,
								padding: 'none',
								backgroundColor: 'white',
								margin: 'auto',
								overflowX: 'hidden',
								overflowY: 'hidden',
								color: '#0a2342',
								fontFamily: 'sans-serif'
							}}
						>
							{/* <Print data={state} /> */}
							{/* <NewPrint
								publicData={data}
								privateData={privateData}
								loading={loading}
							/> */}
						</div>
					</PDFExport>
				</div>
			</div>
		
	);
}

/*
-██████╗ ██████╗ ███╗   ██╗███╗   ██╗███████╗ ██████╗████████╗             
██╔════╝██╔═══██╗████╗  ██║████╗  ██║██╔════╝██╔════╝╚══██╔══╝             
██║     ██║   ██║██╔██╗ ██║██╔██╗ ██║█████╗  ██║        ██║                
██║     ██║   ██║██║╚██╗██║██║╚██╗██║██╔══╝  ██║        ██║                
╚██████╗╚██████╔╝██║ ╚████║██║ ╚████║███████╗╚██████╗   ██║                
-╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝  ╚═══╝╚══════╝ ╚═════╝   ╚═╝                
██╗    ██╗██╗████████╗██╗  ██╗    ██████╗ ███████╗██████╗ ██╗   ██╗██╗  ██╗
██║    ██║██║╚══██╔══╝██║  ██║    ██╔══██╗██╔════╝██╔══██╗██║   ██║╚██╗██╔╝
██║ █╗ ██║██║   ██║   ███████║    ██████╔╝█████╗  ██║  ██║██║   ██║ ╚███╔╝ 
██║███╗██║██║   ██║   ██╔══██║    ██╔══██╗██╔══╝  ██║  ██║██║   ██║ ██╔██╗ 
╚███╔███╔╝██║   ██║   ██║  ██║    ██║  ██║███████╗██████╔╝╚██████╔╝██╔╝ ██╗
-╚══╝╚══╝ ╚═╝   ╚═╝   ╚═╝  ╚═╝    ╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝ ╚═╝  ╚═╝
*/
const mapStateToProps = state => ({
	publicUserData: state.publicUser.publicUserData,
	publicData: state.publicUser.publicData,
	publicLoadedAt: state.publicUser.publicLoadedAt
});
const mapDispatchToProps = dispatch =>
	bindActionCreators({ getPublicUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewHeaderProfile);
