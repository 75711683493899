import React, { Component } from "react";
import "./css/asideProfile.css";
import { Link, NavLink } from "react-router-dom";
// import myhr from "../../assets/images/profileBeneet/myhr.png";
import iconMenuSide01 from "../../assets/images/sidebar/work-car.svg";
import iconMenuSide02 from "../../assets/images/sidebar/work-subsidio.svg";
// import iconMenuSide03 from "../../assets/images/sidebar/settings.svg";
import iconMenuSide04 from "../../assets/images/sidebar/logout.svg";

import iconMenuSide08 from "../../assets/images/sidebar/star.svg";
// import iconMenuSide09 from "../../assets/images/sidebar/profile.svg";
import iconMenuSide20 from "../../assets/images/sidebar/newsprofile.svg";

import InfoEnvironment from "../../utils/info.environment";

// import iconMenuSide0301 from "../../assets/images/sidebar/calendario.svg";
// import iconMenuSide0402 from "../../assets/images/sidebar/descuentos.svg";
import iconMenuSide09vacantes from "../../assets/images/sidebar/vacantes.svg";
import newTab from "../../assets/images/sidebar/newtab.svg";
import triques from "../../assets/images/sidebar/buysell.svg";
// import userProfile from "../../assets/images/profile/default-profile.svg";
// import isMockUser from "../../utils/isMockUser";
import i18next from "i18next";
import Arriba from "../Header/Arriba";

// const sha1 = require("sha1");
// const parseString = require("xml2js").parseString;
// const soapRequest = require("easy-soap-request");

// const profileImage = "http://www.agadiengcollege.com/wp-content/uploads/2017/01/male.jpg";

const getVars = {
	noEmpleado: localStorage.getItem("noEmpleado") || null,
	compania: localStorage.getItem("compania") || null,
	nombre: localStorage.getItem("nombre") || null,
	apellidoMaterno: localStorage.getItem("apellidoMaterno") || null,
	apellidoPaterno: localStorage.getItem("apellidoPaterno") || null,
	grupo: localStorage.getItem("grupo") || null,
	nivel: localStorage.getItem("nivel") || null,
	ubicacion: localStorage.getItem("ubicacion") || null,
	posicion: localStorage.getItem("posicion") || null,
	direccion: localStorage.getItem("direccion") || null,
	direccionArea: localStorage.getItem("direccionArea") || null,
	fechaContra: localStorage.getItem("fechaContra") || null,
	antiguedadEmpleado: localStorage.getItem("antiguedadEmpleado") || null
};

export default class Aside extends Component {
	constructor(props) {
		super(props);
		this.infoEnvironment = new InfoEnvironment();
		// Remplazar en el estado el email para hacer la consulta
		this.state = {
			loading: false,
			// email: 'eabautista@aeromexico.com',
			email: localStorage.getItem("email"),
			respuesta: "",
			data: "",
			token: "",
			generalInfo: {
				noEmpleado: this.props.publicData ? this.props.publicData.noEmpleado : getVars.noEmpleado,
				compania: this.props.publicData ? this.props.publicData.compania : getVars.compania,
				nombre: this.props.publicData ? this.props.publicData.nombre : getVars.nombre,
				apellidoMaterno: this.props.publicData
					? this.props.publicData.apellidoMaterno === null
						? ""
						: this.props.publicData.apellidoMaterno
					: getVars.apellidoMaterno,
				apellidoPaterno: this.props.publicData
					? this.props.publicData.apellidoPaterno === null
						? ""
						: this.props.publicData.apellidoPaterno
					: getVars.apellidoPaterno,
				grupo: this.props.publicData ? this.props.publicData.grupo : getVars.grupo,
				nivel: this.props.publicData ? this.props.publicData.nivel : getVars.nivel,
				ubicacion: this.props.publicData ? this.props.publicData.ubicacion : getVars.ubicacion,
				posicion: this.props.publicData ? this.props.publicData.posicion : getVars.posicion,
				direccion: this.props.publicData ? this.props.publicData.direccion : getVars.direccion,
				direccionArea: this.props.publicData ? this.props.publicData.direccionArea : getVars.direccionArea,
				fechaContra: this.props.publicData ? this.props.publicData.fechaContra : getVars.fechaContra,
				antiguedadEmpleado: this.props.publicData
					? this.props.publicData.antiguedadEmpleado
					: getVars.antiguedadEmpleado
			},
			error: false,
			charging: false
		};
	}

	logout = () => {
		var lang = localStorage.getItem("i18nextLng");
		localStorage.clear();
		sessionStorage.clear();
		localStorage.setItem("i18nextLng", lang);
	};

	// componentDidMount() {
	// setTimeout(() => this.setState({ loading: false }), 1500);
	// {
	// 	isMockUser() ? this.getMockInfo() : this.getInfo();
	// }
	// }

	getMockInfo = async () => {
		localStorage.setItem("nombre", "Usuario");
		localStorage.setItem("pais", "MX");
		localStorage.setItem("apellidoPaterno", "-");
		localStorage.setItem("ubicacion", "-");
		this.setState({
			error: false,
			charging: false,
			generalInfo: {
				nombre: localStorage.getItem("nombre"),
				apellidoPaterno: localStorage.getItem("apellidoPaterno"),
				ubicacion: localStorage.getItem("ubicacion")
			}
		});
	};

	// getInfo = async () => {
	// 	const HOST = 'NOMBREHOST';
	// 	console.log(localStorage.getItem('email'));
	// 	const token = sha1(
	// 		`${HOST}|${localStorage.getItem('email')}`
	// 	).toUpperCase();
	// 	const email = new Buffer(localStorage.getItem('email'))
	// 		.toString('hex')
	// 		.toUpperCase();
	// 	const URL = this.infoEnvironment.api_url({
	// 		host: window.location.hostname
	// 	});

	// 	let formData = new FormData();

	// 	formData.append('token', token);
	// 	formData.append('email', email);
	// 	formData.append('tipo', '1');
	// 	formData.append('tipo_salida', 'JSON');

	// 	fetch(URL, {
	// 		body: formData,
	// 		method: 'post'
	// 	})
	// 		.then(data => data.json())
	// 		.then(data => {
	// 			console.log(data);
	// 			localStorage.setItem('nombre', data.nombre);
	// 			localStorage.setItem('apellidoPaterno', data.apellidoPaterno);
	// 			localStorage.setItem('ubicacion', data.ubicacion);
	// 			localStorage.setItem('codigo_empresa_AM', data.codigo_empresa);
	// 			localStorage.setItem('tipo_empleado_AM', data.tipo_empleado);
	// 			localStorage.setItem('no_empleado_AM', data.noEmpleado);
	// 			this.setState({
	// 				error: false,
	// 				charging: false,
	// 				generalInfo: {
	// 					nombre: localStorage.getItem('nombre'),
	// 					apellidoPaterno: localStorage.getItem(
	// 						'apellidoPaterno'
	// 					),
	// 					ubicacion: localStorage.getItem('ubicacion')
	// 				}
	// 			});
	// 		})
	// 		.catch(error => {
	// 			this.setState({
	// 				error: error
	// 			});
	// 		});
	// };

	render() {
		const { loading, charging, generalInfo } = this.state;
		
		return (
			
			<div className="aside" >
				<aside className="asideContainer scrollable">
				
					<div class="scrolloverImage">
						<div class="scrollThisPlease">
							<div className="asideHeader">
								<div className="asideHeader__profile">
									<div className="faceContainer">
										<div
											className={
												localStorage.getItem("alreadyRegistered") === "true"
													? `profileImage ${localStorage.getItem("avatar")}`
													: `profileImage`
											}
										/>
									</div>
									
									<div className="nameContainer">
										<h6>{i18next.t("Profile.bienvenido")}</h6>
										{localStorage.getItem("grupo") !== "" ? (
											<h6>
												{!charging && generalInfo.nombre && generalInfo.nombre.toLowerCase()}
											</h6>
										) : (
											""
										)}
									</div>
								</div>
							</div>
							{/*}
          <div className="asideMenu1">
            <h1>Employee Experience</h1>
            <ul className="asideLists">
              <Link to="/profile/" className="asideLinks">
                <li className="menuItems">
                  <i className="fa fa-star-o" aria-hidden="true" />My Total Rewards
                </li>
              </Link>
              <Link to="/profile/myhr" className="asideLinks">
                <li className="menuItems">
                  <img className="icon_hr" src={myhr} alt="" />
                  My HR
                </li>
              </Link>
              {/* <Link to="#" className="asideLinks">
                    <li className="menuItems">
                        <i class="fa fa-suitcase" aria-hidden="true"></i>Carreer & Development
                    </li>
                </Link> 
            </ul>
          </div>*/}
		  
							<div className="asideMenu1">
								<h1>{i18next.t("Profile.miexper")}</h1>
								<ul className="accessLinks">
									<li activeClassName="active">
										{/* <Link to="/noticias/nuestragente"> */}
										<Link to="/noticias/ultimas-noticias">
											<span>
												<img src={iconMenuSide20} />
											</span>
											<p>{i18next.t("Profile.noticias")}</p>
										</Link>
									</li>
									{(localStorage.getItem("nivel") === "1" &&
										localStorage.getItem("grupo") != "99 NSD - NO SINDICALIZADOS") ||
									localStorage.getItem("nivel") === "2" ||
									localStorage.getItem("nivel") === "3" ||
									localStorage.getItem("nivel") === "4" ||
									localStorage.getItem("nivel") === "5" ||
									localStorage.getItem("nivel") === "6" ||
									localStorage.getItem("nivel") === "7" ||
									localStorage.getItem("nivel") === "8" ||
									localStorage.getItem("nivel") === "9" ||
									localStorage.getItem("nivel") === "10" ||
									localStorage.getItem("nivel") === "11" ||
									localStorage.getItem("nivel") === "12" ||
									(localStorage.getItem("grupo") === "99 NSD - NO SINDICALIZADOS" &&
										localStorage.getItem("email") === "nferri@aeromexico.com") ||
									(localStorage.getItem("grupo") === "99 NSD - NO SINDICALIZADOS" &&
										localStorage.getItem("email") === "jsarvis@aeromexico.com") ? (
										<li activeClassName="active">
											<Link
												activeClassName="side-profile-active"
												to="/recompensa-total/perfil/mi-compensaciones">
												<span>
													<img src={iconMenuSide08} />
												</span>
												<p>{i18next.t("Profile.recompensa")}</p>
											</Link>
										</li>
									) : (
										""
									)}
									{/* {localStorage.getItem('nivel') === '6' ||
									localStorage.getItem('nivel') === '7' ||
									localStorage.getItem('nivel') === '8' ||
									localStorage.getItem('nivel') === '9' ||
									localStorage.getItem('nivel') === '10' ||
									localStorage.getItem('nivel') === '11' ||
									localStorage.getItem('nivel') === '12' ? (
										<li activeClassName="active">
											<NavLink
												activeClassName="side-profile-active"
												to="/recompensa-total/my-hr"
											>
												<span>
													<img src={iconMenuSide09} />
												</span>
												<p>{i18next.t('Profile.hr')}</p>
											</NavLink>
										</li>
									) : (
										''
									)} */}
									<li activeClassName="active">
										<Link activeClassName="side-profile-active" to="/oportunidades-carrera">
											<span>
												<img src={iconMenuSide09vacantes} />
											</span>
											<p>{i18next.t("Profile.vacantes")}</p>
										</Link>
									</li>
								</ul>
							</div>
							<div className="asideMenu1">
								<h1>{i18next.t("Profile.accesos")}</h1>
								<ul className="accessLinks">
									<li style={{ background: window.location.pathname === '/buy-and-sell' ? '#035CF7' : 'none'}}>
										<Link activeClassName="side-profile-active" to="/buy-and-sell">
											<span>
												<img src={triques} />
											</span>
											<p style={{ color: window.location.pathname === '/buy-and-sell' ? 'white' : 'black' }} >{i18next.t("Profile.triques")}</p>
											<img className="NewTab" src={newTab} />
										</Link>
									</li>

									<li>
										<Link to="/recompensa-total/perfil/mis-beneficios/disfruta-tu-trabajo/transportacion">
											<span>
												<img src={iconMenuSide01} />
											</span>
											<p>{i18next.t("Profile.transInterna")}</p>
											<img className="NewTab" src={newTab} />
										</Link>
									</li>

									<li>
										<a href="https://alphait.mx/menuCecam/" target="_blank">
											<span>
												<img src={iconMenuSide02} />
											</span>
											<p>{i18next.t("Profile.comedor")}</p>
											<img className="NewTab" src={newTab} />
										</a>
									</li>

								</ul>
							</div>

							<div className="asideMenu1 asideMenu2">
								<ul className="accessLinks">
									<li className="logout">
										<a href="/login" onClick={this.logout}>
											<span>
												<img src={iconMenuSide04} />
											</span>
											<p>{i18next.t("Profile.cerrarSesion")}</p>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</aside>
			</div>
		);
	}
}
