/* import React, { Fragment } from 'react';
import ReactPlayer from 'react-player';
import BG from '../../assets/juntos/wallpaper.png';
import Index from './../../components/Footer/index';
import './css/juntos-styles.css'; */
import React, { Fragment } from 'react';
import ReactPlayer from 'react-player';
import Index from './../../components/Footer/index';
import BG from '../../assets/juntos/wallpaper.png';
// import BG from '../../assets/juntos/avion.jpg';
import './css/juntos-styles.css';

import Header from '../../components/Header';
export default function Juntos() {
	return (
		<Fragment>
			<Header/>
			<div className="container-fluid juntos-container">
				<div
					className="juntos-bg"
					style={{ background: `url(${BG}) no-repeat center` }}
				></div>
				<div className="front-part ">
					<div className="row justify-content-center">
						<div className="col-12 main-header-content-juntos">
							<h1>¡Cumplimos 88 años!</h1>
						</div>
						<div className="col-12 col-sm-8">
							<div
								className="video-container"
								style={{
									position: 'relative',
									paddingTop: '56.25%',
									maxWidth: '1024px',
									width: '100%'
								}}
							>
								<ReactPlayer
									style={{
										position: 'absolute',
										top: 0,
										left: '50%',
										transform: 'translateX(-50%)',
										zIndex: 100
									}}
									url={`${process.env.REACT_APP_SRC_URL}videos/88-Aniversario-AM_ESP.mp4`}
									controls={true}
									
									playing
									width="100%"
									height="100%"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="juntons-rest-videos ">
				<div className="row" style={{ padding: '0 6%' }}>
					<h2 className="col-12">Vuelve a ver otros videos</h2>
				
					<div className="col-12 col-sm-6 col-md-4 item--video">
						<div className="video-container-holder">
							<ReactPlayer
								url={`${process.env.REACT_APP_SRC_URL}videos/86%20AN%CC%83OS%20ENG%20SUB_YT.mp4`}
								controls={true}
								width="100%"
								height="100%"
								className="react-player"
							/>
						</div>
						<p>86 años</p>
					</div>
					<div className="col-12 col-sm-6 col-md-4 item--video">
						<div className="video-container-holder">
							<ReactPlayer
								url={`${process.env.REACT_APP_SRC_URL}videos/Vuelo%20200%205C%20SPA%20NS_LOGO.mp4`}
								controls={true}
								width="100%"
								height="100%"
								className="react-player"
							/>
						</div>
						<p>!Vamos por más!</p>
					</div>
					<div className="col-12 col-sm-6 col-md-4 item--video">
						<div className="video-container-holder">
							<ReactPlayer
								url="https://jabadia.wistia.com/medias/espm1h058s"
								controls={true}
								width="100%"
								height="100%"
								className="react-player"
							/>
						</div>
						<p>De Corazón: ¡gracias!</p>
					</div>
					<div className="col-12 col-sm-6 col-md-4 item--video">
						<div className="video-container-holder">
							<ReactPlayer
								url="https://roganoalien.wistia.com/medias/ms67j2d6fh"
								controls={true}
								width="100%"
								height="100%"
								className="react-player"
							/>
						</div>
						<p>
							Creamos el primer Sistema de Gestión de Salud e
							Higiene (HSMS) para todo Grupo Aeroméxico
						</p>
					</div>
					<div className="col-12 col-sm-6 col-md-4 item--video">
						<div className="video-container-holder">
							<ReactPlayer
								url="https://roganoalien.wistia.com/medias/k83j782xps"
								controls={true}
								width="100%"
								height="100%"
								className="react-player"
							/>
						</div>
						<p>¡Gracias!</p>
					</div>
					<div className="col-12 col-sm-6 col-md-4 item--video">
						<div className="video-container-holder">
							<ReactPlayer
								url="https://youtu.be/LoiISJJIRbk"
								controls={true}
								width="100%"
								height="100%"
								className="react-player"
							/>
						</div>
						<p>
							Transportamos insumos médicos y sanitarios desde
							China
						</p>
					</div>
					<div className="col-12 col-sm-6 col-md-4 item--video">
						<div className="video-container-holder">
							<ReactPlayer
								url={`${process.env.REACT_APP_SRC_URL}videos/0403_MENSAJE%20JAMES%20SARVIS%20INGLES.mov`}
								controls={true}
								width="100%"
								height="100%"
								className="react-player"
							/>
						</div>
						<p>¡Sigamos poniendo la seguridad primero!</p>
					</div>
					<div className="col-12 col-sm-6 col-md-4 item--video">
						<div className="video-container-holder">
							<ReactPlayer
								url={`${process.env.REACT_APP_SRC_URL}videos/Misionero%20de%20Paz.mp4`}
								controls={true}
								width="100%"
								height="100%"
								className="react-player"
							/>
						</div>
						<p>Misionero de Paz</p>
					</div>
					<div className="col-12 col-sm-6 col-md-4 item--video">
						<div className="video-container-holder">
							<ReactPlayer
								url={`${process.env.REACT_APP_SRC_URL}videos/Mensaje%20del%20Director%20General.mov`}
								controls={true}
								width="100%"
								height="100%"
								className="react-player"
							/>
						</div>
						<p>Mensaje del Director General</p>
					</div>
					<div className="col-12 col-sm-6 col-md-4 item--video">
						<div className="video-container-holder">
							<ReactPlayer
								url="https://jabadia.wistia.com/medias/04pu2nzd2g"
								controls={true}
								width="100%"
								height="100%"
								className="react-player"
							/>
						</div>
						<p>Juntos Continuaremos Volando</p>
					</div>
					<div className="col-12 col-sm-6 col-md-4 item--video">
						<div className="video-container-holder">
							<ReactPlayer
								url="https://www.youtube.com/embed/2tPrtIWDW3k"
								controls={true}
								width="100%"
								height="100%"
								className="react-player"
							/>
						</div>
						<p>Medidas Preventivas ante Coronavirus</p>
						
					</div>
					
				</div>
				
			</div>
			<Index/>
		</Fragment>
		
	);
}
