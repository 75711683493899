import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import DOMPurify from 'dompurify';

function FaqsNacional({ faq, index }) {
	const dirtyHTML = `languge !== 'es' ? faq.localizations[0].answer : faq.answer`;
	const cleanHTML = DOMPurify.sanitize(dirtyHTML);
	return (
		<>
			<h2
				key={`key-holder-generic-${faq.title}-${index}`}
				className={`${
					index === 0 ? 'mt-10' : 'mt-24'
				} w-full text-xl font-bold text-amBlue`}
			>
				{faq.title}
			</h2>
			<div
				key={`${faq.title}-${index}-faqs-holder`}
				className="faqs-holder mt-6 space-y-6 divide-y divide-gray-200"
			>
				{faq.faqs.map((question, index) => (
					<Disclosure
						as="div"
						key={`${question.question}-${index}`}
						className="pt-6"
					>
						{({ open }) => (
							<>
								<dt className="text-md">
									<Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400 outline-none focus:outline-none ring-0 focus:ring-0">
										<span className="font-medium text-gray-900">
											{question.question}
										</span>
										<span className="ml-6 h-7 flex items-center">
											<ChevronDownIcon
												className={`${
													open
														? '-rotate-180'
														: 'rotate-0'
												} h-6 w-6 transform`}
												aria-hidden="true"
											/>
										</span>
									</Disclosure.Button>
								</dt>
								<Disclosure.Panel
									as="dd"
									className="mt-2 pr-12"
								>
									<div
		className="prose text-gray-500 max-w-full"
		dangerouslySetInnerHTML={{
		__html: cleanHTML
		}}
></div>
								</Disclosure.Panel>
							</>
						)}
					</Disclosure>
				))}
			</div>
		</>
	);
}

export default FaqsNacional;
