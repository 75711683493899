import React, { Component } from 'react';
import arrow from '../../assets/images/arrow-blue.svg';
import { Link } from 'react-router-dom';
import './css/travelBenefits.css';

import avion from '../../assets/images/benefits/download-bg-travel.jpg';

import logobenefit04 from '../../assets/images/profile/mybenefitslogotravel.svg';
import iconDownloadManual from '../../assets/images/download-icon.svg';
import profileIcon010 from '../../assets/images/profile/icon-mybenefits10.svg';
import profileIcon011 from '../../assets/images/profile/icon-mybenefits11.svg';
import profileIcon013 from '../../assets/images/profile/icon-mybenefits13.svg';
import accesTravel01 from '../../assets/images/arrow-blue.svg';
import linc from '../../assets/images/linc.jpg';
import core from '../../assets/images/core.jpg';
import id from '../../assets/images/id.jpg';
import periodos from '../../assets/calendar.svg';

import destinosTravel from '../../assets/destinos.svg';
import periodosTravel from '../../assets/politica.svg';
import Travel from './TravelBenefits/travelbenefits';
import MaletaGratis from './Modales/maleta';
import AsientoGratis from './Modales/asiento';
import ModalPremier1 from './Modales/modal1.js';
import ModalPremier7 from './Modales/modalancillaries'; //--- Modal de Upgrade para niveles 7 - 9
import ModalPremier10 from './Modales/premierL7'; //--- Modal de Upgrade para niveles 10 - 12

import PerdiodosEmbargo from './Modales/modalembargo';

import Swiper from 'react-id-swiper';
import '../Slider/css/swiper.css';
import i18next from 'i18next';
// import { motion } from "framer-motion";
// import "../Slider/css/style.css"
import isTravelBenefitsActive, {
	covidTickets
} from '../../utils/isTravelBenefitsActive';
import { AnimatePresence, motion } from 'framer-motion';
// import { url } from 'inspector';

const isSpanish = i18next.language === 'es';

export default class TravelBenefits extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			content: null,
			language: i18next.language,
			modalCovid: false,
			reembolsoAlert: sessionStorage.getItem('reembolsoAlert') || false
		};
	}

	async componentDidMount() {

		const token = process.env.REACT_APP_TOKEN_LOCAL;
		const baseUrl = `${process.env.REACT_APP_LOCAL}my-travel-benefits`;
		const locale = isSpanish ? 'es-MX' : 'en';
		const url = `${baseUrl}?locale=${locale}`;

		
		const cabecera = new Headers({ 
    		'Content-type': 'application/json',
    		'Authorization': `Bearer ${token}`
		});

		await fetch(url, { headers:cabecera } )
			.then((response) => {
				return response.json();
			})
			.then(async (json) => {
				await this.setState({
					...this.state,
					loading: false,
					content:
						this.state.language === 'es'
							? json.data.content.es
							: json.data.content.en
				});
			});
		// if (covidTickets()) {
		// 	setTimeout(() => {
		// 		this.setState({
		// 			...this.state,
		// 			modalCovid: true
		// 		});
		// 	}, 250);
		// }
	}

	hidingCookie = () => {
		this.setState({
			...this.state,
			reembolsoAlert: true
		});
		sessionStorage.setItem('reembolsoAlert', true);
	};

	render() {
		//Seteo de local storage, dependiendo el nivel que se tenga o pidan
		localStorage.setItem('nivel', 5);
		const nivel = parseInt(localStorage.getItem('nivel'));
		const params = {
			slidesPerView: 'auto',
			/*effect: 'coverflow',*/
			spaceBetween: 30,
			grabCursor: true,
			/*loop: true,*/
			autoplay: {
				delay: 5000,
				disableOnInteraction: true
			},

			// pagination: {
			//   el: '.swiper-pagination',
			//   /*type: 'bullets',*/
			//   // type: 'progressbar',
			//   clickable: true,
			//   dynamicBullets: true,
			// },
			mousewheel: true,
			freeMode: false,

			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			}
		};
		if (isTravelBenefitsActive()) {
			return (
				<motion.div
					key="travel-benefits-contenedor-2021"
					initial={{ opacity: 0, y: 100 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 100 }}
					className="travel-container"
				>
					<div className="totalBenefits__title__container">
						<div className="logoTabBenefit">
							<img className="Travel" src={logobenefit04} />
						</div>
					</div>

					<AnimatePresence exitBeforeEnter>
						{!this.state.reembolsoAlert && (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								style={{
									position: 'fixed',
									left: 0,
									top: 0,
									height: '100vh',
									width: '100vw',
									zIndex: 100
								}}
							>
								<div
									className="containerCoookie"
									style={{
										background: 'rgba(0,0,0,.8)',
										position: 'fixed',
										top: '0%',
										width: '100%',
										height: '100vh',
										left: '0'
									}}
								>
									<div
										className="modalWhiteWelcome modalTextTriques modalventaEspecialCookie"
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
											padding: '0 40px'
										}}
									>
										{/* <p className="title">Recuerda</p> */}
										<p
											className="subtitle mt-3"
											style={{ fontSize: '16px' }}
										>
											<strong>Recuerda:</strong> tus
											boletos Travel Benefits tienen
											vigencia de un año para su uso a
											partir de la fecha de emisión.
										</p>
										<p
											className="subtitle mt-3"
											style={{ fontSize: '16px' }}
										>
											Una vez que estos hayan{' '}
											<strong>expirado</strong>, solo
											tienes <strong>30 días</strong> para
											solicitar el{' '}
											<strong>reembolso</strong> del monto
											pagado. Consulta más información en
											el apartado de Reembolso.
										</p>
										<button
											type="button"
											className="acceptVentaEspecialButton outline-none focus:outline-none focus:shadow-none"
											onClick={this.hidingCookie}
											style={{
												left: 'auto',
												right: 'auto',
												top: 'auto',
												bottom: 'auto',
												position: 'relative',
												marginTop: '1em',
												transform: 'translate(0,0)'
											}}
										>
											Entendido
										</button>
									</div>
								</div>
							</motion.div>
						)}
					</AnimatePresence>

					<Travel />

					{/* <div className='travel-benefit-cards' /> */}

					<div className="okBenefitsChange">
						<div className="totalBenefitsMidWrapper">
							<div className="totalBenefitsTravel__container">
								<div
									className="totalBenefits__title__container"
									id="AncillariesTitle"
								>
									<div className="iconTitleProfile">
										<img src={profileIcon010} />
									</div>
									<h1>
										{i18next.t(
											'MiExperAM.travel.ancillares.title'
										)}
									</h1>
								</div>

								<div className="totalBenefitsTravel__cards__container">
									{nivel > 9 ? <ModalPremier10 /> : ''}

									{nivel > 6 && nivel < 10 ? (
										<ModalPremier7 />
									) : (
										''
									)}
									{nivel < 7 && <ModalPremier1 />}
									<AsientoGratis />
									<MaletaGratis />
								</div>

								<div className="totalBenefits__title__container">
									<div className="iconTitleProfile">
										<img src={profileIcon013} />
									</div>
									<h1>
										{i18next.t(
											'MiExperAM.travel.masInfoTitle'
										)}
									</h1>
								</div>

								<div className="moreInformationWrap">
									<Swiper
										{...params}
										className="swiper-home-slider"
									>
										<a
											className="boxMore information"
											href={i18next.t(
												'MiExperAM.travel.masInfo.cambiosLink'
											)}
											target="_blank"
											rel="noopener noreferrer"
										>
											<div className="pictureInformation"></div>
											<div className="textMoreInformation">
												<div className="textMoreInformationCell">
													<p className="title">
														{i18next.t(
															'MiExperAM.travel.masInfo.cambios'
														)}
													</p>
													<p className="subtitle">
														{i18next.t(
															'MiExperAM.travel.masInfo.cambiosSub'
														)}
													</p>
												</div>
												{/* <p className="link">Leer más</p> */}
											</div>
										</a>

										<a
											className="boxMore information"
											href={i18next.t(
												'MiExperAM.travel.masInfo.reembolsosLink'
											)}
											target="_blank"
											rel="noopener noreferrer"
										>
											<div className="pictureInformation"></div>
											<div className="textMoreInformation">
												<div className="textMoreInformationCell">
													<p className="title">
														{i18next.t(
															'MiExperAM.travel.masInfo.reembolsos'
														)}
													</p>
													<p className="subtitle">
														{i18next.t(
															'MiExperAM.travel.masInfo.reembolsosSub'
														)}
													</p>
												</div>
												{/* <p className="link">Leer más</p> */}
											</div>
										</a>

										<a
											className="boxMore information"
											href={i18next.t(
												'MiExperAM.travel.masInfo.compaLink'
											)}
											target="_blank"
											rel="noopener noreferrer"
										>
											<div className="pictureInformation"></div>
											<div className="textMoreInformation">
												<div className="textMoreInformationCell">
													<p className="title">
														{i18next.t(
															'MiExperAM.travel.masInfo.compa'
														)}
													</p>
													<p className="subtitle">
														{i18next.t(
															'MiExperAM.travel.masInfo.compaSub'
														)}
													</p>
												</div>
												{/* <p className="link">Leer más</p> */}
											</div>
										</a>
										{/* <a
											className="boxMore information"
											href={i18next.t(
												'MiExperAM.travel.masInfo.boletosLink'
											)}
											target="_blank"
											rel="noopener noreferrer"
										>
											<div className="pictureInformation">
												<div className="PlayButton"></div>
											</div>
											<div className="textMoreInformation">
												<div className="textMoreInformationCell">
													<p className="title">
														{i18next.t(
															'MiExperAM.travel.masInfo.boletos'
														)}
													</p>
													<p className="subtitle">
														{i18next.t(
															'MiExperAM.travel.masInfo.boletosSub'
														)}
													</p>
												</div>
											</div>
										</a> */}

										<a
											className="boxMore information"
											href={i18next.t(
												'MiExperAM.travel.masInfo.zedLink'
											)}
											target="_blank"
										>
											<div className="pictureInformation"></div>
											<div className="textMoreInformation">
												<div className="textMoreInformationCell">
													<p className="title">
														{i18next.t(
															'MiExperAM.travel.masInfo.zed'
														)}
													</p>
													<p className="subtitle">
														{i18next.t(
															'MiExperAM.travel.masInfo.zedSub'
														)}
													</p>
												</div>
												{/* <p className="link">Leer más</p> */}
											</div>
										</a>
									</Swiper>
								</div>
							</div>
						</div>

						<div className="totalLeftBenefitsContacts">
							<div className="boletosEmbargo">
								<Link
									to="https://aeromexico.com/es-mx/destinos"
									target="_blank"
								>
									<div className="cardWrapperBoletos">
										<img
											className="icon"
											src={destinosTravel}
										></img>
										<div className="ticketsText">
											<p>
												{i18next.t(
													'MiExperAM.travel.leftMenu.destinos'
												)}
											</p>
										</div>
										<img
											className="arrow"
											src={accesTravel01}
										></img>
									</div>
								</Link>

								<PerdiodosEmbargo />

								{parseInt(localStorage.getItem('nivel')) <
								10 ? (
									<a
										href={`${process.env.REACT_APP_SRC_URL}travel-benefits/S_PO_GACB_08-1%20L1-9%20Espan%CC%83ol.pdf`}
										target="_blank"
									>
										<div className="cardWrapperBoletos">
											<img
												className="icon"
												src={periodosTravel}
											></img>
											<div className="ticketsText">
												<p>
													{i18next.t(
														'MiExperAM.travel.leftMenu.politica'
													)}
												</p>
											</div>
											<img
												className="arrow"
												src={accesTravel01}
											></img>
										</div>
									</a>
								) : (
									<a
										href={`${process.env.REACT_APP_SRC_URL}travel-benefits/S_PO_GACB_08-1%20L1-9%20Espan%CC%83ol.pdf`}
										target="_blank"
									>
										<div className="cardWrapperBoletos">
											<img
												className="icon"
												src={periodosTravel}
											></img>
											<div className="ticketsText">
												<p>
													{i18next.t(
														'MiExperAM.travel.leftMenu.politica'
													)}
												</p>
											</div>
											<img
												className="arrow"
												src={accesTravel01}
											></img>
										</div>
									</a>
								)}

								{/* {localStorage.getItem('nivel') === '10' ||
								localStorage.getItem('nivel') === '11' ||
								localStorage.getItem('nivel') === '12' ? (
									<a
										href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/EbY3uuH2LfpKmIYL5ZeWBj0Be0XmdVWwOopYdwfwBbH_cw?e=yufxnj"
										target="_blank"
									>
										<div className="cardWrapperBoletos">
											<img
												className="icon"
												src={periodosTravel}
											></img>
											<div className="ticketsText">
												<p>
													{i18next.t(
														'MiExperAM.travel.leftMenu.politica'
													)}
												</p>
											</div>
											<img
												className="arrow"
												src={accesTravel01}
											></img>
										</div>
									</a>
								) : (
									''
								)} */}
							</div>

							<div className="fixedBoletosEmbargo">
								<div className="acceslinks">
									<div className="title">
										<p>
											{i18next.t(
												'MiExperAM.travel.leftMenu.boletos.title'
											)}
										</p>
									</div>

									<a
										href="https://autoservicio.aeromexico.com/"
										target="_blank"
									>
										<div className="cardWrapperBoletos">
											<img
												className="icon"
												src={core}
											></img>
											<div className="ticketsText">
												<p>
													{i18next.t(
														'MiExperAM.travel.leftMenu.boletos.auto'
													)}
												</p>
												<p>
													{i18next.t(
														'MiExperAM.travel.leftMenu.boletos.autoSub'
													)}
												</p>
											</div>
											<img
												className="arrow"
												src={accesTravel01}
											></img>
										</div>
									</a>

									<a
										href="https://www.myidtravel.com/myidtravel/ui/"
										target="_blank"
									>
										<div className="cardWrapperBoletos">
											<img
												className="icon"
												src={id}
											></img>
											<div className="ticketsText">
												<p>
													{i18next.t(
														'MiExperAM.travel.leftMenu.boletos.travel'
													)}
												</p>
												<p>
													{i18next.t(
														'MiExperAM.travel.leftMenu.boletos.travelSub'
													)}
												</p>
											</div>
											<img
												className="arrow"
												src={accesTravel01}
											></img>
										</div>
									</a>

									<a href="tel:5591324488">
										<div className="cardWrapperBoletos">
											<img
												className="icon"
												src={linc}
											></img>
											<div className="ticketsText">
												<p>
													{i18next.t(
														'MiExperAM.travel.leftMenu.boletos.linea'
													)}
												</p>
												<p>
													{i18next.t(
														'MiExperAM.travel.leftMenu.boletos.lineaSub'
													)}
												</p>
											</div>
											<img
												className="arrow"
												src={accesTravel01}
											></img>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="CoreTravelContacts"></div> */}

					<DownloadManual />
				</motion.div>
			);
		} else {
			return null;
		}
	}
}
const BenefitCardBG = (props) => (
	<div
		className="benefits-big-card bg-container-card travel"
		style={{
			backgroundImage: `${props.gradient}, url(${props.image})`,
			backgroundSize: `${props.size}`,
			backgroundPositionY: `${props.positionY}`,
			backgroundPositionX: `${props.positionX}`
		}}
	>
		<p
			className="moreTravelBenefits__card__text"
			style={{ color: `${props.color}` }}
		>
			{props.content}
		</p>
		<h5
			className="moreTravelBenefits__card__title"
			style={{ color: `${props.color}` }}
			dangerouslySetInnerHTML={{ __html: props.title }}
		/>

		<Link className="link-travel-benefits" to="#">
			<div className="moreTravel">
				<h6
					className="moreTravelBenefits__card__more"
					style={{ color: `${props.color}` }}
				>
					View more{' '}
				</h6>
				<img className="blue-arrow" src={arrow} alt="blue-arrow" />{' '}
			</div>
		</Link>
	</div>
);
const DownloadManual = () => (
	<div>
		{/*Nivel L1-L8*/}
		{/*Nivel L1-L8*/}
		{/*Nivel L1-L8*/}
		{parseInt(localStorage.getItem('nivel')) > 0 &&
		parseInt(localStorage.getItem('nivel')) < 9 ? (
			<div className="button-download travel">
				<a
					target="_blank"
					rel="noopener noreferrer"
					className="download-button"
					style={{
						backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.2), rgba(11, 35, 67,0.2)),url(${avion})`
					}}
					href={i18next.t('MiExperAM.manualestravel.l1_l8')}
				>
					<div className="container-download">
						<div class="icondownload">
							<img
								src={iconDownloadManual}
								alt="downloadmanual"
							/>
						</div>
						{/* <i className="fa fa-download" /> */}
						<div className="down-benefits">
							<h3 className="download-benefits">
								{i18next.t('MiExperAM.travel.manualTitle')}
							</h3>
							<h5 className="download-bnf">
								{i18next.t('MiExperAM.travel.manualSub')}
							</h5>
						</div>
					</div>
					<h2 className="download">
						{i18next.t('MiExperAM.travel.descargar')}
					</h2>
				</a>
			</div>
		) : (
			''
		)}

		{/*Nivel L9*/}
		{/*Nivel L9*/}
		{/*Nivel L9*/}
		{localStorage.getItem('nivel') === '9' ? (
			<div className="button-download travel">
				<a
					target="_blank"
					rel="noopener noreferrer"
					className="download-button"
					style={{
						backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.2), rgba(11, 35, 67,0.2)),url(${avion})`
					}}
					href={i18next.t('MiExperAM.manualestravel.l9')}
				>
					<div className="container-download">
						<div class="icondownload">
							<img src={iconDownloadManual} />
						</div>
						{/* <i className="fa fa-download" /> */}
						<div className="down-benefits">
							<h3 className="download-benefits">
								{i18next.t('MiExperAM.travel.manualTitleN9')}
							</h3>
							<h5 className="download-bnf">
								{i18next.t('MiExperAM.travel.manualSub')}
							</h5>
						</div>
					</div>
					<h2 className="download">
						{i18next.t('MiExperAM.travel.descargar')}
					</h2>
				</a>
			</div>
		) : (
			''
		)}

		{/*Nivel L10-L12*/}
		{/*Nivel L10-L12*/}
		{/*Nivel L10-L12*/}
		{parseInt(localStorage.getItem('nivel')) > 9 ? (
			<div className="button-download travel">
				<a
					target="_blank"
					rel="noopener noreferrer"
					className="download-button"
					style={{
						backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.2), rgba(11, 35, 67,0.2)),url(${avion})`
					}}
					href={i18next.t('MiExperAM.manualestravel.l10')}
				>
					<div className="container-download">
						<div class="icondownload">
							<img src={iconDownloadManual} />
						</div>
						{/* <i className="fa fa-download" /> */}
						<div className="down-benefits">
							<h3 className="download-benefits">
								{i18next.t(
									'MiExperAM.travel.manualTitleN10-12'
								)}
							</h3>
							<h5 className="download-bnf">
								{i18next.t('MiExperAM.travel.manualSub')}
							</h5>
						</div>
					</div>
					<h2 className="download">
						{i18next.t('MiExperAM.travel.descargar')}
					</h2>
				</a>
			</div>
		) : (
			''
		)}
	</div>
);
