import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./css/estilos.css";
import Comite from "../ComiteEjecutivo/comite.js";
import LoadingScreen from "react-loading-screen";
import i18next from "i18next";
import './../../tailwindcss.css';
import './../../tailwind.css';
import Header from './../../components/Header'
import Index from '../Footer/index';


export default class C06 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 1500);
  }

  render() {
    const { loading } = this.state;

    return (
      <LoadingScreen
        loading={loading}
        bgColor="#f5f5f5"
        spinnerColor="#007dc3"
        textColor="#0b2343"
        text={i18next.t('News.cargando')}
      >
        <Header/>
        <div>
          <div className="blue-header Comiteejecutivo">
            <div className="backContainer">
              <div className="backContainerWrapper">
                <Link to="/inicio" id="backLink">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                      focusable="false"
                      class="ay bq ci cb cc cd ce bc ef bj"
                    >
                      <path d="M23.23 32a1.53 1.53 0 0 0 1.08-2.61L10.93 16 24.32 2.61A1.53 1.53 0 0 0 22.15.45L7.68 14.92a1.53 1.53 0 0 0 0 2.16l14.47 14.47a1.53 1.53 0 0 0 1.08.45z" />
                    </svg>
                  </span>
                  <p>{i18next.t('News.regresar')} </p>
                </Link>
              </div>
            </div>
            <div className="HeaderWrapper">
              <div className="HeaderCell">
                <div className="newsContainer">
                  <p>
                    <span>{i18next.t('ComiteEjec.titulo')}</span>
                  </p>
                  <p className="IntroHeader" />
                </div>
              </div>
            </div>
          </div>

          <Comite />
        </div>
        <Index/>
      </LoadingScreen>
      
    );
  }
}
