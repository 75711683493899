import React from "react";
import ReactMarkdown from "react-markdown";
import { ReactComponent as MyFlex } from "./assets/my-flexibility.svg";
import Matriz from "./assets/matriz-elegibilidad.png";
// import { isFlexibilityActive } from '../../utils/isTravelBenefitsActive';
import { ReactComponent as EnSitio } from "./assets/en-sitio.svg";
import { ReactComponent as EnSitioEn } from "./assets/en-sitio-ingles.svg";
import { ReactComponent as Parcial } from "./assets/parcial.svg";
import { ReactComponent as ParcialEn } from "./assets/parcial-ingles.svg";
import { ReactComponent as Remoto } from "./assets/remoto.svg";
import { ReactComponent as RemotoEn } from "./assets/remoto-ingles.svg";
import { Navigate } from "react-router";
import { useEffect, useState } from "react";
import FaqsNacional from "./FaqsNacional";
import FaqsInternacional from "./FaqsInternacional";
import i18next from "i18next";
import DOMPurify from "dompurify";

const isDev = false;
const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlValid = isDev ? urlLocal : urlDev;

const URL = `${urlValid}`;

function Flexibility() {
	const [userData, setUserData] = useState(null);
	const [mainData, setMainData] = useState(null);
	const [kitData, setKitData] = useState({});
	const [loading, setLoading] = useState(true);
	const [imBoss, setImBoss] = useState(false);
	const [interFaqs, setInterFaqs] = useState(null);
	const [internacional, setInternacional] = useState(false);
	const [canAccess, setCanAccess] = useState(null);
	const language = i18next.language;

	const minionSchemeTranslation = (theScheme, internacional) => {
		if (internacional) {
			switch (theScheme) {
				case "En sitio internacional":
					if (language === "es") {
						return "En sitio";
					} else {
						return "On site";
					}
				case "Parcial internacional":
					if (language === "es") {
						return "Parcial";
					} else {
						return "Partial";
					}
				default:
					if (language === "es") {
						return "Remoto";
					} else {
						return "Remote";
					}
			}
		} else {
			return theScheme;
		}
	};

	useEffect(() => {
		fetch(encodeURI(`${URL}/flex-users?email=${localStorage.getItem("email")}&_sort=createdAt:DESC`), {
			// fetch(`${URL}/flex-users?email=blablaseasokg@aeromexico.com`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then((res) => res.json())
			.then(async (res) => {
				if (!res[0].scheme.includes("internacional")) {
					setCanAccess(res.length !== 0 ? true : false);

					setUserData(res[0]);
					setKitData({
						day: res[0].kitDay,
						place: res[0].kitPlace,
						time: res[0].kitTime
					});
					const schemaToSend = res[0].scheme;
					const esJefe = await fetch(encodeURI(`${URL}/flex-users?bossEmployee=${res[0].employee}`), {
						method: "GET",
						headers: {
							"Content-Type": "application/json"
						}
					}).then((res) => res.json());

					const newMinionsArray = [];
					for (let i = 0; i < esJefe.length; i++) {
						if (esJefe[i].kitDay === "0") {
							newMinionsArray.push(esJefe[i]);
						}
					}
					if (esJefe.length > 0) {
						await setUserData({
							...userData,
							// minions: esJefe,
							minions: newMinionsArray,
							isBoss: true
						});
						await setImBoss(true);
					} else {
						await setUserData({ ...userData, isBoss: false });
					}
					getData(schemaToSend);
				} else {
					setInternacional(true);
					const theFaqs = await fetch(`${URL}/flex-faqs`).then((res) => res.json());

					setInterFaqs(theFaqs);
					setCanAccess(res.length !== 0 ? true : false);

					setUserData(res[0]);
					const schemaToSend = res[0].scheme;
					const esJefe = await fetch(encodeURI(`${URL}/flex-users?bossEmployee=${res[0].employee}`), {
						method: "GET",
						headers: {
							"Content-Type": "application/json"
						}
					}).then((res) => res.json());

					if (esJefe.length > 0) {
						await setUserData({
							...userData,
							minions: esJefe,
							isBoss: true
						});
						await setImBoss(true);
					} else {
						await setUserData({ ...userData, isBoss: false });
					}
					getData(schemaToSend, true);
				}
			})
			.catch(console.error);
	}, []);

	const getData = (scheme, international = false) => {
		const theScheme = scheme.toLowerCase().replaceAll(" ", "-");

		const regularURL = `${URL}/${theScheme}`;
		const internationalURL = international ? "" : "";
		fetch(encodeURI(`${regularURL}${internationalURL}`), {
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then((res) => res.json())
			.then((res) => {
				setMainData(res);
				setLoading(false);
				return res;
			});
	};

	if (canAccess !== null && canAccess) {
		return (
			<section className="w-full mt-20 text-amBlack">
				<header className="w-full flex items-center justify-center">
					<MyFlex className="h-20 w-auto" />
				</header>
				{loading ? (
					<></>
				) : (
					<>
						{mainData.firstAlert ? (
							<div className="w-full bg-amYellow rounded-lg py-3 my-10">
								<div className="mx-auto px-3 sm:px-6 lg:px-8 flex items-center justify-center">
									<span className="flex p-1 rounded-lg bg-amBlack bg-opacity-10 mr-3">
										<svg
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6 text-amBlack fill-current">
											<path d="M0 0h24v24H0z" fill="none" />
											<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z" />
										</svg>
									</span>

									<div className="p-1 sm:px-6 lg:px-8 text-sm">
										<ReactMarkdown>{mainData.firstAlert}</ReactMarkdown>
									</div>
								</div>
							</div>
						) : null}
						<div className="w-full flex items-center justify-start bg-white rounded-lg px-8 py-3">
							<div className={`ImageUser ${localStorage.getItem("avatar")}`}></div>
							<div className="intel flex flex-col items-start justify-center ml-4">
								<h1 className="text-2xl font-bold text-amDarkBlue text-center">
									¡{language === "es" ? "Hola" : "Hello"} {localStorage.getItem("nombre")}!
								</h1>
								<p className="w-full text-center leading-tight text-black text-lg">
									{language !== "es" && internacional
										? mainData.localizations[0].role
										: mainData.role}{" "}
									<strong>
										{language !== "es" && internacional
											? mainData.localizations[0].type
											: mainData.type}
									</strong>
								</p>
							</div>
						</div>
						<div className="w-full my-7 flex items-center justify-center">
							{mainData.type === "Remoto" && (
								<>
									{language !== "es" ? (
										<RemotoEn className="w-8/12 sm:w-5/12 lg:w-3/12 h-auto" />
									) : (
										<Remoto className="w-8/12 sm:w-5/12 lg:w-3/12 h-auto" />
									)}
								</>
							)}
							{mainData.type === "Parcial" && (
								<>
									{language !== "es" ? (
										<ParcialEn className="w-8/12 sm:w-5/12 lg:w-3/12 h-auto" />
									) : (
										<Parcial className="w-8/12 sm:w-5/12 lg:w-3/12 h-auto" />
									)}
								</>
							)}
							{mainData.type === "En Sitio" && (
								<>
									{language !== "es" ? (
										<EnSitioEn className="w-8/12 sm:w-5/12 lg:w-3/12 h-auto" />
									) : (
										<EnSitio className="w-8/12 sm:w-5/12 lg:w-3/12 h-auto" />
									)}
								</>
							)}
						</div>
					</>
				)}
				<div className="w-full grid grid-cols-6 gap-6 mt-14 bg-white rounded-lg">
					{!loading && (
						<div className={`${imBoss ? "col-span-3" : "col-span-6"} p-8 pb-10`}>
							<h2 className="text-xl font-bold tracking-tight sm:text-2xl mb-2 text-amBlue">
								{language === "es" ? "Tu esquema" : "Your scheme"}
							</h2>
							<div className="w-full prose max-w-full">
								<ReactMarkdown>
									{language !== "es" && internacional
										? mainData.localizations[0].description
										: mainData.description}
								</ReactMarkdown>
								{mainData.FlexKit && (
									<>
										<p>
											{mainData.FlexKit} <strong>{kitData.day}</strong> a las{" "}
											<strong>{kitData.time}</strong> en <strong>{kitData.place}</strong>.
										</p>
										<p className="mb-8">{mainData.addendum}</p>
										<a
											href={encodeURIComponent(mainData.addendumBtnLink)}
											className="no-underline bg-amRed py-3 px-4 text-white rounded-full font-bold">
											{mainData.addendumBtn}
										</a>
									</>
								)}
							</div>
						</div>
					)}
					{!loading && userData.isBoss && (
						<div className="col-span-3 p-8">
							<h2 className="text-xl font-bold tracking-tight sm:text-2xl text-amBlue">
								{language === "es" ? "Tu equipo" : "Your team"}
							</h2>
							{userData.minions.map((minion) => (
								<div
									key={`minion-data-${minion.name}`}
									className="border border-gray-300 my-3 w-full p-6 rounded-lg">
									<p className="text-lg text-left font-bold">{minion.name}</p>
									<p className="text-base text-left text-gray-500">
										{language === "es" ? "Esquema" : "Scheme"}:{" "}
										<strong>{minionSchemeTranslation(minion.scheme, internacional)}</strong>
									</p>
								</div>
							))}
							{!internacional && (
								<div className="w-full flex items-center justify-center mt-2">
									<a
										href={Matriz}
										target="_blank"
										rel="noopener noreferrer"
										className="bg-amRed py-3 px-4 no-underline rounded-full text-white font-bold">
										{language !== "es" && internacional
											? mainData.localizations[0].elegibilityBtn
											: mainData.elegibilityBtn}
									</a>
								</div>
							)}
						</div>
					)}
				</div>
				<div className="w-full bg-amYellow rounded-lg py-3 my-10">
					<div className="mx-auto px-3 sm:px-6 lg:px-8 flex items-center justify-center">
						<span className="flex p-1 rounded-lg bg-amBlack bg-opacity-10 mr-3">
							<svg
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6 text-amBlack fill-current">
								<path d="M0 0h24v24H0z" fill="none" />
								<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z" />
							</svg>
						</span>
						{!loading && (
							<p className="p-1 sm:px-6 lg:px-8 text-sm">
								{mainData.secondAlert
									? language !== "es" && internacional
										? mainData.localizations[0].secondAlert
										: mainData.secondAlert
									: `Por favor asegúrate de leer toda la información
								de preguntas frecuentes ya que incluye
								información importante sobre tu esquema de
								trabajo: cobertura de seguro de gastos médicos
								mayores fuera de México, boletos Flex Place,
								Flex Kit, etc.`}
							</p>
						)}
					</div>
				</div>
				<div className="flex flex-col items-end justify-center w-full mt-10">
					<h2 className="text-xl text-black font-bold mb-4 w-full text-left">
						{!loading && (
							<>
								{language !== "es" && internacional
									? mainData.localizations[0].requiredTitle
									: mainData.requiredTitle}
							</>
						)}
					</h2>
					<div className="w-full flex flex-col list-disc list-inside myownprose">
						{!loading && (
							<ReactMarkdown>
								{language !== "es" && internacional
									? mainData.localizations[0].requiredText
									: mainData.requiredText}
							</ReactMarkdown>
						)}
					</div>
				</div>
				<div className="flex flex-col items-end justify-center w-full mt-10">
					<h2 className="text-xl text-black font-bold mb-4 w-full text-left">
						{!loading && (
							<>
								{language !== "es" && internacional
									? mainData.localizations[0].modalitiesTitle
									: mainData.modalitiesTitle}
							</>
						)}
					</h2>
					<div className="w-full grid grid-cols-3 gap-6 mt-5">
						{!loading && mainData.placeImage ? (
							<>
								<div className="col-span-3 lg:col-span-1 flex items-center justify-center">
									{!loading && (
										<img
											src={encodeURIComponent(mainData.placeImage.url)}
											className="h-auto w-8/12 lg:w-11/12 object-cover"
											alt="Flex-Place"
										/>
									)}
								</div>
								<div className="col-span-3 lg:col-span-2 bg-white p-4 rounded-lg text-sm space-y-3 flex-prose">
									{!loading && <ReactMarkdown>{mainData.placeDescription}</ReactMarkdown>}
								</div>
							</>
						) : null}
						{!loading && mainData.leaveImage ? (
							<>
								<div className="col-span-3 lg:col-span-1 flex flex-col items-center justify-center">
									{!loading && (
										<>
											<img
												src={encodeURIComponent(mainData.leaveImage.url)}
												alt="Flex-Leave"
												className="h-auto w-8/12 lg:w-11/12"
											/>
											<p className="text-gray-500 text-sm w-8/12 lg:w-11/12 mt-2">
												{mainData.leaveSubtitle}
											</p>
										</>
									)}
								</div>
								<div className="col-span-3 lg:col-span-2 bg-white p-4 rounded-lg text-sm space-y-3 flex-prose">
									{!loading && <ReactMarkdown>{mainData.leaveDescription}</ReactMarkdown>}
								</div>
							</>
						) : null}
						{!loading && mainData.daysImage ? (
							<>
								<div className="col-span-3 lg:col-span-1 flex flex-col items-center justify-center">
									{!loading && (
										<>
											<img
												src={encodeURIComponent(mainData.daysImage.url)}
												alt="Diversity Flex-Days"
												className="h-auto w-8/12 lg:w-11/12"
											/>
											<p className="text-gray-500 text-sm w-8/12 lg:w-11/12 mt-2">
												{language !== "es" && internacional
													? mainData.localizations[0].daysSubtitle
													: mainData.daysSubtitle}
											</p>
										</>
									)}
								</div>
								<div className="col-span-3 lg:col-span-2 bg-white p-4 rounded-lg text-sm flex-prose-none">
									{!loading && (
										<ReactMarkdown>
											{language !== "es" && internacional
												? mainData.localizations[0].daysDescription
												: mainData.daysDescription}
										</ReactMarkdown>
									)}
								</div>
							</>
						) : null}
						{!loading && mainData.timeImage ? (
							<>
								<div
									className={`col-span-3 ${
										mainData.timeDescription ? "lg:col-span-1" : "lg:col-span-3"
									} flex flex-col items-center justify-center`}>
									{!loading && (
										<>
											<img
												src={encodeURIComponent(mainData.timeImage.url)}
												alt="Flex-Time"
												className={`h-auto w-8/12 ${
													mainData.timeDescription ? "lg:w-11/12" : "lg:w-4/12 mx-auto"
												}`}
											/>
											<p
												className={`text-gray-500 text-sm w-8/12 ${
													mainData.timeDescription ? "lg:w-11/12" : "lg:w-4/12 mx-auto"
												} mt-2`}>
												{language !== "es" && internacional
													? mainData.localizations[0].timeSubtitle
													: mainData.timeSubtitle}
											</p>
										</>
									)}
								</div>
								{!loading && mainData.timeDescription ? (
									<div className="col-span-3 lg:col-span-2 bg-white rounded-lg overflow-hidden flex items-center justify-center p-4">
										<img
											src={encodeURIComponent(mainData.timeDescription.url)}
											alt="Flex-Time Table"
											className="w-full h-full object-contain"
										/>
									</div>
								) : null}
							</>
						) : null}
					</div>
				</div>
				<p className="w-full text-xl font-bold text-left my-10">
					{language === "es" ? "Preguntas frecuentes" : "FAQs"}
				</p>
				<div className="w-full">
					{!loading && (
						<>
							{!internacional
								? mainData.faqs.map((faq, index) => (
										<FaqsNacional key={`nacional-preguntas-${index}`} faq={faq} index={index} />
									))
								: interFaqs.map((faq, index) => (
										<FaqsInternacional
											key={`internacional-preguntas-${index}`}
											faq={faq}
											language={language}
											index={index}
										/>
									))}
						</>
					)}
				</div>
			</section>
		);
	} else if (canAccess !== null && !canAccess) {
		return <Navigate to="/inicio" />;
	} else {
		return null;
	}
}

export default Flexibility;
