import React from "react";
import { PoliticaStyled } from "../styles/Styled";
import i18next from 'i18next';

function Politica() {
	const isSpanish = i18next.language === 'es';
	return (
		<PoliticaStyled>
			<div className="container">
				<div className="row align-items-center">
					<div className="col-12 col-md-6">
						<p>
						{isSpanish?"Conoce más detalles en la página oficial del Gobierno de México":"Learn more details on the official website of the Government of Mexico" }
						</p>
					</div>
					<div className="col-12 col-md-6 right">
						<a
							href="https://www.gob.mx/stps/documentos/guia-informativa-nom-035"
							className="fake-red-button"
							target="_blank"
							rel="noopener noreferrer"
						>
							{isSpanish?"Ir al sitio":"Go to the site"}
						</a>
					</div>
				</div>
			</div>
		</PoliticaStyled>
	);
}

export default Politica;
