import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import "./turn.js";

import "./styles.css";
import i18next from "i18next";

class Turn extends React.Component {
	static defaultProps = {
		style: {},
		className: "",
		options: {}
	};

	componentDidMount() {
		if (this.el) {
			$(this.el).turn(Object.assign({}, this.props.options));
		}
		document.addEventListener("keydown", this.handleKeyDown, false);
	}

	componentWillUnmount() {
		if (this.el) {
			$(this.el)
				.turn("destroy")
				.remove();
		}
		document.removeEventListener("keydown", this.handleKeyDown, false);
	}

	handleKeyDown = event => {
		if (event.keyCode === 37) {
			$(this.el).turn("previous");
		}
		if (event.keyCode === 39) {
			$(this.el).turn("next");
		}
	};

	render() {
		return (
			<div
				className={this.props.className}
				style={Object.assign({}, this.props.style)}
				ref={el => (this.el = el)}
			>
				{this.props.children}
			</div>
		);
	}
}

const options = {
	width: 900,
	height: 600,
	autoCenter: true,
	display: "double",
	acceleration: true,
	elevation: 50,
	gradients: !$.isTouch,
	when: {
		turned: function(e, page) {
			
		}
	}
};

const pages = [
	"https://vuela.aeromexico.com/ISO2018/informe/pages/1.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/2.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/3.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/4.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/5.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/6.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/7.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/8.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/9.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/10.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/11.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/12.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/13.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/14.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/15.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/16.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/17.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/18.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/19.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/20.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/21.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/22.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/23.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/24.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/25.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/26.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/27.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/28.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/29.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/30.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/31.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/32.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/33.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/34.jpg",

	"https://vuela.aeromexico.com/ISO2018/informe/pages/35.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/36.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/37.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/38.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/39.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/40.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/41.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/42.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/43.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/44.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/45.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/46.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/47.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/48.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/49.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/50.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/51.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/52.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/53.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/54.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/55.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/56.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/57.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/58.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/59.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/60.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/61.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/62.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/63.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/64.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/65.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/66.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/67.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/68.jpg",

	"https://vuela.aeromexico.com/ISO2018/informe/pages/69.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/70.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/71.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/72.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/73.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/74.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/75.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/76.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/77.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/78.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/79.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/80.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/81.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/82.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/83.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/84.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/85.jpg",
	"https://vuela.aeromexico.com/ISO2018/informe/pages/86.jpg"
];

const FlipBook = () => {
	return (
		<div className="pagesBackground">
			<div className="headerBackgroundInforme">
				<h3 className="informeTitle">
					{i18next.t("Sostenib.informes.informeSust")}
					asfgsfagsfagsfag
				</h3>
				<a
					className="downloadInforme"
					href="https://vuela.aeromexico.com/ISO2018/informe/files/Informe-Sostenibilidad-Aeromexico-2018.pdf"
					target="_blank"
					rel="noopener noreferrer"
				>
					{i18next.t("Sostenib.informes.descargarInforme")}
				</a>
			</div>

			<Turn options={options} className="magazine magazineMiam">
				{pages.map((page, index) => (
					<div key={index} className="page">
						<img src={page} alt="" />
					</div>
				))}
			</Turn>
		</div>
	);
};

export default FlipBook;
