import React from 'react';

function WrapperButtonBig({ pdf, download, year, url }) {
	return (
		<div className="wrapperbuttonBig">
			<div className="wrapperTop">
				<div className="wrapperTopCell">
					<img className="noTextImg" src={pdf}></img>
					<p>{year}</p>
				</div>
			</div>
			<a
				className="wrapperBottom"
				href={url}
				target="_blank"
				rel="noopener noreferrer"
			>
				<div className="downloadIdenti">
					<span></span>
					<p>{download}</p>
				</div>
			</a>
		</div>
	);
}

export default WrapperButtonBig;
