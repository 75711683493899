import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ReloadOnUrlChange = () => {
  const location = useLocation();
  const prevLocation = useRef(location.pathname); // Almacena la URL anterior usando useRef

  useEffect(() => {
    // Verifica si la URL actual es diferente a la anterior antes de recargar
    if (location.pathname !== prevLocation.current) {
      window.location.reload();
      prevLocation.current = location.pathname; // Actualiza la URL anterior
    }
  }, [location.pathname]); // La dependencia aquí hace que useEffect se active solo cuando cambie la ruta

  return null;
};

export default ReloadOnUrlChange;


