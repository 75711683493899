import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./css/newsCategoryHeader.css";
import i18next from "i18next";
import { NavLink } from "react-router-dom";
export default class NewsCategoryHeader extends Component {

	render() {
    
		return (
			<div className="newsCategoryHeaderContainer">
				<div className="backgroundContainer">
					<div className="newsCategoryMenuContainer">
						<div className="miniContainer">
							<div className="backLinkContainer1">
								<Link to="/inicio" id="backLink1">
									<i class="fa fa-long-arrow-left" aria-hidden="true" id="backIcon1" />
									<span>{i18next.t("News.regresarInicio")}</span>
								</Link>
							</div>
							<div className="newsCategoryMenu">
								<ul>
									<router>
										<Link
											activeClassName="buttonactiveNew"
											to="/noticias/ultimas-noticias"
											className="buttons">
											<li className="buttonText">{i18next.t("News.topMenu.ultimas")}</li>
										</Link>
									</router>
									<router>
										<NavLink
											activeClassName="buttonactiveNew"
											to="/noticias/seguridad"
											className="buttons">
											<li className="buttonText">{i18next.t("News.topMenu.seguridad")}</li>
										</NavLink>
									</router>

									<router>
										<Link
											activeClassName="buttonactiveNew"
											to="/noticias/nuestragente"
											className="buttons">
											<li className="buttonText">{i18next.t("News.topMenu.gente")}</li>
										</Link>
									</router>
									<router>
										<Link
											activeClassName="buttonactiveNew"
											to="/noticias/experienciacliente"
											className="buttons">
											<li className="buttonText">{i18next.t("News.topMenu.expCliente")}</li>
										</Link>
									</router>
									<router>
										<Link
											activeClassName="buttonactiveNew"
											to="/noticias/delta"
											className="buttons">
											<li className="buttonText">{i18next.t("News.topMenu.jca")}</li>
										</Link>
									</router>
									<router>
										<Link
											activeClassName="buttonactiveNew"
											to="/noticias/comercial"
											className="buttons">
											<li className="buttonText">{i18next.t("News.topMenu.comercial")}</li>
										</Link>
									</router>
									<roter>
										<Link
											activeClassName="buttonactiveNew"
											to="/noticias/operaciones"
											className="buttons">
											<li className="buttonText">{i18next.t("News.topMenu.opera")}</li>
										</Link>
									</roter>
									<router>
										<Link
											activeClassName="buttonactiveNew"
											to="/noticias/skyteam"
											className="buttons">
											<li className="buttonText">{i18next.t("News.topMenu.sky")}</li>
										</Link>
									</router>
									<router>
										<Link
											activeClassName="buttonactiveNew"
											to="/noticias/sostenibilidad"
											className="buttons">
											<li className="buttonText">{i18next.t("News.topMenu.sosten")}</li>
										</Link>
									</router>
								</ul>
							</div>
						</div>
					</div>

					<div className="newsCategoryTextContainer">
						<div className="newsCategoryText">
							<div className="miniContainerText">
								<h1>{i18next.t("News.ultimas")}</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
