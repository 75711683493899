import React, { Component } from 'react';
import * as contentful from '../../lib/contentful';
import '../Slider/css/swiper.css';
import pdf from '../../assets/identidad/pdf.svg';
import getLocale from '../../utils/getLocale';
import i18next from 'i18next';

export default class ReportesGrid extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			error: null,
			entries: null
		};
	}
	componentDidMount() {
		this.fetchEntries();
	}

	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: 'responsabilidadReportes',
				include: 2,
				order: 'sys.updatedAt',
				locale: getLocale()
			})
			.then(response => response.items)
			.then(data => {
				
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	render() {
		const { loading, entries } = this.state;

		const params = {
			slidesPerView: 'auto',
			spaceBetween: 30,

			autoplay: {
				delay: 50000,
				disableOnInteraction: true
			},

			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
				dynamicBullets: true
			},
			mousewheel: true,
			freeMode: true,

			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			}
		};

		return (
			<React.Fragment>
				{!loading &&
					entries &&
					entries.map(worktool => (
						/*entries.slice(0, 6).map(worktool => (*/
						<div
							className={
								worktool.fields.tipoDocumentoResponsabilidad +
								' ' +
								'wrapperbuttonBig'
							}
							key={worktool.sys.id}
						>
							<div className="wrapperTop">
								<div className="wrapperTopCell">
									<img className="noTextImg" src={pdf}></img>
									<p>{worktool.fields.tituloResponsabilidad}</p>
								</div>
							</div>
							<a
								className="wrapperBottom"
								href={worktool.fields.ligaDocumentoResponsabilidad}
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="downloadIdenti">
									<span></span>
									<p> {i18next.t('Sostenib.informes.descargar')} </p>
								</div>
							</a>
						</div>
					))}
			</React.Fragment>
		);
	}
}
