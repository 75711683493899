import React, { Component } from "react";
/* import MenuTop from './MenuTop'; */
import Arriba from "./Arriba";
import styled from "styled-components";
import { Events, animateScroll as scroll, scroller } from "react-scroll";

const MenuScrollPages = styled.div`
	position: fixed;
	${'' /* left: 50%;  */}
	${'' /* transform: translate(-50%, 0%); */}
	
	width: 100%;
	height: 60px;
	z-index: 99;
	opacity: 1;
	transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
	box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05); 
`;
export default class IndexHeader extends Component {
	constructor(props) {
		super(props);
		this.scrollToTop = this.scrollToTop.bind(this);
		this.state = {
			hasScrolled: false,
			error: false
		};
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);

		Events.scrollEvent.register("begin", function () {});

		Events.scrollEvent.register("end", function () {});
	}

	scrollToTop() {
		scroll.scrollToTop();
	}
	scrollTo() {
		scroller.scrollTo("scroll-to-element", {
			duration: 800,
			delay: 0,
			smooth: "easeInOutQuart"
		});
	}
	scrollToWithContainer() {
		let goToContainer = new Promise((resolve, reject) => {
			Events.scrollEvent.register("end", () => {
				resolve();
				Events.scrollEvent.remove("end");
			});

			scroller.scrollTo("scroll-container", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart"
			});
		});

		goToContainer.then(() =>
			scroller.scrollTo("scroll-container-second-element", {
				duration: 800,
				delay: 0,
				smooth: "easeInOutQuart",
				containerId: "scroll-container"
			})
		);
	}
	componentWillUnmount() {
		Events.scrollEvent.remove("begin");
		Events.scrollEvent.remove("end");
	}

	handleScroll = (event) => {
		const scrollTop = window.pageYOffset;
		console.log(scrollTop);
		if (scrollTop > 50) {
			this.setState({ hasScrolled: true });
		} else {
			this.setState({ hasScrolled: false });
		}
	};

	render() {
		return (
			// <MenuScrollPages className={this.state.hasScrolled ? "WrapperMenu WrapperMenuScrolled" : "WrapperMenu"}>
				<Arriba />
			// </MenuScrollPages>
		);
	}
}
