import React, { Component } from "react";
import "../flightplan/css/flightplan.css";
import LoadingScreen from "react-loading-screen";
import avion from "../../assets/images/Spinner/avion.gif";
import close from "../../assets/logo/close.svg";
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import DOMPurify from "dompurify";

var noScroll = require("no-scroll");

export default class vision extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsShow: false,
			modalIsShowIdiomas: false,
			loading: true,
			loadedApi: false,
			error: null,
			entries: null,
			visible: false,
			content: null
		};

		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	async componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1500);
		document.addEventListener("keydown", this.escFunction, false);
		const cabecera = new Headers({ 
			'Content-type': 'application/json',
			'Authorization':'Bearer ' + process.env.REACT_APP_TOKEN_LOCAL
		});

		await fetch(`${process.env.REACT_APP_LOCAL}/traduccion-es`,{headers:cabecera})
			.then(response => {
				return response.json();
			})
			.then(async json => {
				
				await this.setState({
					...this.state,
					loadedApi: true,
					content: json.data.attributes.data.Inicio.planVuelo.version_2.en
				});
				
			});
	}

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false,
				modalIsShowIdiomas: false,
				visible: false
			});
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				modalIsShow: false,
				modalIsShowIdiomas: false
			});
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
	};

	showAlertModal01 = () => {
		this.setState({
			modalIsShowIdiomas: !this.state.modalIsShowIdiomas
		});
	};

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	render() {
		const { loading } = this.state;
		const securyFirstHTML = DOMPurify.sanitize(`this.state.content.safety`);
		const experienciaUsuarioHTML = DOMPurify.sanitize(`this.state.content.ex`);
		const custExperienceHTML = DOMPurify.sanitize(`this.state.content.cx`);

		const eficienciaHTML = DOMPurify.sanitize(`this.state.content.create`);
		const aerolineaFavHTML = DOMPurify.sanitize(`this.state.content.carrier`);
		const ejecucionImpecableHTML = DOMPurify.sanitize(`this.state.content.execution`);

		return (
			<LoadingScreen
				loading={loading}
				bgColor="#f5f5f5"
				spinnerColor="#007dc3"
				textColor="#0b2343"
				//logoSrc={avion}
				text="Loading your experience"
			>
				<div>
					{this.state.modalIsShow === true ? (
						<div className="flightVideoModal">
							<div
								className="videoModalWindow"
								ref={this.setWrapperRef}
							>
								<div
									className="closeModal"
									onClick={this.showAlertModal}
								>
									<img src={close} alt="cerrar"></img>
								</div>
								<iframe
									width="100%"
									height="100%"
									src="https://www.youtube.com/embed/5mt3FdPdUT0"
									frameborder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</div>
					) : (
						""
					)}

					<Modal
						visible={this.state.visible}
						width="auto"
						height="auto"
						effect="fadeInDown"
						onClickAway={() => this.closeModal()}
						className="container-modal-notification"
					>
						<div className="selectLangugeModal">
							<div className="whiteModal">
								<div
									className="closeModal"
									onClick={() => this.closeModal()}
								>
									<img src={close} alt="cerrar"></img>
								</div>

								<div className="padding">
									<h4>Selecciona un idioma</h4>
									<div className="gridFlags">
										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo"
										>
											<div className="flags spanish"></div>
											<p>Español</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/ingles"
										>
											<div className="flags english"></div>
											<p>Inglés</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/frances"
										>
											<div className="flags french"></div>
											<p>Francés</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/holandes"
										>
											<div className="flags dutch"></div>
											<p>Holandés</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/portugues"
										>
											<div className="flags portuguese"></div>
											<p>Portugués</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/chino"
										>
											<div className="flags mandarin"></div>
											<p>Mandarín</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/coreano"
										>
											<div className="flags korean"></div>
											<p>Coreano</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/japones"
										>
											<div className="flags japan"></div>
											<p>Japonés</p>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</Modal>
					{this.state.loadedApi && (
						<div className="flightplanWrapper">
							<div className="midflightplan">
								<h1>
									Flight Plan <br></br>2019
								</h1>

								<a
									className="download"
									href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EYI4lo9olZdNjsgYMLeFwugBAMMhHXTOT1yS73_GUoAwxA?e=hcFzDw"
									target="_blank"
								>
									<p>Download Flight Plan</p>
								</a>

								<div className="linenamebottom"></div>

								<div
									className="languageFlightPlan"
									onClick={() => this.openModal()}
								>
									<p>English</p>
									<div className="languageFlag usa"></div>
								</div>

								<div className="circlePlaneFlight">
									<h4 className="title color01">Seguridad</h4>
									<p className="securityFirst">
										<span
											className="text"
											dangerouslySetInnerHTML={{
												__html: securyFirstHTML
											}}
										></span>
										<span className="arrow down"></span>
									</p>

									<h4 className="title color02">
										Experiencia del Empleado
									</h4>
									<p className="employeeExperience">
										<span
											className="text"
											dangerouslySetInnerHTML={{
												__html: experienciaUsuarioHTML
											}}
										></span>
										<span className="arrow right top"></span>
									</p>

									<h4 className="title color03">
										Experiencia del Cliente
									</h4>
									<p className="cutomerExperience">
										<span
											className="text"
											dangerouslySetInnerHTML={{
												__html: custExperienceHTML
											}}
										></span>
										<span className="arrow left top"></span>
									</p>

									<h4 className="title color04">
										Crear Eficiencia
									</h4>
									<p className="crearEficiencia">
										<span
											className="text"
											dangerouslySetInnerHTML={{
												__html: eficienciaHTML
											}}
										></span>
										<span className="arrow right bottom"></span>
									</p>

									<h4 className="title color05">
										Aerolínea Favorita
									</h4>
									<p className="aerolineaFavorita has-arrow left">
										<span
											className="text"
											dangerouslySetInnerHTML={{
												__html: aerolineaFavHTML
											}}
										></span>
										<span className="arrow left bottom"></span>
									</p>

									<h4 className="title color06">
										Ejecución Impecable
									</h4>
									<p className="ejecucionImpecable">
										<span
											className="text"
											dangerouslySetInnerHTML={{
												__html: ejecucionImpecableHTML
											}}
										></span>
										<span className="arrow upper"></span>
									</p>
									<span className="planeholder">
										<div className="planeitem"></div>
										<div className="planebg planebgusa"></div>
									</span>
								</div>
							</div>
						</div>
					)}
				</div>
			</LoadingScreen>
		);
	}
}
