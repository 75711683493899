import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
// import Aside from '../../components/Profile/AsideProfile';
import Banner from "./assets/talent-planning-banner.jpg";
import "./talent.css";
import { talentPlanning } from "./TALENTPLANNING_TEXTS";
// import { getAge, getRoleTime } from '../../utils/TalentPlanningUtils';
// import TalentCard from './TalentCard';

const fakeUsers = [
	{
		ANTIGUEDADROL: "",
		AREA: "RECURSOS HUMANOS",
		CORREO: "aberber@aeromexico.com",
		FECHACONTRA: "16/10/2019",
		FECHANACIMIENTO: "05/09/1986",
		GENERO: "Femenino",
		NIVEL: "10",
		NOMBRE: "ALEJANDRA BERBER ARANDA",
		POSICION: "DISEÑADOR GRAFICO CI"
	},
	{
		ANTIGUEDADROL: "04/11/2019",
		AREA: "RECURSOS HUMANOS",
		CORREO: "jgarciab@aeromexico.com",
		FECHACONTRA: "04/11/2019",
		FECHANACIMIENTO: "17/02/1989",
		GENERO: "Masculino",
		NIVEL: "10",
		NOMBRE: "JUAN RODRIGO DE LA CRUZ GARCIA BRISEÑO",
		POSICION: "ESPECIALISTA SR HERRAMIENTAS DIGITALES RH"
	}
];

function TalentPlanningPage() {
	const [loading, setLoading] = useState(true);
	const [subordinados, setSubordinados] = useState([]);
	const [subordinadosLoaded, setSubordinadosLoaded] = useState([]);
	const [isHRBP, setIsHRBP] = useState(false);
	const isDev = false;
const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlValid = isDev ? urlLocal : urlDev;
	const createFetchURL = (theArray, isHRFetch = false) => {
		// cretes default url base
		let theURL = encodeURI(`${urlValid}/tp-user-statuses?email_eq=`);
		// array to add one by one the email to search inside the statuses
		if (isHRFetch) {
			for (let i = 0; i < theArray.length; i++) {
				theURL += i === 0 ? theArray[i].email : `&email_eq=${theArray[i].email}`;
			}
		} else {
			for (let i = 0; i < theArray.length; i++) {
				theURL += i === 0 ? theArray[i].CORREO : `&email_eq=${theArray[i].CORREO}`;
			}
		}
		// returns the created url
		return theURL;
	};

	const validateStep = (theEmail, returnKeyPos = false) => {
		// Searchs email from the array of the fetched ones
		const selected = subordinadosLoaded.find((o) => o.email === theEmail);
		// Returns the step of the finded email if theres none, returns 0
		if (!returnKeyPos) return !!selected ? selected.currentStep : 0;
		// if we pass the returnKeyPos as true
		return selected.keyPosition;
	};

	// const isItHRBP = emailSearch =>
	// fetch(
	// 	`https://cadmin.miaeromexico.com/tp-hrbp-data?hrbp_email_eq=${emailSearch}`
	// )
	// 	.then(res => res.json())
	// 	.then(resData => {
	// 		console.log('GENERIC RESDATA: ', resData);
	// 		if (resData.length > 0) {
	// 			console.log('HRBP RESPONSE SEARCH: ', resData);
	// 			// AQUI DEBE DE IR LA CREACIÓN DE LA URL PARA VALIDAR LA INFORMACIÓN
	// 			const firstFecthURL = createFetchURL(resData, true);
	// 			console.log('the fetch URL', firstFecthURL);
	// 			fetch(firstFecthURL)
	// 				.then(res => res.json())
	// 				.then(responseData => {
	// 					if (responseData.length > 0) {
	// 						alert('RESPONSE CORRECT');
	// 						console.log('HRBP RESPONSEDATA', responseData);
	// 						console.table(responseData);
	// 						console.log('HRBP from hrbp users', resData);
	// 						console.table(resData);
	// 						setSubordinadosLoaded(responseData);
	// 						setSubordinados(resData);
	// 						// setSubordinados(responseData);
	// 						setIsHRBP(true);
	// 						setLoading(false);
	// 						// alert(JSON.stringify(resData));
	// 						return true;
	// 					} else {
	// 						return false;
	// 					}
	// 				})
	// 				.catch(error => {
	// 					console.log('HUBO UN ERROR');
	// 					console.error(error);
	// 				});
	// 			// setSubordinadosLoaded(resData);
	// 			// setSubordinados(resData);
	// 		}
	// 	})
	// 	.catch(console.error);
	// alert(JSON.stringify(theResponse));
	// if (!!theResponse && theResponse.length > 0) {
	// 	return true;
	// } else {
	// 	return false;
	// }

	// const validateUSERS = usuarios => {
	// 	console.log(
	// 		'ENCUENTRA USUARIOS NIVEL 10?: ',
	// 		usuarios.find(
	// 			o =>
	// 				o.nivel === '10' ||
	// 				o.nivel === '11' ||
	// 				o.nivel === '12' ||
	// 				o.nivel === '13' ||
	// 				o.nivel === '14'
	// 		)
	// 	);
	// };

	useEffect(() => {
		const theAsync = async () => {
			const userEmail = localStorage.getItem("email");
			sessionStorage.clear();
			fetch(encodeURI(`${urlValid}/tp-hrbp-data?hrbp_email_eq=${userEmail}`))
				.then((res) => res.json())
				.then((resData) => {
					if (resData.length > 0) {
						// AQUI DEBE DE IR LA CREACIÓN DE LA URL PARA VALIDAR LA INFORMACIÓN
						const firstFecthURL = createFetchURL(resData, true);
						fetch(encodeURI(firstFecthURL))
							.then((res) => res.json())
							.then((responseData) => {
								if (responseData.length > 0) {
									console.table(responseData);

									console.table(resData);
									setSubordinadosLoaded(responseData);
									setSubordinados(resData);
									// setSubordinados(responseData);
									setIsHRBP(true);
									setLoading(false);
									// alert(JSON.stringify(resData));
								} else {
									// if we are in localhost uses the default local array
									if (window.location.hostname === "localhost") {
										const firstFecthURL = createFetchURL(fakeUsers);
										fetch(firstFecthURL)
											.then((res) => res.json())
											.then((responseData) => {
												setSubordinadosLoaded(responseData);
												setSubordinados(fakeUsers);
												// validateUSERS(fakeUsers);
												setLoading(false);
											})
											.catch(console.error);
									} else {
										// if we are not in localhost then it uses the subordinados localstorage item
										const losBenefits = !!localStorage.getItem("colaboradores")
											? JSON.parse(localStorage.getItem("colaboradores"))
											: [];
										// creates the url that we are going to fetch
										const firstFecthURL = createFetchURL(losBenefits);
										// fetches the url
										fetch(encodeURI(firstFecthURL))
											.then((res) => res.json())
											.then((responseData) => {
												// saves the users from fetched url if theres a response
												setSubordinadosLoaded(responseData);
												// sets the local users from localstorage
												setSubordinados(losBenefits);
												setLoading(false);
											})
											.catch(console.error);
									}
								}
							})
							.catch((error) => {
								console.error(error);
							});
						// setSubordinadosLoaded(resData);
						// setSubordinados(resData);
					} else {
						const colaboradores = JSON.parse(localStorage.getItem("colaboradores"));
						const theFetchUrl = createFetchURL(colaboradores, false);
						fetch(encodeURI(theFetchUrl))
							.then((res) => res.json())
							.then((resData) => {
								if (resData.length > 0) {
									console.table(resData);
									setSubordinadosLoaded(resData);
									setSubordinados(colaboradores);
									// setSubordinados(responseData);
									setIsHRBP(false);
									setLoading(false);
								}
							})
							.catch(console.error);
					}
				})
				.catch(console.error);
		};
		// const theAsync = async () => {
		// };
		theAsync();
	}, []);

	return (
		<div className="full-container">
			<div className="content-holder-talent">
				<header className="talent-header relative h-52">
					<div className="absolute left-1/2 top-1/2 z-10 flex w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center">
						<h1 className="mb-3 text-5xl font-bold text-white opacity-100">{talentPlanning.title}</h1>
						<p className="font-regular px-10 text-white  lg:px-24">{talentPlanning.description}</p>
					</div>
					<img src={Banner} alt="Talent Planning" className="relative z-0 h-full w-full object-cover" />
				</header>
				<div className="top-navigation sticky left-0 top-14 z-50 flex w-full items-center justify-center border-b border-gray-200 bg-myWhite py-3 shadow-lg">
					<div className={`ImageUser ${localStorage.getItem("avatar")}`}></div>
					<p className="text-mg mx-4 flex flex-col items-start justify-center">
						<span className="text-amBlack">
							{localStorage.getItem("nombre")} {localStorage.getItem("apellidoPaterno")}{" "}
							{localStorage.getItem("apellidoMaterno")}
						</span>
						<span className="text-xs text-gray-500">{localStorage.getItem("posicion")}</span>
					</p>
					<p className="flex flex-col items-start justify-center border-l border-gray-300 pl-4">
						<span className="text-xs text-amBlack">
							Employee No.: {localStorage.getItem("no_empleado_AM")}
						</span>
						<span className="text-xs text-amBlack">Nivel L{localStorage.getItem("nivel")}</span>
					</p>
				</div>
				<motion.div
					key="talent-planning-holder-parent"
					initial={{ opacity: 0, y: 10 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 10 }}
					className="mb-16 mt-8 w-full px-4 lg:px-0">
					<motion.div
						key="key-position-assessment-step"
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 20 }}
						className="the-questions mx-auto mb-10 mt-5 w-full rounded-md border-l border-t border-r border-b border-amBlack bg-amBlue py-4 px-10 text-lg lg:w-9/12">
						<p className="w-full text-center text-base text-white">{talentPlanning.blue_description}</p>
					</motion.div>
					<div className="mx-auto w-full rounded-md border border-amLightBlue bg-gray-50 p-4 lg:w-10/12">
						<h2 className="mb-2 text-xl font-bold text-amBlue">{talentPlanning.table.title}</h2>
						{loading ? (
							<>
								<div className="fake-table-holder overflow-hidden rounded-md border-2 border-amBlue">
									<div className="fake-table-head flex w-full bg-amBlue py-2 px-4 text-sm font-bold text-gray-200">
										<div className="name-data w-5/12 text-center">
											{talentPlanning.table.firstColumn}
										</div>
										<div className="name-data w-3/12 text-center">
											{talentPlanning.table.secondColumn}
										</div>
										<div className="name-data w-4/12 text-center">
											{talentPlanning.table.thirdColumn}
										</div>
									</div>
									<div className="fake-table-body flex w-full flex-col text-amBlack">
										<div className="flex w-full flex-col items-start justify-center space-y-2 pt-2 pb-3">
											<div className="grid w-full grid-cols-3 gap-4 px-3"></div>
											<div className="grid w-full grid-cols-3 gap-4 px-3"></div>
											<div className="grid w-full grid-cols-3 gap-4 px-3"></div>
											<div className="grid w-full grid-cols-3 gap-4 px-3"></div>
											<div className="grid w-full grid-cols-3 gap-4 px-3"></div>
										</div>
									</div>
								</div>
							</>
						) : (
							<>
								{subordinados.length > 0 ? (
									<div className="fake-table-holder overflow-hidden rounded-md border-2 border-amBlue">
										<div className="fake-table-head flex w-full bg-amBlue py-2 px-4 text-sm font-bold text-gray-200">
											<div className="name-data w-5/12 text-center">
												{talentPlanning.table.firstColumn}
											</div>
											<div className="name-data w-3/12 text-center">
												{talentPlanning.table.secondColumn}
											</div>
											<div className="name-data w-4/12 text-center">
												{talentPlanning.table.thirdColumn}
											</div>
										</div>
										<div className="fake-table-body flex w-full flex-col text-amBlack">
											{loading ? (
												<div className="flex w-full flex-col items-start justify-center space-y-2 pt-2 pb-3">
													<div className="grid w-full grid-cols-3 gap-4 px-3"></div>
													<div className="grid w-full grid-cols-3 gap-4 px-3"></div>
													<div className="grid w-full grid-cols-3 gap-4 px-3"></div>
													<div className="grid w-full grid-cols-3 gap-4 px-3"></div>
													<div className="grid w-full grid-cols-3 gap-4 px-3"></div>
												</div>
											) : (
												<>
													{subordinados.map((user, index) => (
														<React.Fragment
															key={`parent-holder-${isHRBP ? user.name : user.NOMBRE}`}>
															{isHRBP && (
																<div
																	key={isHRBP ? user.name : user.NOMBRE}
																	className={`flex items-center justify-start py-2 px-4 text-sm text-amBlue ${
																		index === subordinados.length - 1
																			? ""
																			: index < subordinados.length - 1
																				? "border-b-2 border-amBlue"
																				: ""
																	} ${
																		index % 2 === 1
																			? "bg-amWhite hover:bg-amLightBlue"
																			: "bg-amWhite hover:bg-amLightBlue"
																	}`}>
																	<div className="name w-5/12 overflow-hidden overflow-ellipsis whitespace-nowrap pr-2">
																		{isHRBP ? user.name : user.NOMBRE}
																	</div>
																	<div className="talent w-3/12 text-center">
																		{isHRBP ? user.position : user.POSICION}
																	</div>
																	<div
																		className={`quiz flex w-4/12 items-center justify-center space-x-4 ${"py-2"}`}>
																		{/* AQUI VALIDACION DE PASO */}
																		{validateStep(
																			isHRBP ? user.email : user.CORREO
																		) < 1 ? (
																			<Link
																				to={`/recompensa-total/talent-planning/assessment/${
																					isHRBP ? user.email : user.CORREO
																				}`}
																				onClick={() => {
																					sessionStorage.setItem(
																						"talentUser",
																						JSON.stringify(user)
																					);
																				}}
																				className={`whitespace-nowrap rounded-md bg-amBlue py-2 px-2 text-xs font-bold text-white hover:bg-amBlack`}>
																				{talentPlanning.table.button_assessment}
																			</Link>
																		) : validateStep(
																				isHRBP ? user.email : user.CORREO
																		  ) > 7 &&
																		  validateStep(
																				isHRBP ? user.email : user.CORREO,
																				true
																		  ) === "Key Position" ? (
																			<>
																				<Link
																					onClick={() => {
																						sessionStorage.setItem(
																							"onlyViewCard",
																							true
																						);
																						sessionStorage.setItem(
																							"talentUser",
																							JSON.stringify(user)
																						);
																					}}
																					to={`/recompensa-total/talent-planning/card/${
																						isHRBP
																							? user.email
																							: user.CORREO
																					}`}
																					className={`whitespace-nowrap rounded-md py-2 px-2 text-xs font-bold ${"bg-amBlue text-white hover:bg-amBlack"}`}>
																					{
																						talentPlanning.table
																							.button_viewCard
																					}
																				</Link>
																				<Link
																					onClick={() => {
																						sessionStorage.setItem(
																							"talentUser",
																							JSON.stringify(user)
																						);
																					}}
																					to={`/recompensa-total/talent-planning/succession-planning/${
																						isHRBP
																							? user.email
																							: user.CORREO
																					}`}
																					className={`whitespace-nowrap rounded-md py-2 px-2 text-xs font-bold ${"bg-amBlue text-white hover:bg-amBlack"}`}>
																					{
																						talentPlanning.table
																							.button_succession
																					}
																				</Link>
																			</>
																		) : validateStep(
																				isHRBP ? user.email : user.CORREO
																		  ) > 7 ? (
																			<Link
																				onClick={() => {
																					sessionStorage.setItem(
																						"onlyViewCard",
																						true
																					);
																					sessionStorage.setItem(
																						"talentUser",
																						JSON.stringify(user)
																					);
																				}}
																				to={`/recompensa-total/talent-planning/card/${
																					isHRBP ? user.email : user.CORREO
																				}`}
																				className={`whitespace-nowrap rounded-md py-2 px-2 text-xs font-bold ${"bg-amBlue text-white hover:bg-amBlack"}`}>
																				{talentPlanning.table.button_viewCard}
																			</Link>
																		) : (
																			<>
																				<Link
																					to={`/recompensa-total/talent-planning/assessment/${
																						isHRBP
																							? user.email
																							: user.CORREO
																					}`}
																					onClick={() => {
																						sessionStorage.setItem(
																							"talentUser",
																							JSON.stringify(user)
																						);
																					}}
																					className={`whitespace-nowrap rounded-md bg-amBlue py-2 px-2 text-xs font-bold text-white hover:bg-amBlack`}>
																					{talentPlanning.table.button_card}
																				</Link>
																				{validateStep(
																					isHRBP ? user.email : user.CORREO,
																					true
																				) === "Key Position" ? (
																					<Link
																						onClick={() => {
																							sessionStorage.setItem(
																								"talentUser",
																								JSON.stringify(user)
																							);
																						}}
																						to={`/recompensa-total/talent-planning/succession-planning/${
																							isHRBP
																								? user.email
																								: user.CORREO
																						}`}
																						className={`whitespace-nowrap rounded-md py-2 px-2 text-xs font-bold ${"bg-amBlue text-white hover:bg-amBlack"}`}>
																						{
																							talentPlanning.table
																								.button_succession
																						}
																					</Link>
																				) : null}
																			</>
																		)}
																	</div>
																</div>
															)}
															{(parseInt(user.NIVEL) > 9 && !isHRBP) ||
															localStorage.getItem("email") ===
																"jtapias@aeromexico.com" ? (
																<div
																	key={isHRBP ? user.name : user.NOMBRE}
																	className={`flex items-center justify-start py-2 px-4 text-sm text-amBlue ${
																		index === subordinados.length - 1
																			? ""
																			: index < subordinados.length - 1
																				? "border-b-2 border-amBlue"
																				: ""
																	} ${
																		index % 2 === 1
																			? "bg-amWhite hover:bg-amLightBlue"
																			: "bg-amWhite hover:bg-amLightBlue"
																	}`}>
																	<div className="name w-5/12 overflow-hidden overflow-ellipsis whitespace-nowrap pr-2">
																		{isHRBP ? user.name : user.NOMBRE}
																	</div>
																	<div className="talent w-3/12 text-center">
																		{isHRBP ? user.position : user.POSICION}
																	</div>
																	<div
																		className={`quiz flex w-4/12 items-center justify-center space-x-4 ${"py-2"}`}>
																		{/* AQUI VALIDACION DE PASO */}
																		{validateStep(
																			isHRBP ? user.email : user.CORREO
																		) < 1 ? (
																			<Link
																				to={`/recompensa-total/talent-planning/assessment/${
																					isHRBP ? user.email : user.CORREO
																				}`}
																				onClick={() => {
																					sessionStorage.setItem(
																						"talentUser",
																						JSON.stringify(user)
																					);
																				}}
																				className={`whitespace-nowrap rounded-md bg-amBlue py-2 px-2 text-xs font-bold text-white hover:bg-amBlack`}>
																				{talentPlanning.table.button_assessment}
																			</Link>
																		) : validateStep(
																				isHRBP ? user.email : user.CORREO
																		  ) > 7 &&
																		  validateStep(
																				isHRBP ? user.email : user.CORREO,
																				true
																		  ) === "Key Position" ? (
																			<>
																				<Link
																					onClick={() => {
																						sessionStorage.setItem(
																							"onlyViewCard",
																							true
																						);
																						sessionStorage.setItem(
																							"talentUser",
																							JSON.stringify(user)
																						);
																					}}
																					to={`/recompensa-total/talent-planning/card/${
																						isHRBP
																							? user.email
																							: user.CORREO
																					}`}
																					className={`whitespace-nowrap rounded-md py-2 px-2 text-xs font-bold ${"bg-amBlue text-white hover:bg-amBlack"}`}>
																					{
																						talentPlanning.table
																							.button_viewCard
																					}
																				</Link>
																				<Link
																					onClick={() => {
																						sessionStorage.setItem(
																							"talentUser",
																							JSON.stringify(user)
																						);
																					}}
																					to={`/recompensa-total/talent-planning/succession-planning/${
																						isHRBP
																							? user.email
																							: user.CORREO
																					}`}
																					className={`whitespace-nowrap rounded-md py-2 px-2 text-xs font-bold ${"bg-amBlue text-white hover:bg-amBlack"}`}>
																					{
																						talentPlanning.table
																							.button_succession
																					}
																				</Link>
																			</>
																		) : validateStep(
																				isHRBP ? user.email : user.CORREO
																		  ) > 7 ? (
																			<Link
																				onClick={() => {
																					sessionStorage.setItem(
																						"onlyViewCard",
																						true
																					);
																					sessionStorage.setItem(
																						"talentUser",
																						JSON.stringify(user)
																					);
																				}}
																				to={`/recompensa-total/talent-planning/card/${
																					isHRBP ? user.email : user.CORREO
																				}`}
																				className={`whitespace-nowrap rounded-md py-2 px-2 text-xs font-bold ${"bg-amBlue text-white hover:bg-amBlack"}`}>
																				{talentPlanning.table.button_viewCard}
																			</Link>
																		) : (
																			<>
																				<Link
																					to={`/recompensa-total/talent-planning/assessment/${
																						isHRBP
																							? user.email
																							: user.CORREO
																					}`}
																					onClick={() => {
																						sessionStorage.setItem(
																							"talentUser",
																							JSON.stringify(user)
																						);
																					}}
																					className={`whitespace-nowrap rounded-md bg-amBlue py-2 px-2 text-xs font-bold text-white hover:bg-amBlack`}>
																					{talentPlanning.table.button_card}
																				</Link>
																				{validateStep(
																					isHRBP ? user.email : user.CORREO,
																					true
																				) === "Key Position" ? (
																					<Link
																						onClick={() => {
																							sessionStorage.setItem(
																								"talentUser",
																								JSON.stringify(user)
																							);
																						}}
																						to={`/recompensa-total/talent-planning/succession-planning/${
																							isHRBP
																								? user.email
																								: user.CORREO
																						}`}
																						className={`whitespace-nowrap rounded-md py-2 px-2 text-xs font-bold ${"bg-amBlue text-white hover:bg-amBlack"}`}>
																						{
																							talentPlanning.table
																								.button_succession
																						}
																					</Link>
																				) : null}
																			</>
																		)}
																	</div>
																</div>
															) : null}
														</React.Fragment>
													))}
												</>
											)}
										</div>
									</div>
								) : (
									<p className="text-md text-gray-400">Loading your information</p>
								)}
							</>
						)}
					</div>
				</motion.div>
			</div>
		</div>
	);
}

export default TalentPlanningPage;
