import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import Modal from "react-awesome-modal";
import ReactMarkdown from "react-markdown";
import Banner from "./assets/banner-procesos-administrativos.jpeg";
import Placeholder from "./assets/placeholder.svg";
import { ReactComponent as SearchIcon } from "./assets/search.svg";
import { ReactComponent as Atencion } from "./assets/atencion.svg";
import { ReactComponent as Correo } from "./assets/correo.svg";
import close from "../../assets/logo/close.svg";
import Loader from "../../components/Loader/Loader";
import Index from "../../components/Footer";
import Arriba from "../../components/Header/Arriba";
import i18next from "i18next";

const PageProcesosAdministrativos = () => {
	const [loading, setLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [procesos, setProcesos] = useState([]);
	const [modalData, setModalData] = useState({
		icon: Placeholder,
		subtitle: "Credencial de empleo",
		title: "Renovación por vigencia",
		content: null
	});
	const modalRef = useRef(null);
	const cabecera = new Headers({
		"Content-type": "application/json",
		Authorization: "Bearer " + process.env.REACT_APP_TOKEN_LOCAL
	});
	useEffect(() => {
		const isDev = false;
		const urlLocal = `${process.env.REACT_APP_LOCAL}`;
		const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
		const urlValid = isDev ? urlLocal : urlDev;
		//fetch(`${process.env.REACT_APP_CADMIN}/pa-categorias`)
		fetch(`${urlValid}/pa-categorias`, { headers: cabecera })
			.then((res) => res.json())
			.then((resData) => {
				if (resData.data.length > 0) {
					setProcesos(resData);
					setLoading(false);
				}
			});
	}, []);

	return (
		<motion.div
			key="page-procesos-administrativos"
			initial={{ opacity: 0, y: 50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 50 }}
			className="full-container">
			<Arriba />
			<div className="content-holder-talent">
				<header className="talent-header relative py-40">
					<div className="absolute left-1/2 top-1/2 z-20 flex w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center">
						<h1 className="mb-1 text-center text-5xl font-bold leading-none text-white opacity-100">
							{i18next.language === "es" ? "Servicios al personal" : "Staff Services"}
						</h1>
						<h2 className="text-center text-2xl font-bold leading-none text-white opacity-100">
							{i18next.language === "es"
								? "Colaboradores de Grupo Aeroméxico"
								: "Grupo Aeroméxico collaborators"}
						</h2>
						<p className="font-regular mt-4 px-10  text-center leading-tight text-white lg:px-80">
							{i18next.language === "es"
								? "Porque queremos que tengas la mejor experiencia en la compañía, hemos diseñado este espacio	con información importante sobre cómo llevar a cabo diferentes trámites, solicitudes y procesos de Grupo Aeroméxico."
								: "Because we want you to have the best experience in the company, we have designed this space with important information on how to carry out different procedures, requests and Aeroméxico Group processes."}
						</p>
					</div>
					<div className="absolute left-0 top-0 z-10 h-full w-full bg-amDarkBlue opacity-75"></div>
					<img
						src={Banner}
						alt="Talent Planning"
						className="absolute left-0 top-0 z-0 h-full w-full object-cover"
					/>
				</header>
				<section className="py-16" style={{ background: "#F6F8F9" }}>
					<div className="container mx-auto flex items-center justify-center px-10 lg:px-16">
						<div className="mb-16 flex w-full flex-col items-stretch justify-center overflow-hidden rounded-md md:flex-row">
							<div className="flex h-72 w-full flex-col items-center justify-center bg-white px-10 py-4 lg:w-4/12">
								<SearchIcon className="h-16 w-16" />
								<p className="mb-2 text-center font-bold text-amBlue">
									{i18next.language === "es"
										? "1. Consulta los procedimientos"
										: "1. Check the procedures"}
								</p>
								<p className="text-center text-sm leading-tight text-amBlack">
									{i18next.language === "es"
										? "En la siguiente sección, podrás encontrar información importante sobre diferentes temas principales. En caso de requerir formatos, podrás descargarlos en el botón correspondiente."
										: "In the next section, you can find important information about different main topics. If you require formats, you can download them using the button correspondent."}
								</p>
							</div>
							<div className="flex h-72 w-1 flex-col items-center justify-center bg-white py-4">
								<span className="h-44 bg-gray-100 opacity-75" style={{ width: "1px" }}></span>
							</div>
							<div className="flex h-72 w-full flex-col items-center justify-center bg-white px-10 py-4 lg:w-4/12">
								<Atencion className="h-16 w-16" />
								<p className="mb-2 text-center font-bold text-amBlue">
									{i18next.language === "es"
										? "2. Llama a 'Mi línea de Atención'"
										: "2. Call 'My Helpline'"}
								</p>
								<p className="text-center text-sm leading-tight text-amBlack">
									{i18next.language === "es"
										? "Si no encontraste el proceso que buscabas o requieres más información, llama a “Mi Línea de Atención” op. #4, donde un agente te brindará atención especializada."
										: "If you did not find the process you were looking for or require more information, call “My Attention Line” op. #4, where an agent will provide you with specialized attention."}
								</p>
							</div>
							<div className="flex h-72 w-1 flex-col items-center justify-center bg-white py-4">
								<span className="h-44 bg-gray-100 opacity-75" style={{ width: "1px" }}></span>
							</div>
							<div className="flex h-72 w-full flex-col items-center justify-center bg-white px-10 py-4 lg:w-4/12">
								<Correo className="h-16 w-16" />
								<p className="mb-2 text-center font-bold text-amBlue">
									{i18next.language === "es"
										? "3. Mándanos tus comentarios"
										: "3. Send us your comments"}
								</p>
								<p className="text-center text-sm leading-tight text-amBlack">
									{i18next.language === "es"
										? "Tus comentarios son muy importantes para nosotros, escríbenos a: "
										: "Your comments are very important to us, write to us at: "}
									<a
										href="mailto:amcomunicacioninterna@aeromexico.com"
										className="whitespace-pre-wrap font-bold text-amBlue underline hover:underline">
										amcomunicacioninterna@aeromexico.com
									</a>{" "}
									{i18next.language === "es"
										? "si tienes algún comentario o sugerencia para mejorar el entendimiento de esta sección."
										: "If you have any comments or suggestions to improve the understanding of this section."}
								</p>
							</div>
						</div>
					</div>

					<div className="container mx-auto flex flex-col items-center justify-center px-10 lg:px-16">
						{loading ? (
							<Loader />
						) : (
							<>
								{procesos.data.map((proceso) => {})}
								{procesos.data.length > 0 ? (
									<>
										{procesos.data.map((proceso) => (
											<article className="category-item-procesos mb-10 flex w-full flex-wrap items-center justify-center">
												<div
													className="left-side mb-5 flex w-full flex-col items-start justify-center md:w-5/12 lg:mb-0"
													style={{ minHeight: "50px" }}>
													<img
														className="h-10 w-10 object-contain"
														src={encodeURI(proceso.attributes?.icon.url)}
														alt={proceso.attributes?.title}
													/>
													<h3 className="my-2 text-left text-xl font-bold text-amBlue lg:pr-16">
														{i18next.language === "es"
															? proceso.attributes.title
															: proceso.attributes.Localization.title}
													</h3>
													{proceso.attributes.description ? (
														<p className="m-0 text-amBlack lg:pr-16">
															{i18next.language === "es"
																? proceso.attributes.description
																: proceso.attributes.Localization.description}
														</p>
													) : null}
												</div>
												{proceso.attributes.items.length > 0 ? (
													<div
														className="right-side flex w-full flex-col items-center justify-center rounded-md bg-white shadow-xl md:w-7/12"
														style={{
															minHeight: "50px"
														}}>
														{proceso.attributes.items.map((item, index) => (
															<div className="flex w-full items-center justify-between border-b border-gray-200 px-4 py-3">
																<p className="font-bold text-amBlue">
																	{i18next.language === "es"
																		? item.title
																		: proceso.attributes.Localization.items[index]
																				.title}
																</p>
																<button
																	type="button"
																	onClick={() => {
																		setModalData({
																			icon: proceso.attributes?.icon.url,
																			subtitle:
																				i18next.language === "es"
																					? item.title
																					: proceso.attributes.Localization
																							.items[index].title,
																			title:
																				i18next.language === "es"
																					? item.title
																					: proceso.attributes.Localization
																							.items[index].title,
																			content:
																				i18next.language === "es"
																					? item.description
																					: proceso.attributes.Localization
																							.items[index].description
																		});
																		setShowModal(true);
																	}}
																	className="rounded-full bg-amLightBlue px-2 py-1 text-sm text-amBlueLighter hover:bg-amBlueLighter hover:text-amBlue">
																	{i18next.language === "es"
																		? "Más información"
																		: "More information"}
																</button>
															</div>
														))}
													</div>
												) : (
													<div className="right-side focus:outline-none relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:w-7/12">
														<svg
															className="mx-auto h-12 w-12 text-gray-400"
															id="Capa_1"
															data-name="Capa 1"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 35.84074 29.94584"
															stroke="currentColor"
															fill="none">
															<path d="M13.69752,15.69722a5.64483,5.64483,0,0,0,1.57069,2.6207v1.07126c-.31777,1.01989-2.93595,2.65894-6.35083,3.97946a.763.763,0,0,0-.48376.71267v.54408a.75743.75743,0,0,0,.75137.7626H26.80424a.75749.75749,0,0,0,.75145-.7626v-.54408a.76221.76221,0,0,0-.48369-.71267c-3.415-1.31961-6.20578-2.95835-6.52439-3.97946V18.31792A5.79648,5.79648,0,0,0,22.22,15.69722v-5.316c0-2.47551-1.11867-4.06692-4.27-4.06692-3.07013,0-4.25248,1.59141-4.25248,4.06692Z" />
															<path d="M23.72318,15.35514c0,2.65006-1.844,3.08236-4.807,3.13733" />
															<path d="M13.69752,15.50714h-.95666a1.07524,1.07524,0,0,1-1.07524-1.07532V11.16206a1.07521,1.07521,0,0,1,1.07524-1.07524h.96278" />
															<path d="M22.22,10.08682h.957a1.07523,1.07523,0,0,1,1.07531,1.07524v3.26976A1.07525,1.07525,0,0,1,23.177,15.50714H22.22" />
															<path d="M12.77152,10.08682V8.56269a5.18036,5.18036,0,1,1,10.36071,0v1.52413" />
															<path d="M18.91613,18.49079a.7863.7863,0,1,1-.78629-.78638A.7863.7863,0,0,1,18.91613,18.49079Z" />
														</svg>
														<span className="mt-2 block text-sm font-medium text-gray-400">
															{i18next.language === "es"
																? "Esta categoría no tiene procesos"
																: "This category has no processes"}
														</span>
													</div>
												)}
											</article>
										))}
									</>
								) : (
									<h1>{i18next.language === "es" ? "NO HAY PROCESOS" : "THERE ARE NO PROCESSES"}</h1>
								)}
							</>
						)}
						{/* <article className="category-item-procesos flex w-full items-center justify-center">
							
							
						</article> */}
					</div>
				</section>
			</div>
			<Modal
				visible={showModal}
				width="auto"
				height="auto"
				effect="fadeInDown"
				onClickAway={() => {
					setShowModal(false);
				}}>
				<div ref={modalRef} className="container-footer-card-modal compliance-modal-2">
					<div class="closeModal" onClick={() => setShowModal(false)}>
						<img src={close} alt="Cerrar modal" />
					</div>
					<div className="flex items-center justify-center bg-amBlue py-8">
						<img
							className="flex h-10 w-10 items-center justify-center rounded-full bg-white object-contain p-1"
							src={encodeURI(modalData.icon)}
							alt={modalData.subtitle}
						/>
						<p className="ml-4 text-2xl font-bold text-white">{modalData.subtitle}</p>
					</div>
					<div className="modal-footer-card-content">
						<h3 className="type-title-modal font-bold normal-case">{modalData.title}</h3>
						<div className="actual-modal-content prose">
							<p>{modalData.content}</p>
							{/* <ReactMarkdown source={modalData.content} /> */}
						</div>
					</div>
				</div>
			</Modal>
			<Index />
		</motion.div>
	);
};

export default PageProcesosAdministrativos;
